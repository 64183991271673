<template>
  <div
    v-resizeObserver="{
      openResize: true,
      handler: observerCallBack
    }"
    :style="{ overflow: 'hidden' }"
  >
    <div id="metricChart" />
  </div>
</template>

<script>
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  MarkLineComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  VisualMapComponent
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import splitThousands from '@/util/splitThousands';
import { currencyToSymbol } from '@/util/helper';

echarts.use([
  LineChart,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  MarkLineComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  VisualMapComponent,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);

export default {
  name: 'MatricChart',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartContainerWidth: 0,
      eChartInstance: null,
      option: {
        tooltip: {
          trigger: 'axis',
          className: 'metric-chart-tooltip'
        },
        grid: {
          left: 0,
          bottom: '24%'
        },
        axisPointer: {
          link: { xAxisIndex: 'all' }
        },
        xAxis: {
          data: [],
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            formatter(value, index) {
              return `${value}`;
            },
            fontSize: 12
          },
          name: 'Date',
          nameLocation: 'middle',
          nameTextStyle: {
            padding: [20, 0, 0, 0],
            fontSize: 16,
            fontWeight: 'bolder',
            color: 'gray'
          },
          position: 'bottom',
          axisPointer: {
            show: true,
            type: 'line',
            handle: {
              show: false,
              color: '#004abc'
            }
          }
        },
        yAxis: {
          type: 'value',
          name: 'Equity',
          nameLocation: 'middle',
          scale: true,
          nameTextStyle: {
            padding: [0, 0, 50, 0],
            fontSize: 16,
            fontWeight: 'bolder',
            color: 'gray'
          },
          axisLabel: {
            fontSize: 12
          },
          axisPointer: {
            show: true,
            type: 'line'
          }
        },
        toolbox: {
          right: 20,
          orient: 'verticasl',
          emphasis: {
            iconStyle: {
              textPosition: 'left'
            }
          },
          feature: {
            dataZoom: {
              title: {
                zoom: 'Zoom',
                back: 'Zoom Reset'
              },
              yAxisIndex: 'none'
            },
            restore: {
              title: 'Refresh'
            },
            saveAsImage: {
              name: 'PUG_PropTrading_chart',
              title: 'Save Image'
            }
          }
        },
        dataZoom: [
          {
            type: 'slider',
            show: true,
            xAxisIndex: [0],
            left: 60,
            right: '10%'
          },
          {
            type: 'inside',
            show: true,
            xAxisIndex: [0],
            left: 60,
            right: '10%'
          }
        ],
        series: [
          {
            name: 'Result',
            type: 'line',
            smooth: true,
            data: [],
            showAllSymbol: true,
            lineStyle: {
              color: '#1dd1a1',
              type: 'solid',
              width: 2
            },
            itemStyle: {
              color: '#1dd1a1'
            },
            symbolSize: '6',
            markLine: {
              silent: true,
              symbol: 'none',
              percision: 2,
              label: {
                color: '#fff',
                lineHeight: 20,
                height: 20,
                borderRadius: 4,
                padding: [2, 8]
              },
              data: [
                {
                  yAxis: 0,
                  lineStyle: {
                    color: '#1dd1a1',
                    type: 'solid',
                    width: 2
                  },
                  label: {
                    position: 'insideStartTop',
                    backgroundColor: '#1dd1a1',
                    width: 80
                  }
                },
                {
                  yAxis: 0,
                  lineStyle: {
                    color: '#ff7272',
                    type: 'solid',
                    width: 2
                  },
                  label: {
                    position: 'insideStartBottom',
                    backgroundColor: '#ff7272',
                    width: 108
                  }
                }
              ]
            }
          },
          {
            name: 'Daily limit',
            type: 'line',
            smooth: true,
            data: [],
            showAllSymbol: true,
            lineStyle: {
              color: '#ff7272',
              type: 'dotted',
              width: 2
            },
            itemStyle: {
              color: '#ff7272'
            },
            symbolSize: '6'
          }
        ]
      },
      isMobile: false
    };
  },
  computed: {
    currentLang() {
      return this.$store.state.common.lang;
    },
    propData() {
      return this.data;
    }
  },
  watch: {
    currentLang(newValue) {
      if (newValue) this.refreshChart();
    },
    propData: {
      immediate: true,
      handler(newValue) {
        if (Object.keys(newValue)?.length && this.option && this.eChartInstance) this.refreshChart();
      }
    },
    chartContainerWidth: {
      immediate: true,
      handler(newValue) {
        if (this.eChartInstance) {
          if (newValue <= 580) {
            this.isMobile = false;
            this.option.dataZoom[0].left = 20;
            this.option.dataZoom[0].right = 20;
            this.option.dataZoom[1].left = 0;
            this.option.dataZoom[1].right = 0;

            this.option.xAxis.nameTextStyle.fontSize = 12;
            this.option.xAxis.axisLabel.fontSize = 9;
            this.option.yAxis.nameTextStyle.fontSize = 12;
            this.option.yAxis.axisLabel.fontSize = 9;
            this.option.yAxis.nameTextStyle.padding = [0, 0, 12, 0];
            this.option.yAxis.nameTextStyle.align = 'right';
            this.option.yAxis.nameLocation = 'end';
            this.option.grid.left = 44;
            this.option.grid.right = 20;
            this.option.grid.bottom = '20%';
            this.option.toolbox.orient = 'horizontal';
            this.option.toolbox.emphasis = {
              iconStyle: {
                textPosition: 'bottom'
              }
            };
            this.option.toolbox.feature.dataZoom.show = false;
            if (this.option.series[0].markLine?.label) {
              this.option.series[0].markLine.label.fontSize = 10;
            }

            this.eChartInstance.setOption(this.option, true);
          } else {
            this.isMobile = true;
            this.option.dataZoom[0].left = 60;
            this.option.dataZoom[0].right = '10%';
            this.option.dataZoom[1].left = 60;
            this.option.dataZoom[1].right = '10%';

            this.option.xAxis.nameTextStyle.fontSize = 16;
            this.option.xAxis.axisLabel.fontSize = 12;
            this.option.yAxis.nameTextStyle.fontSize = 16;
            this.option.yAxis.axisLabel.fontSize = 12;
            this.option.yAxis.nameTextStyle.padding = [0, 0, 50, 0];
            this.option.yAxis.nameLocation = 'middle';
            this.option.grid.left = 100;
            this.option.grid.right = '10%';
            this.option.grid.bottom = '24%';
            this.option.toolbox.orient = 'vertical';
            this.option.toolbox.emphasis = {
              iconStyle: {
                textPosition: 'left'
              }
            };
            this.option.toolbox.feature.dataZoom.show = true;
            if (this.option.series[0].markLine?.label) {
              this.option.series[0].markLine.label.fontSize = 12;
            }
            this.eChartInstance.setOption(this.option, true);
          }
        }
      }
    }
  },
  mounted() {
    this.initialize();
  },
  destroyed() {
    window.removeEventListener('resize', () => this.eChartInstance.resize());
  },
  methods: {
    async initialize() {
      try {
        await this.initChart();
      } catch (error) {}
    },
    async initChart() {
      // init chart
      this.eChartInstance = await echarts.init(document.getElementById('metricChart'));
      this.eChartInstance.setOption(this.option, true);

      window.addEventListener('resize', () => this.eChartInstance.resize());
    },
    async refreshChart() {
      if (this.propData) {
        // current currency
        let currentCurrency = this.propData?.currency || 'USD';
        let currencySymbol = currencyToSymbol(currentCurrency);

        // set chart data
        this.option.dataZoom[0].startValue = this.propData.detail?.slice(-1)[0].date;
        this.option.dataZoom[0].endValue =
          this.propData.detail?.slice(-14, -13)[0]?.date || this.propData.detail[0].date;
        this.option.xAxis.data = this.propData.detail.map(item => item.date);
        this.option.xAxis.name = this.$t('propTrade.metricsPage.chart.tooltip.date');

        // yAxis setting
        const yAxisData = this.propData.detail.map(item => item.balance);
        const interval = Math.ceil(
          Math.max.apply(
            null,
            this.propData.detail.map(item => item.balance)
          ) / 20
        );
        const sortedYAxisData = yAxisData.sort((a, b) => Number(a) - Number(b));
        const yMin = Math.min(Math.round(Number(sortedYAxisData[0])), this.propData.maxTotalLoss) - interval;
        const yMax = Math.max(
          Number(this.propData.profitTarget),
          Math.ceil(Number(sortedYAxisData[sortedYAxisData?.length - 1]))
        );
        this.option.yAxis.data = yAxisData;
        this.option.yAxis.min = yMin;
        this.option.yAxis.max = Math.ceil((yMax - yMin) / interval) * interval + yMin;
        this.option.yAxis.interval = interval;
        this.option.yAxis.name = this.$t('propTrade.metricsPage.chart.tooltip.equity');

        // series setting
        this.option.series[1].data = this.propData.detail.map(item => item.dailyBalance);
        this.option.series[0].data = this.propData.detail.map(item => item.balance);
        // series markLine setting
        let isNeedProfitLine = typeof this.propData.profitTarget === 'number' && ~this.propData.profitTarget;
        let isNeedMaxLossLine = typeof this.propData.maxTotalLoss === 'number' && ~this.propData.maxTotalLoss;
        const getMarkLineData = () => {
          const data = [];
          const profitLine = {
            yAxis: Number(this.propData.profitTarget),
            lineStyle: {
              color: '#1dd1a1',
              type: 'solid',
              width: 2
            },
            label: {
              position: 'insideStartTop',
              backgroundColor: '#1dd1a1',
              formatter: () =>
                `${this.$t('propTrade.metricsPage.chart.markLine.profit')} : ${currencySymbol}${splitThousands(
                  this.propData.profitTarget
                )}`
            }
          };
          const maxLossLine = {
            yAxis: Number(this.propData.maxTotalLoss),
            lineStyle: {
              color: '#ff7272',
              type: 'solid',
              width: 2
            },
            label: {
              position: 'insideStartBottom',
              backgroundColor: '#ff7272',
              formatter: () =>
                `${this.$t('propTrade.metricsPage.chart.markLine.maxLoss')} : ${currencySymbol}${splitThousands(
                  this.propData.maxTotalLoss
                )}`
            }
          };

          if (isNeedProfitLine) data.push(profitLine);
          if (isNeedMaxLossLine) data.push(maxLossLine);

          return data;
        };
        this.option.series[0].markLine = {
          silent: true,
          symbol: 'none',
          percision: 2,
          label:
            isNeedProfitLine || isNeedMaxLossLine
              ? {
                  color: '#fff',
                  lineHeight: 20,
                  height: 20,
                  borderRadius: 4,
                  padding: [2, 8]
                }
              : null,
          data: getMarkLineData()
        };

        // tooltip setting
        this.option.tooltip.formatter = params => {
          let maxDailyLoss = this.propData.detail[params[0]?.dataIndex].dailyBalance;
          return `
            <div>${this.$t('propTrade.metricsPage.chart.tooltip.date')} : ${
            this.propData.detail[params[0]?.dataIndex].date
          }</div>
            <div>${this.$t('propTrade.metricsPage.chart.tooltip.equity')} : ${currencySymbol}${splitThousands(
            this.propData.detail[params[0]?.dataIndex].balance
          )}</div>
            <div>${this.$t('propTrade.metricsPage.chart.tooltip.trades')} : ${
            this.propData.detail[params[0]?.dataIndex].trades || 0
          }</div>
            <div>${
              maxDailyLoss && ~maxDailyLoss
                ? this.$t('propTrade.metricsPage.chart.tooltip.maxDailyLoss') + ' : ' + currencySymbol + splitThousands(maxDailyLoss)
                : ''
            } </div>
          `;
        };

        // toolbox button name lang setting
        this.option.toolbox.feature.dataZoom.title.zoom = this.$t('propTrade.metricsPage.chart.toolbox.zoom');
        this.option.toolbox.feature.dataZoom.title.back = this.$t('propTrade.metricsPage.chart.toolbox.zoomReset');
        this.option.toolbox.feature.restore.title = this.$t('propTrade.metricsPage.chart.toolbox.refresh');
        this.option.toolbox.feature.saveAsImage.title = this.$t('propTrade.metricsPage.chart.toolbox.saveImage');

        this.eChartInstance.setOption(this.option, true);
      }
    },
    observerCallBack(width) {
      this.chartContainerWidth = width;
    }
  }
};
</script>

<style lang="scss" scoped>
#metricChart {
  max-width: 100%;
  min-height: 500px;
  // height: 500px;
}

/deep/ .metric-chart-tooltip {
  // padding: 0 !important;
  min-width: 120px;

  .tooltip-top {
    // background-color: #bbbaba;
  }
}
</style>
