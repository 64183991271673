import { apiCheckPromoAvailable } from '@/resource'
import { isSuccess } from '@/util/restApi'
import { PROMO_CODE_TYPE } from '@/constants/propTrading'

export default {
  data() {
    return {
      promoCodeStatus: '',
      promoErrorMessage: '',
      promoCode: '',
      promo: {
        lastSuccesss: {
          code: '',
          status: false,
          result: null,
        },
      },
    }
  },
  watch: {
    ['promoCode']() {
      if (this.promo.lastSuccesss.status) {
        if (this.promoCode === this.promo.lastSuccesss.code) {
          this.promoCodeStatus = 'success'
        } else {
          this.promoCodeStatus = null
        }
      }
    },
  },
  methods: {
    restore() {
      this.promoCodeStatus = ''
      this.promoErrorMessage = ''
      this.promoCode = ''
      this.promo.lastSuccesss.code = ''
      this.promo.lastSuccesss.status = false
      this.promo.lastSuccesss.result = null
    },
    async applyPromoCode(feeSettingId = '') {
      if (!this.promoCode) {
        this.promo.lastSuccesss.status = false
        this.promo.lastSuccesss.code = ''
        this.promo.lastSuccesss.result = null

        this.promoCodeStatus = 'error'
        this.promoErrorMessage = this.$t('propTrade.promoCode.validPromoCode')

        return null
      }

      const result = await apiCheckPromoAvailable(feeSettingId, this.promoCode)

      if (isSuccess(result.data)) {
        this.promoCodeStatus = 'success'
        this.promoErrorMessage = ''
        this.promo.lastSuccesss.status = true
        this.promo.lastSuccesss.code = this.promoCode
        this.promo.lastSuccesss.result = result.data.data

        return result.data.data
      } else {
        this.promoCodeStatus = 'error'
        this.promoErrorMessage = this.$t(
          result?.data?.code ? `responseMsg.${result.data.code}` : 'propTrade.promoCode.unknownError'
        )
        this.promo.lastSuccesss.status = false
        this.promo.lastSuccesss.code = ''
        this.promo.lastSuccesss.result = null
        this.promoCode = ''

        return null
      }
    },

    calcDiscount(promo, fee = 0) {
      if (promo.promoType === PROMO_CODE_TYPE.RATE) {
        return fee * (promo.offPercentage / 100)
      } else if (promo.promoType === PROMO_CODE_TYPE.AMOUNT) {
        return promo.offAmount
      }

      return 0
    },

    promoCodeCheck() {
      if (!this.promo.lastSuccesss.status && this.promoCode) {
        // TODO: i18n
        this.promoCodeStatus = 'error'
        this.promoErrorMessage = this.$t('propTrade.promoCode.applyFirst')

        return false
      }
      return true
    },
  },
}
