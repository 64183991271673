<template>
  <div
    class="pug-upload"
    :class="[!fileInfo.fileList.length ? 'no-file' : '', ...calCustomClasseses]"
    :style="{
      height: calHeight,
      width: calWidth
    }"
    v-resizeObserver="{
      openResize: true,
      handler: observerCallBack
    }"
  >
    <el-upload
      ref="uploadRef"
      :action="action"
      :list-type="calUploadListType"
      :limit="calLimit"
      :multiple="calMultiple"
      :accept="acceptFiles"
      :before-upload="examFile"
      :on-success="handleOnSuccess"
      :on-error="uploadError"
      class="upload-container"
      :data-testid="calDataTestId"
      v-bind="$attrs"
      v-on="$listeners"
      v-loading="isLoading"
      :file-list="files"
    >
      <div v-show="!isLoading" class="upload-area" :class="{ 'pre-view': showPreViewImage }">
        <slot v-if="hasUploadContent" name="uploadContent"></slot>
        <div v-else :class="calCustomContentClasses" data-testid="button_upload">
          <slot name="uploadTopContent"></slot>
          <div class="upload-add">
            <img src="@/assets/images/common/add-icon.png" alt="upload-icon" />
          </div>

          <slot name="uploadBottomContent"></slot>
        </div>
      </div>

      <vue-pdf-embed
        v-if="pdfSource && !isLoading"
        ref="pdfRef"
        :source="pdfSource"
        :width="pdfPreviewWidth"
        :page="1"
        @rendered="setPreviewImgPositionEvent"
      />
    </el-upload>

    <div class="actions-item fullscreen-btn" data-testid="fullscreen" @click="handlePreViewFile">
      <img src="@/assets/images/common/fullscreen.png" alt="preview-file" />
    </div>
    <div class="actions-item delete-btn" data-testid="deleteFile" @click="showSureToDelete = true">
      <img src="@/assets/images/common/delete.png" alt="delete-file" />
    </div>

    <ElImageViewer
      v-if="showPreViewImage && fileInfo.fileList && !isCurrentFilePdf"
      :on-close="closeViewer"
      :z-index="1000"
      :url-list="[...fileInfo.fileList]"
      hide-on-click-modal
    />

    <el-dialog
      :visible="showPreViewPdf"
      fullscreen
      closeOnClickModal
      class="pdf-previewer"
      @close="showPreViewPdf = false"
      width="100%"
      v-resizeObserver="{
        openResize: true,
        handler: observerElDialogCallBack
      }"
    >
      <div class="close-preview-btn" @click="showPreViewPdf = false">
        <i class="el-icon-circle-close"></i>
      </div>
      <vue-pdf-embed v-if="showPreViewPdf" ref="previewPdfRef" :source="pdfSource" :width="pdfAreaWidth" />
    </el-dialog>

    <InfoDialog
      :visible.sync="showSureToDelete"
      :title="$t('fileUploader.sureToDelete.title')"
      infoType="fail"
      @closCbDialog="showSureToDelete = false"
    >
      <div>{{ $t('fileUploader.sureToDelete.content') }}</div>

      <template #actions>
        <Button buttonType="default" @click="handleRemoveSingleFile">
          {{ $t('common.button.ok') }}
        </Button>
      </template>
    </InfoDialog>
  </div>
</template>

<script>
import { apiDelete_file } from '@/resource';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed.js';
import InfoDialog from '@/components/dialog/InfoDialog.vue';
import Button from '@/components/common/Button.vue';

export default {
  name: 'PugUpload',
  components: { ElImageViewer, VuePdfEmbed, InfoDialog, Button },
  props: {
    action: {
      type: String,
      default: '/api/file/upload'
    },
    limit: {
      type: Number,
      default: 1
    },
    multiple: {
      type: Boolean,
      default: false
    },
    fileTypes: {
      type: Array,
      default: () => ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', '.pdf']
    },
    height: {
      type: String | Number,
      default: '100%'
    },
    width: {
      type: String | Number,
      default: '100%'
    },
    customClasseses: {
      type: Array | String,
      default: ''
    },
    customContentClasses: {
      type: Array | String,
      default: ''
    },
    uploadListType: {
      // original element ui has picture, picture-card, text types.
      // In this component, uploadListType will be restricted to 'picture'.
      type: String,
      default: 'picture'
    },
    limitUploadFileSize: {
      // defualt will limit to 5MB (MB only)
      type: Number | String,
      default: 5
    },
    dataTestId: {
      type: String | Number,
      default: 'textInput'
    },
    files: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      uploadRef: null,
      fileInfo: {
        fileList: []
      },
      showPreViewImage: false,
      dialogImageUrl: '',
      pdfSource: '',
      isLoading: false,
      isCurrentFilePdf: false,
      showPreViewPdf: false,
      currentHeight: this.height,
      currentWidth: this.width,
      previewPdfRef: null,
      currentElDialogWidth: 0,
      currentElDialogHeight: 0,
      showSureToDelete: false,
      uploadedBlobFile: null
    };
  },
  watch: {
    files() {
      if (this.files.length) {
        this.isCurrentFilePdf = this.files[0].type.includes('pdf');
        this.fileInfo.fileList = [this.files[0].url];
        if (this.isCurrentFilePdf) this.pdfSource = this.files[0].url;
      }
    }
  },
  computed: {
    calDataTestId() {
      return this.dataTestId;
    },
    calUploadListType() {
      return 'picture';
      // return this.uploadListType;
    },
    calLimit() {
      return 1;
      // return this.calUploadListType === 'picture' || this.calUploadListType === 'picture-card' ? 1 : this.limit;
    },
    calMultiple() {
      return false;
      // return this.calUploadListType === 'picture' || this.calUploadListType === 'picture-card' ? false : this.multiple;
    },
    calLimitUploadFileSize() {
      return Number(this.limitUploadFileSize);
    },
    acceptFiles() {
      return this.fileTypes.join(',');
    },
    allowedFile() {
      return this.fileTypes.map(item => {
        let data = item.split('image/');
        return data.length > 1 ? data[1] : data[0].toLowerCase().replace('.', '');
      });
    },
    calHeight() {
      if (Number(this.height) || Number(this.height) == 0) return `${Math.abs(Number(this.height))}px`;
      return this.height;
    },
    calWidth() {
      if (Number(this.width) || Number(this.width) == 0) return `${Math.abs(Number(this.width))}px`;
      return this.width;
    },
    hasUploadContent() {
      return !!this.$slots.uploadContent;
    },
    calCustomClasseses() {
      return typeof this.customClasseses === 'string' ? [this.customClasseses] : [...this.customClasseses];
    },
    calCustomContentClasses() {
      return typeof this.customContentClasses === 'string'
        ? [this.customContentClasses]
        : [...this.customContentClasses];
    },
    pdfPreviewWidth() {
      let border = 1;
      return Math.floor(this.currentWidth - border * 2);
    },
    pdfAreaWidth() {
      let maxWidth = this.currentElDialogWidth > 852 ? 852 : this.currentElDialogWidth;
      return this.currentElDialogWidth > 768 ? maxWidth : this.currentWidth;
    }
  },
  methods: {
    onErrorOccur(type, response) {
      this.$emit('onErrorOccur', { type, response });
    },
    updateDeletedFile(file) {
      this.$emit('updateDeletedFile', file);
    },
    examFile(file) {
      this.isLoading = true;

      let isPdfFile = file.type.endsWith('pdf');

      if (file.size / 1024 / 1024 > this.calLimitUploadFileSize) {
        this.isLoading = false;
        this.onErrorOccur('oversize', { limitSize: `${this.calLimitUploadFileSize}` });
        return false;
      }

      if (isPdfFile) this.pdfSource = URL.createObjectURL(file);
      this.isCurrentFilePdf = isPdfFile;

      let result = this.allowedFile.some(s => file.name.toLowerCase().endsWith(s));
      if (!result) {
        this.isLoading = false;
        this.onErrorOccur('fileTypeNotAllow');
      }

      return result;
    },
    async handleRemoveSingleFile() {
      let uploadFiles = this.$refs.uploadRef.uploadFiles;
      if (!uploadFiles.length) return;

      try {
        this.showSureToDelete = false;
        const result = await apiDelete_file({ fileId: uploadFiles[0].response.data });
        if (result.data.code == 0) {
          this.fileInfo.fileList = this.fileInfo.fileList.filter(item => item != uploadFiles[0].response.data);
          this.updateFileInfo();
          this.updateDeletedFile(uploadFiles[0].response.data);
          this.$refs.uploadRef.clearFiles();
          this.pdfSource = null;
        } else {
          this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
        }
      } catch (error) {
        this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
      }
      this.isLoading = false;
    },
    handleOnSuccess(response, file) {
      if (response.code == 0) {
        this.fileInfo.fileList.push(response.data);
        this.uploadedBlobFile = file;
        this.updateFileInfo();
      } else if (response.code == 'session_timeout') {
        this.$router.push(response.loginUrl);
      } else {
        this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
        this.$refs.uploadRef.clearFiles();
      }
      this.isLoading = false;
    },
    uploadError(response) {
      this.$refs.uploadRef.clearFiles();
      this.onErrorOccur('error', this.$t('responseMsg.505'));
      this.isLoading = false;
    },
    handlePreViewFile() {
      if (this.isCurrentFilePdf) {
        this.showPreViewPdf = true;
        return;
      }
      this.showPreViewImage = true;
    },
    closeViewer() {
      this.showPreViewPdf = false;
      this.showPreViewImage = false;
    },
    observerCallBack(width, height) {
      this.currentWidth = width;
      this.currentHeight = height;
    },
    observerElDialogCallBack(width, height) {
      this.currentElDialogWidth = width;
      this.currentElDialogHeight = height;
    },
    setPreviewImgPositionEvent() {
      const self_pxToNumber = val => {
        return Number(val.split('px')[0]);
      };

      const { width, height } = this.$refs.pdfRef.$el.children[0].children[0].style;
      const [previewWidth, previewHeight] = [self_pxToNumber(width), self_pxToNumber(height)];
      let topNum = 0;
      if (previewWidth > previewHeight && this.currentHeight - previewHeight > 0)
        topNum = (this.currentHeight - previewHeight) / 2;

      this.$refs.pdfRef.$el.style = `top: ${topNum}px; opacity:1; transition: 'all 0.3s';`;
    },
    updateFileInfo() {
      this.$emit('updateFileInfo', { ...this.fileInfo, ...{ blob: this.uploadedBlobFile } });
    }
  }
};
</script>

<style lang="scss" scoped>
.pug-upload {
  position: relative;
  .upload-container {
    width: 100%;
    height: 100%;
    position: relative;

    /deep/ .vue-pdf-embed {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .upload-area {
    display: none;
  }
  /deep/ .el-upload {
    width: 100%;
    height: 100%;
    background-color: $pug-blue-upload-bg;
    border: 1px dashed $pug-blue-upload-border;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    .vue-pdf-embed {
      position: absolute;
      top: 0;
      opacity: 0;
      transition: all 0.3s;
    }
    .el-upload-dragger {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background-color: transparent;
      border: none;
    }
    .upload-add {
      margin: 12px;
    }
  }

  .actions-item {
    cursor: pointer;
    position: absolute;

    width: 36px;
    height: 36px;
    box-shadow: 0px 4px 4px rgba(0, 74, 188, 0.2);
    border-radius: 50%;
    background-color: #fdfeff;
    display: flex;
    align-items: center;
    justify-content: center;

    &.fullscreen-btn {
      top: 6px;
      right: 6px;
    }
    &.delete-btn {
      bottom: 6px;
      right: 6px;
    }
  }

  /deep/ .el-upload-list {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    .el-upload-list__item {
      border: none;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-top: 0;
      .el-upload-list__item-thumbnail {
        background-color: transparent;
        width: auto;
        height: auto;
      }
      .el-upload-list__item-status-label {
        display: none;
      }
      .el-upload-list__item-name {
        display: none;
      }
      .el-icon-close {
        display: none;
      }
    }
  }

  // image preview styles
  /deep/ .el-image-viewer__mask {
    opacity: 0.7;
  }
  /deep/ .el-image-viewer__btn.el-image-viewer__close {
    right: 16px;
    width: 20px;
    height: 20px;
    font-size: 28px;
    i {
      background-color: #606266;
      border-radius: 50%;
      color: #fff;
    }
  }

  // loading mask
  /deep/ .el-loading-mask {
    border-radius: 20px;
    background-color: $pug-gray-opacity-40;
  }
}

// no file
.pug-upload.no-file {
  .upload-container {
    /deep/ .el-upload-list {
      display: none;
    }
  }

  .upload-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $pug-gray-pending;
    font-weight: 40;
    font-size: 16px;
    line-height: 1.2;
    position: relative;

    &.pre-view {
      display: none;
    }
  }

  .actions-item {
    display: none;
  }
}

// pdf pre-viewer
.pdf-previewer {
  /deep/ &.el-dialog__wrapper {
    overflow-x: hidden;
    overflow-y: hidden;

    padding: 0;
    .el-dialog {
      &::-webkit-scrollbar {
        width: inherit;
        height: inherit;
      }

      background-color: transparent;
      max-width: 100%;
      width: 100%;
      .el-dialog__header {
        display: none;
      }

      .el-dialog__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .close-preview-btn {
          align-self: flex-end;
          cursor: pointer;
          z-index: 1000;
          top: 20px;
          right: 16px;
          position: sticky;
          width: 28px;
          height: 28px;
          font-size: 28px;
          > i {
            color: $pug-white;
            border-radius: 50%;
            background-color: #606266;
          }
        }

        .vue-pdf-embed {
          margin: 24px auto auto auto;
          overflow-y: auto;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .pdf-previewer {
    /deep/ &.el-dialog__wrapper {
      .el-dialog {
        top: 24px;
        .el-dialog__body {
          padding: 0px;
          .close-preview-btn {
            top: 0px;
            right: 4px;
          }
          .vue-pdf-embed {
            margin: -30px auto auto auto;
          }
        }
      }
    }
  }
}
</style>
