<template>
  <div class="account-container" :class="{ 'new-account': calNewAccount, 'mobile-style': containerWidth <= 950 }">
    <div class="account-header">
      <div class="account-left">
        <AccountIcon
          :customClasses="calFailedClass"
          :accountStage="accountData.accountStage"
          :accountStatus="accountData.status"
        />
        <div class="account-content">
          <span>{{ accountData.sessionNumber }}</span>
          <span class="account-content-devider">-</span>
          <span>{{ $t(ACCOUNT_STATUS_REV[accountData.status]) }}</span>
        </div>
      </div>
      <div class="account-right">
        <div v-if="calNewAccount" class="new-tag">{{ $t('propTrade.tags.new') }}</div>
        <el-dropdown @command="handleCommand" trigger="click">
          <el-badge :hidden="false" class="item">
            <i class="el-icon-more account-more-icon" :class="{ 'menu-dot': hasPayout }"></i>
          </el-badge>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(menu, index) in dropdownList" :key="index" :command="menu.value">
              <div class="menu-item">
                {{ menu.title }}
                <div v-if="menu.value === 'payout'" class="menu-dot"></div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="account-text">
      <div class="account-text-main">
        <span class="amount">{{ accountData.currency | currencyToSymbol }}{{ formateAmount(accountData.equity) }}</span>
        <span class="currency">{{ accountData.currency }}</span>
      </div>
      <div class="profit" :class="{ minus: accountData.profitRatio < 0 }">
        {{ accountData.profitRatio !== null ? accountData.profitRatio.toFixed(2) : ''
        }}{{ accountData.profitRatio !== null ? '%' : '' }}
      </div>
    </div>
    <div class="account-footer">
      <div class="account-footer-detail">
        <!-- <div class="account-footer-item single">Application/payment time: 10-10-2023</div> -->
        <div class="account-footer-detail-item">{{ accountData.platform }}</div>
        <div v-if="accountData?.server" class="account-footer-detail-item">{{ accountData?.server }}</div>
        <div class="account-footer-detail-item" v-if="isStatusDescVisible">
          {{ statusDesc }} {{ accountData.statusDate }}
        </div>
        <span
          class="account-footer-detail-item-auth"
          v-if="isAuthVisible"
          @click.prevent="$router.push({ name: 'register' })"
        >
          <i class="el-icon-key"></i>
          {{ $t('propTrade.dashboard.completeAuthentication') }}</span
        >
      </div>
      <Button
        v-if="accountData.status !== ACCOUNT_STATUS.PROCESSING"
        customClasses="view-metrics-button"
        buttonType="default"
        :styles="{ padding: '4px 8px', 'font-size': '12px', 'font-weight': 400 }"
        outline
        dataTestId="viewMetrics"
        @click="showAccountMetrics"
      >
        <div>
          {{ $t('propTrade.button.viewMetrics') }}
        </div>
      </Button>
    </div>

    <el-drawer
      :visible.sync="showHistory"
      :direction="lang === 'ar' ? 'ltr' : 'rtl'"
      :size="drawerWidth"
      custom-class="history-container"
    >
      <template #title>
        <div class="drawer-title">
          <div class="drawer-title-main">{{ $t('propTrade.history.title') }}</div>
          <div class="drawer-title-desc">
            {{ $t('propTrade.history.sessionNumber') }} : {{ accountData.sessionNumber }}
          </div>
        </div>
      </template>
      <el-table :data="historyData" height="90%">
        <el-table-column
          property="date"
          :label="$t('propTrade.history.field.date')"
          :width="drawerColumnWidth"
        ></el-table-column>
        <el-table-column property="event" :label="$t('propTrade.history.field.event')">
          <template slot-scope="scope">
            {{ scope.row.event }}
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>

    <!-- reset password dialog -->
    <!-- <InteractiveDialog class="reset-pwd-dialog" :show.sync="showRestPassword">
      <template v-slot:body>
        <div class="reset-pwd-body">
          <div class="title">{{ $t('propTrade.resetPassword.account') }}</div>
          <div class="account-number">{{ accountData.sessionNumber }}</div>

          <el-form :model="resetPwdForm" :rules="resetFormRules" ref="resetPwdFormRef">
            <el-form-item prop="currentPwd" class="reset-form-item" ref="currentPwd">
              <TextInput
                type="password"
                isPassword
                autocomplete="off"
                :placeholder="$t('propTrade.resetPassword.currentPwdPlaceholder')"
                v-model="resetPwdForm.currentPwd"
                dataTestId="currentPwd"
              >
                <template #title>
                  {{ $t('propTrade.resetPassword.currentPwd') }}
                </template>
              </TextInput>
            </el-form-item>

            <div class="forgot-pwd" @click="forgetPwdAndSendEmail">{{ $t('propTrade.resetPassword.forgotPwd') }}</div>

            <el-form-item prop="newPwd" class="reset-form-item">
              <TextInput
                type="password"
                isPassword
                autocomplete="off"
                :placeholder="$t('propTrade.resetPassword.newPwdPlaceholder')"
                v-model="resetPwdForm.newPwd"
                dataTestId="newPwd"
              >
                <template #title>
                  {{ $t('propTrade.resetPassword.newPwd') }}
                </template>
              </TextInput>
            </el-form-item>

            <el-form-item prop="confirmNewPwd" class="reset-form-item" ref="confirmNewPwd">
              <TextInput
                type="password"
                isPassword
                autocomplete="off"
                :placeholder="$t('propTrade.resetPassword.confirmNewPwdPlaceholder')"
                v-model="resetPwdForm.confirmNewPwd"
                dataTestId="confirmNewPwd"
              >
                <template #title>
                  {{ $t('propTrade.resetPassword.confirmNewPwd') }}
                </template>
              </TextInput>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template v-slot:btn>
        <div class="action-area">
          <Button
            customClasses="action-button"
            buttonType="default"
            :styles="{ padding: '8px', 'font-size': '16px', 'font-weight': 900 }"
            outline
            dataTestId="cancelChangePassword"
            @click="cancelChangePassword"
          >
            <div :style="{ 'white-space': 'nowrap' }">
              {{ $t('propTrade.button.cancel') }}
            </div>
          </Button>

          <Button
            customClasses="action-button"
            buttonType="default"
            :styles="{ padding: '8px', 'font-size': '16px', 'font-weight': 900 }"
            dataTestId="changePassword"
            @click="changePassword"
          >
            <div :style="{ 'white-space': 'nowrap' }">
              {{ $t('propTrade.button.changePwd') }}
            </div>
          </Button>
        </div>
        <div class="cs-chat-container" @click="startChat">
          <img src="@/assets/images/dialog/cs-chat.png" class="cs-chat-image" alt="cs-chat" />
          <div class="cs-chat-desc" v-html="$t('common.liveChat.desc')"></div>
        </div>
      </template>
    </InteractiveDialog> -->

    <InteractiveDialog class="payout-dialog" :show.sync="showPayoutDialog">
      <template v-slot:body>
        <div class="reset-pwd-body">
          <div class="title">{{ $t('propTrade.payoutPage.howToProcessYourProfitSplit') }}</div>
          <div class="desc">{{ $t('propTrade.payoutPage.howToProcessYourProfitSplitDesc') }}</div>
          <div class="desc">{{ $t('propTrade.payoutPage.howToProcessYourProfitSplitDesc2') }}</div>
          <div class="desc">{{ $t('propTrade.payoutPage.howToProcessYourProfitSplitDesc3') }}</div>
          <div class="desc">{{ $t('propTrade.payoutPage.howToProcessYourProfitSplitDesc4') }}</div>
          <div class="desc">{{ $t('propTrade.payoutPage.howToProcessYourProfitSplitDesc5') }}</div>
        </div>
      </template>
      <template v-slot:btn>
        <div class="action-area">
          <Button
            customClasses="action-button"
            buttonType="default"
            :styles="{ padding: '8px', 'font-size': '16px', 'font-weight': 900 }"
            outline
            dataTestId="cancelPayoutDialog"
            @click="showPayoutDialog = false"
          >
            <div :style="{ 'white-space': 'nowrap' }">
              {{ $t('propTrade.button.cancel') }}
            </div>
          </Button>

          <Button
            customClasses="action-button"
            buttonType="default"
            :styles="{ padding: '8px', 'font-size': '16px', 'font-weight': 900 }"
            dataTestId="gotoPayout"
            @click="gotoPayout"
          >
            <div :style="{ 'white-space': 'nowrap' }">
              {{ $t('propTrade.payoutPage.submit') }}
            </div>
          </Button>
        </div>
      </template>
    </InteractiveDialog>

    <InfoDialog
      :visible.sync="showInfoDialog"
      :title="$t('propTrade.resetPassword.info')"
      infoType="general"
      class="reset-info"
      @closCbDialog="closeResetInfoDialog"
    >
      <div class="info-container">
        <div>{{ infoDialogMessage }}</div>
        <div class="cs-chat-container" @click="startChat">
          <img src="@/assets/images/dialog/cs-chat.png" class="cs-chat-image" alt="cs-chat" />
          <div class="cs-chat-desc">{{ $t('common.liveChat.desc') }}</div>
        </div>
      </div>
    </InfoDialog>

    <InfoDialog
      :visible.sync="showPoaDialog"
      :title="$t('propTrade.payoutPage.poaDialog.title')"
      infoType="pending"
      @closCbDialog="showPoaDialog = false"
    >
      <div class="info-container">
        <div>{{ $t('propTrade.payoutPage.poaDialog.message') }}</div>
      </div>
      <template #actions>
        <Button buttonType="default" @click="verifyNow">
          {{ $t('common.button.verifyNow') }}
        </Button>
      </template>
    </InfoDialog>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import TextInput from '@/components/common/TextInput.vue';
import InfoDialog from '@/components/dialog/InfoDialog.vue';
import AccountIcon from '@/components/propTrading/components/dashboard/AccountIcon.vue';
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import InteractiveDialog from '@/components/dialog/interactive/Dialog.vue';
import splitThousands from '@/util/splitThousands';
import { SESSION_STATUS_REV, ACCOUNT_STATUS_REV, ACCOUNT_STATUS, ACCOUNT_STAGE } from '@/constants/propTrading';
import { apiResetPassword, apiGetSessionHistory, apiForgetPassword } from '@/resource';
import { isSuccess } from '@/util/restApi';
import { rsa } from '@/util/encrypt';
import { currencyToSymbol } from '@/util/helper';
import passwordValidator from 'password-validator';
import { statusConfig } from '@/constants/status';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'DashboardAccount',
  props: {
    // dropdownMenu
    newAccount: {
      type: Boolean,
      default: false
    },
    accountData: {
      type: Object,
      default: () => ({})
    },
    containerWidth: {
      type: Number | String,
      default: 0
    }
  },
  components: { Button, AccountIcon, InfoDialog, TextInput, GeneralDialog, InteractiveDialog },
  data() {
    return {
      ACCOUNT_STATUS,
      ACCOUNT_STAGE,
      ACCOUNT_STATUS_REV,
      SESSION_STATUS_REV,
      // showRestPassword: false,
      showPayoutDialog: false,
      showInfoDialog: false,
      showPoaDialog: false,
      showHistory: false,
      historyData: [],
      resetPwdForm: {
        currentPwd: '',
        newPwd: '',
        confirmNewPwd: ''
      },
      schema: '',
      infoDialogMessage: ''
    };
  },
  mounted() {
    this.schema = new passwordValidator();
    this.schema.is().min(8).has().uppercase().has().digits().has().not().spaces();
  },
  computed: {
    ...mapState('register', ['poiAuditStatus', 'poaAuditStatus']),
    ...mapGetters('propTrading', ['accountStageMap']),
    lang() {
      return this.$store.state.common.lang;
    },
    calFailedClass() {
      return this.accountData?.status === ACCOUNT_STATUS.FAILED ? 'failed' : '';
    },
    calNewAccount() {
      return this.newAccount;
    },
    calAccountData() {
      return { ...this.accountData };
    },
    hasPayout() {
      return !!this.accountData.payout;
    },
    dropdownList() {
      const defaultMenu = [
        // {
        //   title: this.$t('propTrade.button.resetPassword'),
        //   value: 'resetPassword'
        // },
        this.accountData.payout
          ? {
              title: this.$t('propTrade.button.payout'),
              value: 'payout'
            }
          : null,
        {
          title: this.$t('propTrade.button.history'),
          value: 'history'
        }
      ];

      return defaultMenu.filter(Boolean);
    },
    drawerWidth() {
      if (this.containerWidth <= 768) {
        return '100%';
      } else {
        return '50%';
      }
    },
    drawerColumnWidth() {
      if (this.containerWidth <= 768) {
        return '120';
      } else {
        return '240';
      }
    },
    resetFormRules() {
      return {
        currentPwd: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('propTrade.resetPassword.currentPwd')
            }),
            trigger: 'blur'
          }
        ],
        newPwd: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('propTrade.resetPassword.newPwd')
            }),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (this.resetPwdForm?.currentPwd && value === this.resetPwdForm.currentPwd) {
                callback(new Error(this.$t('propTrade.resetPassword.newPwdErrorMsg')));
              }
              callback();
            },
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!this.schema.validate(value)) {
                callback(new Error(this.$t('common.formValidation.newPwFormat')));
              }
              callback();
            },
            trigger: 'blur'
          }
        ],
        confirmNewPwd: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('propTrade.resetPassword.confirmNewPwd')
            }),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (this.resetPwdForm?.newPwd && value !== this.resetPwdForm.newPwd) {
                callback(new Error(this.$t('propTrade.resetPassword.confirmNewPwdErrorMsg')));
              }
              callback();
            },
            trigger: 'blur'
          }
        ]
      };
    },
    statusDesc() {
      if (this.hasPayout) {
        return `${this.$t('propTrade.button.payout')} : `;
      }

      if (this.accountData.statusDate) {
        return `${this.$t('propTrade.end')} : `;
      }

      return '';
    },
    isStatusDescVisible() {
      const isEligibleAccountStatus = [
        ACCOUNT_STATUS.ACTIVE,
        ACCOUNT_STATUS.FAILED,
        ACCOUNT_STATUS.PASSED,
        ACCOUNT_STATUS.VERIFYING
      ].includes(this.accountData.status);
      return isEligibleAccountStatus ? this.hasPayout || this.accountData.statusDate : false;
    },
    isAuthVisible() {
      return (
        [ACCOUNT_STAGE.EVALUATION, ACCOUNT_STAGE.EVALUATION_PLUS].includes(this.accountData.accountStage) &&
        this.accountData.status === ACCOUNT_STATUS.VERIFYING &&
        this.poiAuditStatus !== statusConfig.completed
      );
    }
  },
  watch: {
    // showRestPassword: {
    //   immediate: true,
    //   handler(newValue) {
    //     this.handleZendeskWidgetMinimised(newValue);
    //   }
    // },
    showInfoDialog: {
      immediate: true,
      handler(newValue) {
        this.handleZendeskWidgetMinimised(newValue);
      }
    }
  },
  methods: {
    handleCommand(item) {
      if (item === 'history') {
        this.getSessionHistory(this.accountData.sessionId);
        return;
      }

      // if (item === 'resetPassword') {
      //   this.showRestPassword = true;
      //   zE('messenger', 'hide');
      //   return;
      // }

      if (item === 'payout') {
        if (this.poaAuditStatus !== statusConfig.completed) {
          this.showPoaDialog = true;
        } else {
          this.showPayoutDialog = true;
        }
      }
    },
    async gotoPayout() {
      await this.$router
        .push({
          name: 'propTradingPayout',
          params: { accountNumber: encodeURIComponent(this.accountData.accountNumber) }
        })
        .catch(() => {});
    },
    async verifyNow() {
      await this.$router.push({ name: 'register' }).catch(() => {});
    },
    formateAmount(amount) {
      return splitThousands(amount, 2);
    },
    async showAccountMetrics() {
      await this.$router
        .push({
          name: 'propTradingMetrics',
          params: { accountNumber: encodeURIComponent(this.accountData.accountNumber) }
        })
        .catch(() => {});
    },
    async getSessionHistory(sessionId) {
      this.historyData = [];
      const result = await apiGetSessionHistory(sessionId);

      if (isSuccess(result.data)) {
        this.historyData = result.data.data
          .sort((a, b) => b.id - a.id)
          .map(history => {
            const { accountStage, amount, currency } = history?.vars || {};
            let event = null;
            switch (history?.type) {
              case 0:
              case 1:
              case 7:
              case 9:
              case 10:
              case 11:
                event = this.$t(`propTrade.history.historyEvent.${history.type}`);
                break;
              case 2:
              case 3:
              case 4:
              case 5:
                let accountStageText = this.$t(`propTrade.accountStageContext.${this.accountStageMap[accountStage]}`);
                event = this.$t(`propTrade.history.historyEvent.${history.type}`, {
                  accountStage: accountStageText,
                  accountNumber: ''
                });
                break;
              case 6:
              case 8:
                event = this.$t(`propTrade.history.historyEvent.${history.type}`, {
                  currency: currencyToSymbol(currency),
                  amount
                });
                break;
              default:
                event = history?.event;
                break;
            }

            return {
              date: history.displayDate,
              event
            };
          });
      }

      this.showHistory = true;
    },
    getHistoryStatusContext(type) {
      switch (type) {
        case 0:
          return this.$t('propTrade.history.status.refunded');
        case 1:
          return this.$t('propTrade.history.status.liveAccountCreated', { account: '777777' });
        case 2:
          return this.$t('propTrade.history.status.verifyAccount', { account: '777777' });
        case 3:
          return this.$t('propTrade.history.status.challangeAccountPassed', { account: '777777' });
        case 4:
          return this.$t('propTrade.history.status.challangeAccountCreated', { account: '777777' });
        case 5:
          return this.$t('propTrade.history.status.paymentSuccess');
        default:
          return '-';
      }
    },
    // cancelChangePassword() {
    //   this.$refs.resetPwdFormRef.resetFields();
    //   this.showRestPassword = false;
    //   zE('messenger', 'show');
    // },
    // changePassword() {
    //   this.$refs.resetPwdFormRef.validate(async valid => {
    //     if (valid) {
    //       const result = await apiResetPassword({
    //         mt4Account: this.accountData.mt4Account,
    //         currentPassword: rsa(this.resetPwdForm.currentPwd),
    //         newPassword: rsa(this.resetPwdForm.newPwd),
    //         confirmNewPassword: rsa(this.resetPwdForm.confirmNewPwd)
    //       });

    //       if (isSuccess(result.data)) {
    //         this.$refs.resetPwdFormRef.resetFields();
    //         this.infoDialogMessage = this.$t('propTrade.resetPassword.succ2');
    //         this.showInfoDialog = true;
    //         // this.showRestPassword = false;
    //       }
    //     }
    //   });
    // },
    startChat() {
      zE('messenger', 'open');
      zE('messenger', 'show');
    },
    closeResetInfoDialog() {
      this.showInfoDialog = false;
      zE('messenger', 'show');
    },
    handleZendeskWidgetMinimised(target) {
      if (target) {
        zE('messenger', 'hide');
      } else {
        zE('messenger', 'show');
      }
    }
    // async forgetPwdAndSendEmail() {
    //   try {
    //     const baseUrl = location.protocol + '//' + location.hostname;
    //     const res = await apiForgetPassword({
    //       userId: this.accountData.userId,
    //       mtAccount: this.accountData.mt4Account,
    //       baseUrl
    //     });
    //     if (res.data.code == 0 && res.data.data) {
    //       this.infoDialogMessage = this.$t('propTrade.resetPassword.succ');
    //       // this.showRestPassword = false;
    //       this.showInfoDialog = true;
    //     } else {
    //       this.$message({
    //         message: this.$t('home.pw.error'),
    //         type: 'error'
    //       });
    //     }
    //   } catch (error) {
    //     this.$message({
    //       message: this.$t('home.pw.error'),
    //       type: 'error'
    //     });
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.account-container {
  flex: 0 0 49%;
  // width: 520px;
  height: 160px;
  padding: 16px;
  background-color: $pug-white;
  border-radius: 10px;

  &.new-account {
    border: 0.5px solid $pug-blue-opacity-40;
    box-shadow: 2px 2px 4px 0px #2163bc80;
  }
  &.mobile-style {
    flex: 0 0 100%;
  }
}

.account-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-tag {
  width: 100%;
  border-radius: 14px;
  height: 20px;
  // max-width: 112px;
  min-width: 80px;
  background-color: $pug-blue;
  color: $pug-white;
  text-align: center;
  line-height: 20px;
  @include rtl-sass-prop(margin-right, margin-left, 16px);
}

.account-left {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 4px 0px;
  .account-content {
    width: 100%;
    display: flex;
    align-items: center;
    &-devider {
      margin: 0 8px;
    }
  }
}
.account-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  // width: 30%;
  height: 22px;

  .new-tag {
    color: $pug-white;
    border-radius: 2px;
    background-color: $pug-red;
    font-size: 12px;
    padding: 4px;
  }

  .account-more-icon {
    transform: rotate(90deg);
    cursor: pointer;
    font-size: 20px;
    color: $pug-gray-dot;
  }
}
[dir='rtl'] * .account-right {
  .account-more-icon {
    transform: rotate(270deg);
  }
}

/deep/ .el-badge__content {
  background-color: $pug-red !important;
}
.menu-item {
  display: flex;
  align-items: center;
  min-width: 120px;
  padding: 4px 0;
}
.menu-dot {
  border-radius: 50%;
  background-color: $pug-red;
  width: 8px;
  height: 8px;
  @include rtl-sass-prop(margin-left, margin-right, 8px);
}

.account-text {
  margin: 16px 0 8px 0;
  &-main {
    margin-bottom: 12px;
    display: flex;
    align-items: flex-end;
  }
  .amount {
    @include rtl-sass-prop(margin-right, margin-left, 12px);
    font-weight: bolder;
    font-size: 22px;
    line-height: 1.2;
    color: $pug-black;
  }
  .currency {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: $pug-black;
  }
  .profit {
    color: $pug-profit-green;
    height: 18px;
    @include rtl-sass-value(text-align, left, right);
    &.minus {
      color: $pug-red;
    }
  }
}

.account-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-detail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    &-item {
      display: flex;
      align-items: center;
      color: $pug-gray-title;
      font-size: 14px;
      font-weight: 400;
      @include rtl-sass-prop(padding-right, padding-left, 16px);
      @include rtl-sass-prop(border-right, border-left, 1px solid);
      @include rtl-sass-prop(margin-left, margin-right, 16px);

      &:nth-child(1) {
        @include rtl-sass-prop(margin-left, margin-right, 0px);
      }
      &:nth-last-of-type(1) {
        @include rtl-sass-prop(border-right, border-left, none);
        @include rtl-sass-prop(padding-right, padding-left, 0px);
      }
      &.single {
        @include rtl-sass-prop(padding-right, padding-left, 0px);
        border-right: none;
        @include rtl-sass-prop(margin-left, margin-right, 0px);
      }
      &-auth {
        display: flex;
        color: #014cb2;
        margin-top: 5px;
        width: 100%;
        cursor: pointer;
        .el-icon-key {
          transform: rotate(45deg);
          margin-right: 6px;
          @include rtl-sass-prop(margin-right, margin-left, 6px);
        }
      }
    }
  }

  .view-metrics-button {
    min-width: 96px;
    width: fit-content;
    height: 30px;
  }
}

.drawer-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: $pug-black;
  &-main {
    font-weight: 900;
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 16px;
  }
  &-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
  }
}

/deep/ .reset-pwd-dialog,
/deep/.payout-dialog {
  .el-dialog__wrapper .el-dialog {
    max-width: 480px;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    // padding: 45px 55px 35px !important;
    text-align: left;
    .dialog-body {
      padding: 0;
    }

    .title {
      font-weight: 900;
      font-size: 20px;
      margin-bottom: 12px;
      color: $pug-black;
      text-align: left;
    }
    .desc {
      overflow-y: scroll;
      margin-top: 20px;
    }
    .account-number {
      font-weight: 400;
      font-size: 14px;
      color: $pug-blue-hover;
      margin-bottom: 24px;
      text-align: left;
    }
    .reset-pwd-body {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      .reset-form-item {
        margin-bottom: 20px;
      }
    }
    .forgot-pwd {
      display: flex;
      justify-content: flex-end;
      color: $pug-blue-hover;
      text-decoration: underline;
      cursor: pointer;
    }
    .action-area {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      margin-bottom: 16px;
      .action-button {
        height: 45px;
      }
    }
  }
}

[dir='rtl'] * /deep/ .reset-pwd-dialog,
[dir='rtl'] * /deep/.payout-dialog {
  .el-dialog__body {
    .title {
      text-align: right;
    }
    .account-number {
      text-align: right;
    }
  }
}

.cs-chat-container {
  margin-top: 36px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.cs-chat-image {
  width: 24px !important;
  height: 24px !important;
  @include rtl-sass-prop(margin-right, margin-left, 8px);
}
.cs-chat-desc {
  color: $pug-blue-hover;
  font-size: 16px;
  font-weight: 400;
  /deep/p {
    line-height: 1.3;
    margin-bottom: 0;
    margin-top: 0;
  }
}
/deep/ .reset-info {
  .el-dialog__body {
    padding: 8px 60px 24px 60px !important;
  }
}
.info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/deep/ .history-container {
  .el-drawer__header {
    align-items: flex-start;
    .el-drawer__close-btn {
      color: $pug-black;
    }
  }
  .el-table {
    .el-table__header-wrapper {
      .el-table__header {
        border-bottom: 1px solid $pug-pray-border;
        th {
          color: $pug-black;
          background-color: $pug-white;
          height: 60px;
          text-align: left;

          .cell {
            padding: 22px 28px;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.2;
          }
        }
      }
    }

    .el-table__body-wrapper .el-table__body .el-table__row {
      td {
        border-bottom: 2px solid $pug-pray-border;
        .cell {
          @include rtl-sass-value(padding, 0 0 0 24px, 0 24px 0 0);
          @include rtl-sass-value(text-align, left, right);
          font-size: 14px;
          font-weight: 400;
          line-height: 2;
        }
      }
    }
  }
}

[dir='rtl'] * /deep/ .history-container {
  .el-table {
    .el-table__header-wrapper {
      .el-table__header {
        th {
          .cell {
            text-align: right;
          }
        }
      }
    }
    .el-table__body-wrapper .el-table__body .el-table__row {
      td {
        .cell {
          text-align: right;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .account-container {
    min-width: 320px;
    width: 100%;
    height: 250px;
  }

  .status-tag {
    @include rtl-sass-prop(margin-right, margin-left, 8px);
  }
  .account-right {
    gap: 0px;
  }

  .account-text {
    margin: 24px 0;
  }

  .account-footer {
    flex-direction: column;
    gap: 16px;
    &-detail {
      width: 100%;
      &-item {
        @include rtl-sass-prop(margin-left, margin-right, 8px);
        @include rtl-sass-prop(padding-right, padding-left, 8px);
      }
    }

    .view-metrics-button {
      width: 100%;
      height: 36px;
    }
  }

  /deep/ .reset-pwd-dialog {
    .el-dialog__body {
      .action-area {
        flex-direction: column;
        flex-direction: column-reverse;
        gap: 12px;
        .action-button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .account-left {
    flex-wrap: wrap;
  }
}

@media (max-width: 475px) {
  /deep/ .reset-pwd-dialog {
    .el-dialog__wrapper .el-dialog {
      top: 50%;
      transform: translateY(-50%);
    }
    .el-dialog__body {
      padding: 30px 20px 12px 20px !important;
    }
  }
}

/deep/.el-form-item__error {
  position: relative;
}

@media (max-width: 430px) {
  .account-left {
    .account-content {
      &-devider {
        margin: 0 2px;
      }
    }
  }
}
</style>
