import Pagination from '@/util/pagination'

export default {
  data() {
    return {
      pagination: {
        data: [],
        source: [],
        canNext: false,
        _object: null,
      },
    }
  },

  methods: {
    initPagination(data, limit) {
      const pagination = new Pagination(data, limit)

      this.pagination._object = pagination

      this.pagination.source = data
      this.pagination.data = []

      this.paginationSelfStatusUpdate()

      this.paginationLoadMore()
    },
    paginationLoadMore() {
      if (this.pagination.canNext) {
        this.pagination.data = this.pagination.data.concat(this.pagination._object.more())
        this.paginationSelfStatusUpdate()
      }
    },

    paginationSelfStatusUpdate() {
      this.pagination.canNext = this.pagination._object.canNext
    },
  },
}
