export const TAB_ACCOUNT_SYMBOL = Symbol('account')
export const TAB_PROCESSING_AND_FAILED_SYMBOL = Symbol('processing&failed')

export const ACCOUNT_TYPE = {
  BASIC: 0,
  PLUS: 1,
}

export const ACCOUNT_TYPE_REV = {
  [ACCOUNT_TYPE.BASIC]: 'basic',
  [ACCOUNT_TYPE.PLUS]: 'plus',
}

export const DEFAULT_ACCOUNT_TYPE = ACCOUNT_TYPE.BASIC

export const ACCOUNT_STAGE = {
  ALL: null,
  CHALLENGE: 0,
  CHALLENGE_PLUS: 1,
  EVALUATION: 2,
  EVALUATION_PLUS: 3,
  LIVE_TRADING: 4,
  LIVE_TRADING_PLUS: 5,
}

export const ACCOUNT_STATUS = {
  ALL: null,
  ACTIVE: 0,
  VERIFYING: 1,
  PASSED: 2,
  FAILED: 3,
  PROCESSING: 4,
  PAYOUT_PENDING: 5
}

export const ACCOUNT_STATUS_ORDER = {
  [ACCOUNT_STATUS.ACTIVE]: 0,
  [ACCOUNT_STATUS.VERIFYING]: 3,
  [ACCOUNT_STATUS.PASSED]: 4,
  [ACCOUNT_STATUS.FAILED]: 5,
  [ACCOUNT_STATUS.PROCESSING]: 2,
  [ACCOUNT_STATUS.PAYOUT_PENDING]: 1,
}

export const ACCOUNT_STATUS_REV = {
  [ACCOUNT_STATUS.ACTIVE]: 'propTrade.statusContext.active',
  [ACCOUNT_STATUS.VERIFYING]: 'propTrade.statusContext.verifying',
  [ACCOUNT_STATUS.PASSED]: 'propTrade.statusContext.passed',
  [ACCOUNT_STATUS.FAILED]: 'propTrade.statusContext.failed',
  [ACCOUNT_STATUS.PROCESSING]: 'propTrade.statusContext.processing',
  [ACCOUNT_STATUS.PAYOUT_PENDING]: 'propTrade.statusContext.payoutPending'
}

export const SESSION_STATUS = {
  ALL: null,
  PROCESSING: 0,
  ACTIVE: 1,
  FAILED: 2,
}

export const SESSION_STATUS_REV = {
  [SESSION_STATUS.PROCESSING]: 'Processing',
  [SESSION_STATUS.ACTIVE]: 'Active',
  [SESSION_STATUS.FAILED]: 'Failed',
}

export const PROMO_CODE_TYPE = {
  RATE: 1,
  AMOUNT: 2,
}

export const METRICS_STATUS = {
  PASSED: 0,
  FAILED: 1,
  VERIFYING: 2,
  PROFIT_PENDING: 3,
  PROFIT_SPLITTED: 4,
  DEFAULT: '',
}

export const DEFAULT_TAB_CONTENT_SIZE = 12

export const DEFAULT_TAB_CONTENT_REFRESH_INTERVAL = 1000 * 60 * 10

export const COMPONENT_NAME = {
  ACCOUNT: 'Account',
  SESSION: 'Session',
}

export const PROMO_CODE_DISCOUNT_ALL_PRICE = 'Promo_Code_Discount_All_Price'

export const PAYMENT_STATUS = {
  ALL: null,
  SUBMITTED: 0,
  SUCCESS: 1,
  FAILED: 2,
  EXPIRED: 3,
  MISMATCHED: 4,
  PROCESSING: [0, 3, 4],
}

export const PAYMENT_STATUS_REV = {
  [PAYMENT_STATUS.SUBMITTED]: 'propTrade.sessionContext.Processing',
  [PAYMENT_STATUS.SUCCESS]: 'propTrade.sessionContext.success',
  [PAYMENT_STATUS.FAILED]: 'propTrade.sessionContext.fail',
  [PAYMENT_STATUS.EXPIRED]: 'propTrade.sessionContext.Processing',
  [PAYMENT_STATUS.MISMATCHED]: 'propTrade.sessionContext.Processing',
}

export const CRYPTO_USDT_TRC20_FAKE_ID = 9999
export const CRYPTO_USDT_TRC20_FAKE_ID_COPY_FROM = 36
export const CRYPTO_USDT_TRC20_FAKE_NAME_KEY = 'cryptocurrencyusdc_trc_cps'

export const PAYOUT_METHOD = {
  CRYPTO: 'crypto',
  EWALLET: 'ewallet',
}
