<template>
  <div class="show-in-pad">
    <div class="title">Verification Progress</div>
    <div class="background">
      <div class="time" :style="{ width: userPercentage + '%' }">
        <div>
          <img src="@/assets/images/register/time.png" alt="" />
          <span>{{ userPercentage }} %</span>
        </div>
      </div>
      <div class="progressbar">
        <div class="step" :style="{ width: userPercentage + '%' }" :value="userPercentage"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Progress-pad',
  props: { userProgress: Object },
  data() {
    return {
      myInterval: null,
      userPercentage: 0
    };
  },
  watch: {
    registerStep: {
      handler(number) {
        this.$nextTick(() => {
          this.myInterval = setInterval(
            function() {
              this.userPercentage >= this.userProgress.progress
                ? clearInterval(this.myInterval)
                : this.userPercentage++;
            }.bind(this),
            50
          );
        });
      },
      immediate: true
    }
  },
  computed: {
    registerStep() {
      return this.$store.state.common.registerStep;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/progress-pad.scss';
</style>
