<template>
	<div class="pt-crypto-cps-withdraw-container">
		<CryptoCpsWithdraw
			ref="TransferFormWrapper"
			v-bind="$attrs"
      v-on="$listeners"
		/>
	</div>
</template>

<script>
import CryptoCpsWithdraw from '@/components/withdraw/types/CryptoCpsWithdraw.vue'

export default {
  name: `PtCryptoCpsWithdraw`,
	components: {CryptoCpsWithdraw}
}
</script>

<style lang="scss" scoped>
.pt-crypto-cps-withdraw-container /deep/ {
	.fr.img-row {
    display: none;
  }

	.alert-info {
    display: flex;
    align-items: center;
    gap: 8px;
		margin-top: 12px;
    & > .alert_info_icon {
      width: 18px;
      height: 18px;
    }
  }
}

@media (min-width: 1200px) {
  .pt-crypto-cps-withdraw-container /deep/ {
    .form-list {
      display: flex;
      gap: 0 16px;

      > li {
        flex: 1 1 100%;
      }
    }
  }
}
</style>
