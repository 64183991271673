<template>
  <DashboardTopBanner
    dataTestId="joinChallenge"
    customClasses="pt-challenge"
    :title="title"
    :buttonText="buttonText"
    :bgImg="bgImgData"
    @clickButtonEvent="createNewAccount"
  ></DashboardTopBanner>
</template>

<script>
import DashboardTopBanner from '@/components/common/DashboardTopBanner.vue';

export default {
  name: 'PropTradingDashboardTopBanner',
  components: { DashboardTopBanner },
  data() {
    return {
      title: this.$t('propTrade.banner.dashboardBannerTitle'),
      buttonText: this.$t('propTrade.button.joinChallenge'),
      bgImgData: {
        img: require('@/assets/images/propTrading/dashboard_top_banner.png'),
        imgSmall: require('@/assets/images/propTrading/small_dashboard_top_banner.png'),
        breakpoint: 1200
      }
    };
  },
  methods: {
    async createNewAccount() {
      await this.$router.push({ name: 'propTradingCreateAccount' }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-button {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.pt-challenge {
  background: $black;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 28px;

  /deep/.title-box {
    align-self: center;
    font-size: 16px;
  }

  /deep/.bg-img {
    width: 60%;
    @include rtl-sass-value(right, 0, unset);
    @include rtl-sass-value(left, unset, 0);
    height: 100%;
    img {
      object-fit: cover;
      height: 100%;
      width: 60%;
      @include rtl-sass-value(float, right, left);
    }
  }
  /deep/.btn-box {
    display: flex;
    justify-content: flex-end;
  }
  &.normal {
    /deep/.bg-img {
      img {
        @include rtl-sass-value(float, right, left);
      }
    }
  }
}
@media (min-width: 576px) {
  .pt-challenge {
    /deep/.bg-img {
      width: 45%;
      @include rtl-sass-value(right, 0, unset);
      @include rtl-sass-value(left, unset, 0);
    }

    &.normal {
      /deep/.bg-img {
        @include rtl-sass-value(right, unset, 0);
        @include rtl-sass-value(left, 0, unset);
        img {
          @include rtl-sass-value(float, left, right);
        }
      }

      /deep/.title-box {
        @include rtl-sass-prop(margin-left, margin-right, 300px);
        white-space: nowrap;
      }
    }
  }
}
@media (min-width: 768px) {
  .pt-challenge {
    /deep/.title-box {
      font-size: 24px !important;
    }
  }
}
@media (min-width: 1200px) {
  .pt-challenge {
    /deep/.bg-img {
      width: 512px;
    }
  }
}
</style>
