export default (fn, timeout = 1000) => {
  let timer = null

  const wrap = () => {
    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(async () => {
      await fn()

      wrap(fn, timeout)
    }, timeout)
  }

  const stop = () => clearTimeout(timer)

  const restart = () => wrap()

  return { stop, intervalFn: wrap, restart }
}
