<template>
  <div class="progress">
    <div class="top">
      <span>{{ $t('register.progressStatus.title') }}</span>
      <div class="time" :style="{ width: userPercentage + '%' }">
        <div>
          <img src="@/assets/images/register/time.png" alt="" />
          <span>{{ userPercentage }} %</span>
        </div>
      </div>
      <div class="progressbar">
        <div class="step" :style="{ width: userPercentage + '%' }" :value="userPercentage"></div>
      </div>
    </div>
    <div class="bottom">
      <span> {{ $t('register.progressStatus.estimatedTimeTitle') }} >>><br /> </span>
      <div class="img">
        <div>
          <img src="@/assets/images/register/clock.png" alt="" />
          <b>
            {{ userProgress.minutes }} <br />
            {{ $t('register.progressStatus.min') }}
          </b>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { userProgress: Object },
  data() {
    return {
      circle: {
        r: 67,
        cx: 72,
        cy: 72,
        circumference: 67 * 2 * Math.PI
      },
      myInterval: null,
      userPercentage: 0
    };
  },
  watch: {
    registerStep: {
      handler(number) {
        this.$nextTick(() => {
          this.myInterval = setInterval(
            function() {
              if (this.userPercentage >= this.userProgress.progress) clearInterval(this.myInterval);
              else this.userPercentage++;
            }.bind(this),
            50
          );
        });
      },
      immediate: true
    }
  },
  computed: {
    registerStep() {
      return this.$store.state.common.registerStep;
    },
    strokeDashoffset() {
      return this.circle.circumference - (this.userPercentage / 100) * this.circle.circumference;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/progress.scss';
</style>
