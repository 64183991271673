<template>
  <div class="pt-usdt-withdraw-container">
		<UsdtWithdraw
			ref="TransferFormWrapper"
			v-bind="$attrs"
      v-on="$listeners"
		/>
	</div>
</template>

<script>
import UsdtWithdraw from '@/components/withdraw/types/UsdtWithdraw.vue';

export default {
  name: `PtUsdtWithdraw`,
	components: {UsdtWithdraw},
}
</script>

<style lang="scss" scoped>
.pt-usdt-withdraw-container /deep/ {
  .fr.img-row {
    display: none;
  }

  .alert-info {
		grid-column: 1 / -1;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    & > .alert_info_icon {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
