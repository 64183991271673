<template>
  <div class="main-head" :class="{ mobile: mobile }">
    <div class="reminder-container">
      <div class="title">{{ $t('propTrade.reminder.title') }}</div>
      <div class="narrative">{{ $t('propTrade.reminder.narrative') }}</div>
    </div>
    <Button
      customClasses="verify-noe-button"
      buttonType="default"
      :styles="{ padding: '10px', 'font-size': '12px', 'font-weight': 500 }"
      @click="verifyNow"
      dataTestId="verifyNow"
    >
      <div class="verify-noe-button-text">
        <div>{{ $t('propTrade.button.verifyNow') }}</div>
      </div>
    </Button>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';

export default {
  name: 'DashboardSearchbar',
  components: { Button },
  props: {
    containerWidth: {
      type: Number | String,
      default: 0
    }
  },
  data() {
    return {};
  },
  computed: {
    mobile() {
      return this.containerWidth <= 700;
    }
  },
  methods: {
    async verifyNow() {
      await this.$router.push({ name: 'register' }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.main-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $pug-white;
  padding: 20px 18px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.reminder-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .title {
    color: $sky-blue;
    font-size: 18px;
    font-weight: 900;
  }

  .narrative {
    margin-top: 2px;
    color: $pug-black;
    font-size: 14px;
  }
}
.verify-noe-button {
  width: fit-content;
  height: 40px;
  max-width: 164px;
  width: 164px;
  &-icon {
    border-radius: 50%;
    margin-right: 8px;
  }
  &-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// mobile style
.main-head.mobile {
  flex-direction: column;
  gap: 12px;

  .reminder-container {
    width: 100%;
  }

  .verify-noe-button {
    max-width: unset;
    width: 100%;
  }
}
</style>
