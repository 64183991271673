<template>
  <el-radio-button :label="paymentName" :data-testid="`pt_payment_${convertDataTestId(paymentName)}`">
    <div class="payment-radio-item-box" @click="cpsClick(payment)">
      <div class="payment-img" v-if="calShowImg">
        <img :src="ptPaymentLogo(paymentName)" :alt="paymentName" />
      </div>
      <div class="payment-name" v-if="calShowName">
        {{ langTranslate('deposit.default.channels.', paymentName) }}
      </div>
    </div>
  </el-radio-button>
</template>

<script>
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';
export default {
  name: 'PaymentRadio',
  mixins: [cpsMixin],
  props: {
    payment: {
      type: Object,
      default: () => {}
    },
    showImg: {
      type: Boolean,
      default: true
    },
    showName: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      calShowImg: this.showImg,
      calShowName: this.showName
    };
  },
  computed: {
    paymentName() {
      return this.payment.merchant_variable?.label || this.payment.merchant_variable?.description_title;
    }
  },
  methods: {
    ptPaymentLogo(value) {
      let imgFile = '';

      switch (value) {
        case 'BITCOIN':
          imgFile = 'bitcoin';
          break;
        case 'USDT-ERC20':
          imgFile = 'usdterc20';
          break;
        case 'USDT-TRC20':
          imgFile = 'usdttrc20';
          break;
        case 'USDC-ERC20':
          imgFile = 'usdcerc20';
          break;
        case 'ETH':
          imgFile = 'eth';
          break;
        case 'Bitwallet':
          imgFile = 'bitwallet';
          break;
        case 'Fasapay':
          imgFile = 'fasapay';
          break;
        case 'SticPay':
        case 'sticpay':
        case 'Sticpay':
          imgFile = 'sticpay';
          break;
        case 'Perfect Money':
          imgFile = 'perfect_money';
          break;
        case 'Skrill':
          imgFile = 'skrill';
          break;
        case 'Neteller':
          imgFile = 'neteller';
          break;
        case 'EBuy':
          imgFile = 'ebuy';
          break;
        case 'Alipay':
          imgFile = 'alipay';
          break;
        case 'AirTM':
          imgFile = 'airtm';
          break;
      }

      // if not mapping img file, hide img and show name
      if (!imgFile) {
        this.calShowImg = false;
        this.calShowName = true;
        return;
      }

      return require(`@/assets/images/propTrading/${imgFile}.png`);
    },
    cpsClick(payment) {
      payment.attach_variable = payment.attach_variable ? payment.attach_variable : '';
      payment.merchant_variable = payment.merchant_variable ? payment.merchant_variable : '';
      // this.$store.dispatch('cps/actionSetPayment', payment);
      this.$emit('changePaymentDetail', payment);
    }
  }
};
</script>
<style lang="scss" scoped></style>
