<template>
  <component :is="currentComponent"></component>
</template>

<script>
import { isRegisterFileExist } from '@/util/checkFileExist';

export default {
  name: 'IdentityVerificationChildView',
  computed: {
    currentComponent() {
      let regulatorFileName = this.regulator.toUpperCase();
      let fileName = isRegisterFileExist(regulatorFileName, this.$route.name) ? regulatorFileName : 'Default';
      return () => import(`@/components/register/${this.$route.name}/${fileName}`);
    }
  }
};
</script>
