<template>
  <div>
    <div class="card">
      <div class="title-box">
        <h4 class="sub-title">{{ $t('propTrade.createAccount.billingCreditCard.subTitle') }}</h4>
      </div>
      <div class="credit-card-box">
        <div id="main" v-show="loadTemplate"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillingCreditCard',
  props: {
    chooseAccountData: {
      type: Object,
      default: () => {}
    },
    additionalData: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      loadTemplate: false
    };
  },
  mounted() {
    this.loadTemplate = true;

    const data = this.additionalData;

    this.showBridgerpay(data);
  },
  methods: {
    showBridgerpay(data) {
      const dataJson = JSON.parse(data);
      let script = document.createElement('script');
      script.setAttribute('src', dataJson.url);
      script.setAttribute('data-cashier-key', dataJson.key);
      script.setAttribute('data-theme', 'dark');
      script.setAttribute('data-cashier-token', dataJson.token);
      document.getElementById('main').appendChild(script);
    }
  }
};
</script>
<style lang="scss" scoped>
.credit-card-box {
  max-width: 780px;
  margin: 0 auto;
}
</style>
