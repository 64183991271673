<template>
  <div class="content-box adjust-header fit-content">
    <div class="no-menu-inner">
      <div class="pt-create-account">
        <el-button type="text" icon="el-icon-back" class="back-btn" @click="goToDestination">
          {{ $t('propTrade.button.backToClientPortal') }}
        </el-button>
        <div class="header">
          <h3 class="title">{{ $t(`propTrade.createAccount.${currentStep}.title`) }}</h3>
          <p>
            {{
              $te(`propTrade.createAccount.${currentStep}.description`)
                ? $t(`propTrade.createAccount.${currentStep}.description`)
                : ''
            }}
          </p>
          <p class="narrative" v-if="currentStep !== 'billingCreditCard'">
            {{ $t('propTrade.createAccount.narrative') }}
          </p>
        </div>
        <ChooseAccount
          v-show="currentStep === CHOOSE_ACCOUNT"
          @redirectCurrentPage="redirectCurrentPage"
          @changeChooseAccountData="changeChooseAccountData"
        ></ChooseAccount>
        <BillingDetail
          v-if="currentStep === BILLING_DETAIL"
          :chooseAccountData="chooseAccountData"
          @redirectCurrentPage="redirectCurrentPage"
        ></BillingDetail>
        <BillingCreditCard
          v-if="currentStep === BILLING_CREDIT_CARD"
          :chooseAccountData="chooseAccountData"
          :additionalData="additionalData"
          @redirectCurrentPage="redirectCurrentPage"
        ></BillingCreditCard>
        <SubscriptionCryptoQrCode v-if="currentStep === CRYPTO_QRCODE" :additionalData="additionalData" />
      </div>
    </div>
  </div>
</template>

<script>
import ChooseAccount from '../components/createAccount/ChooseAccount.vue';
import BillingDetail from '../components/createAccount/BillingDetail.vue';
import BillingCreditCard from '../components/createAccount/BillingCreditCard.vue';
import SubscriptionCryptoQrCode from '../components/createAccount/SubscriptionCryptoQrcode.vue';

const CHOOSE_ACCOUNT = 'chooseAccount';
const BILLING_DETAIL = 'billingDetail';
const BILLING_CREDIT_CARD = 'billingCreditCard';
const CRYPTO_QRCODE = 'cryptoQrcode';

export default {
  name: 'PropTradingCreateAccount',
  components: { ChooseAccount, BillingDetail, BillingCreditCard, SubscriptionCryptoQrCode },
  data() {
    return {
      CHOOSE_ACCOUNT,
      BILLING_DETAIL,
      BILLING_CREDIT_CARD,
      CRYPTO_QRCODE,
      currentStep: 'chooseAccount',
      chooseAccountData: {},
      additionalData: null
    };
  },
  watch: {
    currentStep(val) {
      this.scrollToTop();
    }
  },
  methods: {
    scrollToTop() {
      this.$nextTick(() => {
        document.getElementById('elMain').scrollTop = 0;
      });
    },
    goToDestination() {
      this.$router.push({ name: 'home' });
    },
    redirectCurrentPage(data, additionalData) {
      this.additionalData = additionalData;
      this.currentStep = data;
    },
    changeChooseAccountData(data) {
      this.chooseAccountData = data;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-button {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.no-menu-inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}
.back-btn {
  color: $black;
  padding: 0;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 24px;
  text-transform: none;
}
.pt-create-account {
  .title {
    font-size: 18px;
    font-weight: bold;
    color: $pug-black;
    margin-bottom: 8px;
    text-transform: none;
  }
  /deep/.narrative {
    font-size: 14px;
    margin-bottom: 24px;
    a {
      color: $pug-blue;
    }
  }
  /deep/.card {
    border-radius: 16px;
    padding: 28px 21px;
    margin-top: 16px;
    background-color: $pug-white;
  }
  /deep/.sub-title {
    font-size: 16px;
    font-weight: 700;
    color: $pug-black;
    margin-bottom: 10px;
  }
}

@media (min-width: 842px) {
  .pt-create-account {
    .title {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .narrative {
      margin-bottom: 30px;
    }
    /deep/.card {
      padding: 40px 80px;
    }
    .sub-title {
      font-size: 18px;
    }
  }
}
</style>
