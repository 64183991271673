<template>
  <el-form :model="form" :rules="formRules" ref="form">
    <el-row :gutter="40">
      <el-col :sm="12" :xs="24">
        <el-form-item prop="firstName">
          <TextInput
            type="text"
            id="firstName"
            :title="$t('register.identityVerification.firstName')"
            v-model="form.firstName"
            dataTestId="firstName"
            :placeholder="$t('register.identityVerification.firstName')"
            disabled
          />
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item prop="middleName">
          <TextInput
            type="text"
            id="middleName"
            :title="$t('register.identityVerification.middleName')"
            v-model="form.middleName"
            dataTestId="middleName"
            :placeholder="$t('register.identityVerification.middleName')"
            disabled
          />
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item prop="lastName">
          <TextInput
            type="text"
            id="lastName"
            :title="$t('register.identityVerification.lastName')"
            v-model="form.lastName"
            dataTestId="lastName"
            :placeholder="$t('register.identityVerification.lastName')"
            disabled
          />
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item prop="nationalityId">
          <Selection
            :title="$t('register.identityVerification.nationality')"
            v-model="form.nationalityId"
            :placeholder="$t('register.identityVerification.nationality')"
            :options="nationalities"
            filterable
            disabled
            selectType="underline"
            dataTestId="nationalityId"
          />
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item prop="idType">
          <Selection
            :title="$t('register.identityVerification.idType')"
            v-model="form.idType"
            :placeholder="$t('common.formValidation.answerReq')"
            :options="idTypeList"
            filterable
            selectType="underline"
            dataTestId="idType"
            :disabled="isMalaysiaId3 || isResubmit"
          />
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item prop="idSerialNumber">
          <TextInput
            type="text"
            id="idSerialNumber"
            :title="idTypeLabel[form.idType]"
            v-model="form.idSerialNumber"
            :placeholder="$t('common.formValidation.sameAsID')"
            dataTestId="idSerialNumber"
            :disabled="isResubmit"
          />
        </el-form-item>
      </el-col>

      <!-- id3Pass -->
      <div v-show="showId3PassFields">
        <el-col :sm="24" :xs="24">
          <el-form-item prop="countryId" class="half-row">
            <Selection
              :title="$t('register.poaVerification.country')"
              v-model="form.countryId"
              :placeholder="$t('common.formValidation.answerReq')"
              :options="countries"
              filterable
              selectType="underline"
              dataTestId="countryId"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :sm="6" :xs="12">
          <el-form-item prop="streetNumber">
            <TextInput
              type="text"
              id="address"
              :title="$t('register.identityVerification.streetNumber')"
              :placeholder="$t('register.identityVerification.streetNumber')"
              v-model="form.streetNumber"
              dataTestId="streetNumber"
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="6" :xs="12">
          <el-form-item prop="address">
            <TextInput
              type="text"
              id="address"
              :title="$t('register.identityVerification.streetName')"
              :placeholder="$t('register.identityVerification.streetName')"
              v-model="form.address"
              dataTestId="streetName"
              maxlength="256"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item prop="state">
            <TextInput
              type="text"
              id="state"
              :title="$t('register.poaVerification.provinceState')"
              :placeholder="$t('register.poaVerification.provinceState')"
              v-model="form.state"
              dataTestId="state"
              maxlength="128"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item prop="suburb">
            <TextInput
              type="text"
              id="suburb"
              :title="$t('register.poaVerification.citySuburb')"
              :placeholder="$t('register.poaVerification.citySuburb')"
              v-model="form.suburb"
              dataTestId="suburb"
              maxlength="128"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item prop="postcode">
            <TextInput
              type="text"
              id="postcode"
              :title="$t('register.poaVerification.postalCode')"
              :placeholder="$t('register.poaVerification.postalCode')"
              v-model="form.postcode"
              dataTestId="postcode"
              maxlength="64"
            />
          </el-form-item>
        </el-col>
      </div>

      <el-col :span="24" v-show="!needId3Verification">
        <div class="upload-container">
          <div class="upload-head">
            <div class="upload-main">
              <div class="upload-main-title">{{ $t('register.identityVerification.upload.title') }}</div>
              <p class="upload-main-desc">{{ $t('register.identityVerification.upload.desc') }}</p>
            </div>

            <Button type="text" customClasses="upload-guide" @click="showGuide = true">
              <div class="upload-guide-text">
                {{ $t('register.identityVerification.upload.guideline') }}
              </div>
            </Button>
          </div>

          <div class="upload-area">
            <el-form-item prop="positiveFile">
              <PugUpload
                ref="IDFirst"
                customClasseses="upload-area-item"
                @updateFileInfo="updatePositiveFileInfo"
                @onErrorOccur="onPositiveFileErrorOccur"
                dataTestId="positiveFile"
                :files="existingPositiveFile"
              >
                <template #uploadBottomContent>
                  {{ $t('register.identityVerification.upload.positive') }}
                </template>
              </PugUpload>
            </el-form-item>
            <el-form-item prop="signatureFile">
              <PugUpload
                ref="IDSecond"
                customClasseses="upload-area-item"
                @updateFileInfo="updateSignatureFileInfo"
                @onErrorOccur="onSignatureFileErrorOccur"
                dataTestId="signatureFile"
                :files="existingSignatureFile"
              >
                <template #uploadBottomContent>
                  {{ $t('register.identityVerification.upload.signaturePage') }}
                </template>
              </PugUpload>
            </el-form-item>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="action-area">
      <Button
        v-if="propPreStep"
        buttonType="default"
        customClasses="action-button"
        outline
        dataTestId="back"
        @click="goBack"
      >
        <div>{{ $t('register.btn.back') }}</div>
      </Button>
      <Button buttonType="default" customClasses="action-button" dataTestId="confirm" @click="submit('form')">
        <div>{{ this.propNextStep !== DEPOSIT_PATHNAME ? $t('register.btn.next') : $t('common.button.confirm') }}</div>
      </Button>
    </div>

    <GeneralDialog :visible.sync="showGuide" class="guide-dialog" visibleFooter needFullscreen hideChat>
      <div class="guide-title">{{ $t('verificationStatus.uploadTemplate.idGuideTitle') }}</div>
      <div class="demo-area">
        <div class="demo-correct">
          <div class="demo-item">
            <img class="demo-item-main" src="@/assets/images/register/guide/passport.png" alt="" />
            <img class="demo-item-icon" src="@/assets/images/register/guide/correct.png" alt="" />
          </div>
        </div>
        <div class="demo-fail">
          <div class="demo-item">
            <img class="demo-item-main" src="@/assets/images/register/guide/blurry.png" alt="" />
            <img class="demo-item-icon" src="@/assets/images/register/guide/fail.png" alt="" />
          </div>
          <div class="demo-item">
            <img class="demo-item-main" src="@/assets/images/register/guide/edgeMissing.png" alt="" />
            <img class="demo-item-icon" src="@/assets/images/register/guide/fail.png" alt="" />
          </div>
          <div class="demo-item">
            <img class="demo-item-main" src="@/assets/images/register/guide/reflective.png" alt="" />
            <img class="demo-item-icon" src="@/assets/images/register/guide/fail.png" alt="" />
          </div>
        </div>
      </div>

      <div class="guide-list">
        <div v-for="(item, index) in guideList" :key="index" class="guide-list-item">
          <img class="guide-list-type" :src="require(`@/assets/images/register/guide/${item.type}.png`)" alt="" />
          <p class="guide-list-desc">{{ item.content }}</p>
        </div>
      </div>

      <div class="guide-bottom">
        <div class="guide-bottom-text">{{ $t('verificationStatus.uploadTemplate.bottomDesc') }}</div>
        <Button
          buttonType="default"
          :customClasses="['proceed-upload-btn']"
          dataTestId="proceedToUpload"
          @click="showGuide = false"
        >
          {{ $t('register.btn.proceedToUpload') }}
        </Button>
      </div>
    </GeneralDialog>

    <InfoDialog
      :visible.sync="showResDialog"
      :title="resDialogTitle"
      :infoType="resInfoType"
      @closCbDialog="goToStep(closeInfoDialogDestination)"
    >
      <div>{{resDialogContent}}</div>

      <template #actions>
        <Button buttonType="default" class="mb-3" height="56" @click="goToStep('despoit')" dataTestId="depositNow">
          {{ $t('verificationStatus.kyc.depositNow') }}
        </Button>
        <Button buttonType="default" :outline="true" height="56" @click="goToDownloads" dataTestId="downloadMetaTrader">
          {{ $t('verificationStatus.kyc.downloadMetaTrader') }}
        </Button>
      </template>
    </InfoDialog>
  </el-form>
</template>

<script>
import Button from '@/components/common/Button.vue';
import Selection from '@/components/common/Selection.vue';
import TextInput from '@/components/common/TextInput.vue';
import PugUpload from '@/components/common/Upload.vue';
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import InfoDialog from '@/components/dialog/InfoDialog.vue';
import { getUserStep, getNextRegisterStep, getIdTypeList, getStatus, checkRegisterPageCanAudit } from '@/util/register';
import { apiGetData, apiProcess } from '@/resource/register';
import {
  apiVerification2IdentityProofs,
  apiUpdate_user_id_address,
  apiGet_last_identifiy_proof,
  apiDoId3
} from '@/resource';
import { REGISTERROUTER, DEPOSIT_PATHNAME } from '@/constants/route';
import { statusConfig } from '@/constants/status';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { countryCodeEnum } from '../../../constants/country';
import { urlToFile } from '@/util/urlToFile';

const SPECIAL_CARD_ID_NATIONALITYS = [103]; // Hong Konger---103
const SPECIAL_CARD_ID_COUNTRYs = [countryCodeEnum.MACAO, countryCodeEnum.HONG_KONG]
export default {
  name: 'IdentityVerificationDefault',
  components: { Button, Selection, PugUpload, TextInput, GeneralDialog, InfoDialog },
  props: {
    nextStep: {
      type: String,
      default: null
    },
    preStep: {
      type: String,
      default: null
    },
    isIBT: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileType: 12, // IDProof: 12, AddressProof: 11
      documentType: null,
      existingPositiveFile: [],
      existingSignatureFile: [],
      form: {
        firstName: '',
        middleName: '',
        lastName: '',
        nationalityId: '',
        idType: null,
        idSerialNumber: '',
        positiveFile: [],
        signatureFile: [],
        countryId: '',
        address: '',
        streetNumber: '',
        suburb: '',
        state: '',
        postcode: ''
      },
      formRules: {
        firstName: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.identityVerification.firstName')
            }),
            trigger: 'blur'
          }
        ],
        // middleName: [
        //   {
        //     required: true,
        //     message: this.$t('common.formValidation.dynamicReq', {
        //       dynamic: this.$t('register.identityVerification.middleName')
        //     }),
        //     trigger: 'blur'
        //   }
        // ],
        lastName: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.identityVerification.lastName')
            }),
            trigger: 'blur'
          }
        ],
        nationalityId: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.identityVerification.nationality')
            }),
            trigger: ['blur', 'change']
          }
        ],
        idType: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.identityVerification.idType')
            }),
            trigger: ['blur', 'change']
          }
        ],
        idSerialNumber: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(
                  new Error(
                    this.$t('common.formValidation.dynamicReq', {
                      dynamic: this.currentIdTypeLabel
                    })
                  )
                );
              }
              // ACM-31459 HK \Macao 客户的ID证件格式为A123456(1) 取消限制()的输入
              if (this.form.idType == 1 && (SPECIAL_CARD_ID_NATIONALITYS.includes(Number(this.form.nationalityId)) || SPECIAL_CARD_ID_COUNTRYs.includes(Number((this.form.countryId))))) {
                if (!/^[a-zA-Z0-9]+(\([a-zA-Z-0-9]+\))?$/.test(value)) {
                  return callback(new Error(this.$t('common.formValidation.idNumberReqNew')));
                }else {
                  return callback()
                }  
              }

              if (!/^[a-zA-Z0-9]+$/.test(value)) {
                return callback(new Error(this.$t('common.formValidation.idNumberReq')));
              }

              // only MALAYSIA & idCard => limit idSerialNumber to 12 digits number
              if (this.form.idType === 1 && this.countryCode == countryCodeEnum.MALAYSIA) {
                if (!/^\d{12}$/.test(value)) {
                  return callback(new Error(this.$t('common.formValidation.digits12WithoutDash')));
                }
              }

              // switch (this.form.idType) {
              //   case 1:
              //     if (!/^\d{12}$/.test(value)) {
              //       return callback(new Error(this.$t('common.formValidation.digits12WithoutDash')));
              //     }
              //     break;
              //   // case 1:
              //   // case 3:
              //   //   if (!/^\d{12}$/.test(value)) {
              //   //     return callback(new Error(this.$t('common.formValidation.digits12WithoutDash')));
              //   //   }
              //   //   break;
              //   // case 2: // passport
              //   //   if (!/^[A-Za-z]\d{8}$/.test(value)) {
              //   //     return callback(new Error(this.$t('common.formValidation.passportFormat')));
              //   //   }
              //   //   break;
              //   default:
              //     break;
              // }

              return callback();
            },
            trigger: 'blur'
          }
        ],
        positiveFile: [
          {
            validator: this.validatePositiveFile
          }
        ],
        signatureFile: [
          {
            validator: this.validateSignatureFile
          }
        ],
        countryId: [
          {
            validator: (rule, value, callback) => {
              if (!this.showId3PassFields) return callback();

              if (!value) {
                return callback(new Error(this.getFieldErrorMsg('countryId')));
              } else {
                return callback();
              }
            },
            trigger: 'change'
          }
        ],
        streetNumber: [
          {
            validator: (rule, value, callback) => {
              if (!this.showId3PassFields) return callback();

              if (!value) {
                return callback(new Error(this.getFieldErrorMsg('streetNumber')));
              } else {
                return callback();
              }
            },
            trigger: 'blur'
          }
        ],
        address: [
          {
            validator: (rule, value, callback) => {
              if (!this.showId3PassFields) return callback();

              if (!value) {
                return callback(new Error(this.getFieldErrorMsg('address')));
              } else {
                return callback();
              }
            },
            trigger: 'blur'
          }
        ],
        postcode: [
          {
            validator: (rule, value, callback) => {
              if (!this.showId3PassFields) return callback();

              if (!value) {
                return callback(new Error(this.getFieldErrorMsg('postcode')));
              } else {
                return callback();
              }
            },
            trigger: 'blur'
          }
        ],
        suburb: [
          {
            validator: (rule, value, callback) => {
              if (!this.showId3PassFields) return callback();

              if (!value) {
                return callback(new Error(this.getFieldErrorMsg('suburb')));
              } else {
                return callback();
              }
            },
            trigger: 'blur'
          }
        ],
        state: [
          {
            validator: (rule, value, callback) => {
              if (!this.showId3PassFields) return callback();

              if (!value) {
                return callback(new Error(this.getFieldErrorMsg('state')));
              } else {
                return callback();
              }
            },
            trigger: 'blur'
          }
        ]
      },
      positiveFileCallbackError: null,
      signatureFileCallbackError: null,
      showGuide: false,
      showResDialog: false,
      idTypeLabel: {
        1: this.$t('register.identityVerification.idSerialNumber'),
        2: this.$t('register.identityVerification.passportNum'),
        3: this.$t('register.identityVerification.driverLicenseNum'),
        4: this.$t('register.identityVerification.ID')
      },
      resInfoType: 'success',
      resDialogTitle: this.$t('register.identityVerification.successInfo.title'),
      resDialogContent: this.$t('register.identityVerification.successInfo.content', {
        mail: this.emailMasked
      }),
      doId3ButFail: false,
      positiveBlob: null,
      signatureBlob: null
    };
  },
  computed: {
    ...mapState('common', ['id3Pass']),
    ...mapState('register', ['currStep', 'poiAuditStatus', 'poaAuditStatus', 'ibtPoiAuditStatus', 'ibtPoaAuditStatus']),
    ...mapGetters('register', ['nationalities', 'phoneCodes', 'countries', 'idPass', 'isPoiUnderReview']),
    emailMasked() {
      return this.$store.state.common.emailMasked;
    },
    auditTypeMap() {
      // general poi process: 1, IBT process: 2
      return this.isIBT ? 2 : 1;
    },
    isResubmit() {
      return this.isIBT
        ? this.ibtPoiAuditStatus === statusConfig.pending || this.ibtPoiAuditStatus === statusConfig.rejected
        : this.poiAuditStatus === statusConfig.pending || this.poiAuditStatus === statusConfig.rejected;
    },
    resubmitType() {
      if (this.isResubmit) {
        return this.isIBT ? getStatus(this.ibtPoiAuditStatus) : getStatus(this.poiAuditStatus);
      }
      return null;
    },
    guideList() {
      return [
        {
          type: 'correct',
          content: this.$t('verificationStatus.uploadTemplate.desc1')
        },
        {
          type: 'correct',
          content: this.$t('verificationStatus.uploadTemplate.desc2')
        },
        {
          type: 'correct',
          content: this.$t('verificationStatus.uploadTemplate.desc3')
        },
        {
          type: 'correct',
          content: this.$t('verificationStatus.uploadTemplate.desc4')
        },
        {
          type: 'fail',
          content: this.$t('verificationStatus.uploadTemplate.desc5')
        },
        {
          type: 'fail',
          content: this.$t('verificationStatus.uploadTemplate.desc6')
        }
      ];
    },
    isMalaysiaId3() {
      // Malaysia ID3Pass only allowed ID Card
      return this.countryCode == countryCodeEnum.MALAYSIA && !this.doId3ButFail && !this.isIBT;
    },
    idTypeList() {
      return getIdTypeList(this.isIBT, this.isMalaysiaId3);
    },
    currentIdTypeLabel() {
      return this.form?.idType ? this.idTypeLabel[this.form.idType] : null;
    },
    lang() {
      return this.$store.state.common.lang;
    },
    userStep() {
      return getUserStep(this.$route.name);
    },
    propNextStep() {
      return this.nextStep;
    },
    propPreStep() {
      return this.preStep;
    },
    registerId3Countries() {
      return this.$config.registerId3Countries(this.regulator);
    },
    registerId3NeedAdditionalForm() {
      return this.$config.registerId3NeedAdditionalForm(this.regulator);
    },
    needId3Verification() {
      if (this.isIBT || this.isResubmit || this.doId3ButFail) return false;
      return this.countryCode && this.registerId3Countries.includes(parseInt(this.countryCode));
    },
    showId3PassFields() {
      return this.needId3Verification && this.registerId3NeedAdditionalForm.includes(parseInt(this.countryCode));
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    closeInfoDialogDestination() {
      const completeGeneralProcess =
        this.poiAuditStatus === statusConfig.completed && this.poaAuditStatus === statusConfig.completed;
      return completeGeneralProcess ? 'deposit' : 'register';
    }
  },
  watch: {
    needId3Verification: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.countries.length) {
          // if user country need Id3 verification, set countryId for country value
          this.form.countryId = this.countries.find(item => item.value === parseInt(this.countryCode)).value;
        }
      }
    },
    'form.idType'(newValue, oldValue) {
      if (oldValue && newValue && this.$refs.form) {
        this.$refs.form.validateField('idSerialNumber');
      }
    }
  },
  created() {
    this.DEPOSIT_PATHNAME = DEPOSIT_PATHNAME;
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations('common', ['setId3Pass']),
    ...mapActions('common', ['actionUpdateId3Pass']),
    ...mapActions('register', [
      'actionNationalities',
      'actionCountries',
      'actionStep',
      'actionPoiAuditStatus',
      'actionUpdateIBTStatus',
      'actionChangePoiAuditStatus',
      'actionChangeIbtPoiAuditStatus',
      'actionPendingThenReSubmit',
      'actionRejectThenReSubmit',
      'actionGetCurrentStep'
    ]),
    async init() {
      let isRedirect = false;

      if (!this.isIBT) {
        // Handle manual upload POI in AP before POI step
        await this.actionGetCurrentStep();
        await this.actionPoiAuditStatus();

        if (this.currStep > 3 && (this.isPoiUnderReview || this.idPass)) {
          isRedirect = true;
          this.$router.push(REGISTERROUTER.REGISTER);
        }
      }

      if (!isRedirect) {
        await this.getData();
        this.actionNationalities(this.lang);
        this.actionCountries(this.lang);
      }
    },
    async getData() {
      const routerData = this.$route.params;

      let userChoseIdType = null;
      let userChoseIdSerialNumber = null;
      let userChoseFileList = null;

      if (routerData?.ibtPoi && Object.keys(routerData?.ibtPoi).length) {
        const { idType, idNumber, positiveBlob, signatureBlob } = routerData?.ibtPoi;
        this.form.idType = idType;
        this.form.idSerialNumber = idNumber;
        userChoseIdType = idType;
        userChoseIdSerialNumber = idNumber;
        userChoseFileList = [positiveBlob, signatureBlob];
      }

      try {
        let step = this.userStep > 5 ? 5 : this.userStep;
        const res = await apiGetData(step);
        if (res.data.code === 0 && res.data?.data) {
          const list =
            this.isIBT && res.data.data.idAdvancedDocFilePathList?.length
              ? res.data.data.idAdvancedDocFilePathList
              : res.data.data.idDocFilePathList;
          const uploadRefs = [this.$refs.IDFirst.$refs.uploadRef, this.$refs.IDSecond.$refs.uploadRef];

          for (let i = 0; i < 2; i++) {
            if (userChoseFileList && userChoseFileList[i]) {
              const uploadRef = uploadRefs[i];
              uploadRef.handleStart(userChoseFileList[i].raw);
              uploadRef.submit();
            } else if (list[i]) {
              if (this.resubmitType == statusConfig.pending) {
                const blob = await urlToFile(list[i]);
                const fileKey = i === 0 ? 'existingPositiveFile' : 'existingSignatureFile';
                const objUrl = URL.createObjectURL(blob);
                this[fileKey] = [
                  {
                    url: objUrl,
                    name: blob.name,
                    type: blob.type,
                    raw: blob,
                    size: blob.size,
                    response: {
                      code: 0,
                      data: objUrl
                    },
                    existingFilePath: list[i]
                  }
                ];
                this.form[i === 0 ? 'positiveFile' : 'signatureFile'] = [list[i]];
              } else {
                const uploadRef = uploadRefs[i];
                uploadRef.handleStart(await urlToFile(list[i]));
                uploadRef.submit();
              }
            }
          }

          const self_checkIdTypeAndSetDefault = value => {
            const currentChosenIdType = this.idTypeList.length ? Object.values(this.idTypeList).map(i => i.value) : [];
            if (!currentChosenIdType.length) return null;

            // default using idCard
            return currentChosenIdType.includes(value) ? value : this.idTypeList.find(item => item.value === 1).value;
          };

          // clean api data
          const resultData = res.data.data;
          resultData.idSerialNumber = userChoseIdSerialNumber ? userChoseIdSerialNumber : resultData.idNumber;
          resultData.idType = userChoseIdType ? userChoseIdType : resultData.idType;
          delete resultData.idNumber;
          delete resultData.poaDocFilePathList;
          delete resultData.poaAdvancedDocFilePathList;

          this.form = {
            ...this.form,
            ...resultData,
            idType: self_checkIdTypeAndSetDefault(resultData.idType)
          };
        }

        // if status is resubmit
        if (this.isResubmit) {
          const response = await apiGet_last_identifiy_proof(this.fileType, this.auditTypeMap);
          if (response.data?.code === 0 && response.data?.data) {
            const { status, id, value } = response.data.data;
            if (id) this.documentType = id;
            if (!this.isIBT) {
              this.actionChangePoiAuditStatus(getStatus(status));
            } else {
              this.actionChangeIbtPoiAuditStatus(getStatus(status));
            }
            if (value?.idType) {
              this.form.idType = value.idType;
            }
            if (value?.idNumber) {
              this.form.idSerialNumber = value.idNumber;
            }
          }
        }
      } catch (error) {
        this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
      }
    },
    getFieldErrorMsg(fields) {
      let error = null;
      switch (fields) {
        case 'countryId':
          error = 'countryReq';
          break;
        case 'streetNumber':
          error = 'numberReq';
          break;
        case 'address':
          error = 'addressReq';
          break;
        case 'state':
          error = 'stateReq';
          break;
        case 'suburb':
          error = 'suburbReq';
          break;
        case 'postcode':
          error = 'postcodeReq';
          break;
        default:
          break;
      }
      return this.$t(`common.formValidation.${error}`);
    },
    validatePositiveFile(rule, value, callback) {
      if (this.positiveFileCallbackError) {
        const { type, response } = this.positiveFileCallbackError;
        switch (type) {
          case 'oversize':
            callback(
              new Error(
                this.$t('fileUploader.maxFileSize', {
                  maxSize: response.limitSize
                })
              )
            );
            return;
          case 'error':
            callback(new Error(response));
            return;
          case 'fileTypeNotAllow':
            callback(new Error(this.$t('verificationStatus.uploadTemplate.bottomDesc')));
            return;
          default:
            break;
        }
      }

      if (!value?.length && !this.needId3Verification) {
        callback(
          new Error(
            this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.identityVerification.upload.positive')
            })
          )
        );
        return;
      }

      return callback();
    },
    validateSignatureFile(rule, value, callback) {
      if (this.signatureFileCallbackError) {
        const { type, response } = this.signatureFileCallbackError;
        switch (type) {
          case 'oversize':
            callback(
              new Error(
                this.$t('fileUploader.maxFileSize', {
                  maxSize: response.limitSize
                })
              )
            );
            return;
          case 'error':
            callback(new Error(response));
            return;
          case 'fileTypeNotAllow':
            callback(new Error(this.$t('verificationStatus.uploadTemplate.bottomDesc')));
            return;
          default:
            break;
        }
      }

      if (!value?.length && !this.needId3Verification) {
        callback(
          new Error(
            this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.identityVerification.upload.signaturePage')
            })
          )
        );
        return;
      }

      return callback();
    },
    updatePositiveFileInfo(file) {
      this.form.positiveFile = file?.fileList?.length ? [...file.fileList] : [];
      this.positiveBlob = file.blob ? file.blob : null;
      this.positiveFileCallbackError = null;
      this.$refs.form.validateField('positiveFile');
    },
    onPositiveFileErrorOccur(result) {
      this.positiveFileCallbackError = result;
      this.$refs.form.validateField('positiveFile');
    },
    updateSignatureFileInfo(file) {
      this.form.signatureFile = file?.fileList?.length ? [...file.fileList] : [];
      this.signatureBlob = file.blob ? file.blob : null;
      this.signatureFileCallbackError = null;
      this.$refs.form.validateField('signatureFile');
    },
    onSignatureFileErrorOccur(result) {
      this.signatureFileCallbackError = result;
      this.$refs.form.validateField('signatureFile');
    },
    goToStep(destinationPage = null) {
      let path = null;
      switch (destinationPage) {
        case 'register':
          path = { name: 'register' };
          break;
        case 'despoit':
          path = { name: 'depositFunds' };
          break;
        default:
          break;
      }
      if (path) this.$router.push(path);
    },
    goToDownloads() {
      this.$router.push('/downloads');
    },
    goBack() {
      this.$router.push({
        name: 'financialInfo',
        params: {
          ...this.$route.params,
          financialInfo: this.$route.params.financialInfo,
          ibtPoi: {
            idType: this.form.idType,
            idNumber: this.form.idSerialNumber,
            filePathList: [...this.form.positiveFile, ...this.form.signatureFile],
            fileType: this.fileType,
            auditType: this.auditTypeMap,
            positiveBlob: this.positiveBlob,
            signatureBlob: this.signatureBlob
          }
        }
      });
    },
    async updateIdProofStatus(config = { updateId3Status: true }) {
      const { updateId3Status } = config;
      // push progress step, but do not push while under resubmit process
      if (!this.isResubmit) this.actionStep(getNextRegisterStep(this.$route.name));

      // update id3Pass status
      if (updateId3Status) await this.actionUpdateId3Pass();

      // update status
      return this.isIBT ? await this.actionUpdateIBTStatus() : await this.actionPoiAuditStatus();
    },
    async generalPoiProcessWithId3Pass() {
      try {
        let updateData = {
          idType: this.form.idType,
          idNumber: this.form.idSerialNumber
        };
        if (this.needId3Verification) {
          updateData = {
            ...updateData,
            address: this.form.address,
            streetNumber: this.form.streetNumber,
            suburb: this.form.suburb,
            state: this.form.state,
            postcode: this.form.postcode
          };
        }

        const userIdAddressRes = await apiUpdate_user_id_address({ ...updateData });
        if (userIdAddressRes.data.code == 0) {
          if (this.needId3Verification) {
            const id3Response = await apiDoId3({
              address: this.form.address,
              streetNumber: this.form.streetNumber,
              suburb: this.form.suburb,
              state: this.form.state,
              postcode: this.form.postcode
            });
            if (id3Response.data.code === 0 && id3Response.data?.data) {
              this.doId3ButFail = false;
              this.$store.commit('common/setId3Pass', true);

              const poiAuditStatusRes = await this.updateIdProofStatus({ updateId3Status: false });
              this.openDialog(getStatus(poiAuditStatusRes) !== statusConfig.completed);
            } else {
              this.doId3ButFail = true;
              this.$store.commit('common/setId3Pass', false);
            }
          } else {
            const res = await apiProcess({
              step: this.userStep,
              ...this.form,
              idNumber: this.form.idSerialNumber,
              idDocFilePathList: [...this.form.positiveFile, ...this.form.signatureFile]
            });

            if (res.data.code == 0) {
              const poiAuditStatusRes = await this.updateIdProofStatus();
              this.openDialog(getStatus(poiAuditStatusRes) !== statusConfig.completed);
            }
          }
        }
      } catch (error) {
        this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
      }
    },
    submit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            // resubmit process
            if (this.isResubmit) {
              let res = false;
              if (this.resubmitType == statusConfig.pending) {
                res = await this.actionPendingThenReSubmit({
                  files: [...this.form.positiveFile, ...this.form.signatureFile],
                  documentId: this.documentType,
                  fileType: this.fileType
                });
              } else if (this.resubmitType == statusConfig.rejected) {
                res = await this.actionRejectThenReSubmit({
                  filePathList: [...this.form.positiveFile, ...this.form.signatureFile],
                  fileType: this.fileType,
                  auditType: this.auditTypeMap
                });
              }

              if (res) {
                const res = await this.updateIdProofStatus();
                if (!this.isIBT) {
                  this.openDialog(getStatus(res) !== statusConfig.completed);
                } else {
                  // if IBT process & do not go to IBT poa page
                  if (this.propNextStep === DEPOSIT_PATHNAME) {
                    this.openDialog(this.ibtPoiAuditStatus !== statusConfig.completed);
                  } else {
                    this.$router.push({ name: this.propNextStep, query: this.$route.query ? this.$route.query : '' });
                  }
                }
              }
              return;
            }

            // IBT process
            if (this.isIBT) {
              // if never submited IBT data
              if (this.propNextStep === REGISTERROUTER.ADDRESS_PROOF) {
                this.$router.push({
                  name: 'addressProof',
                  params: {
                    ...this.$route.params,
                    financialInfo: this.$route.params.financialInfo,
                    ibtPoi: {
                      idType: this.form.idType,
                      idNumber: this.form.idSerialNumber,
                      filePathList: [...this.form.positiveFile, ...this.form.signatureFile],
                      fileType: this.fileType,
                      auditType: this.auditTypeMap,
                      positiveBlob: this.positiveBlob,
                      signatureBlob: this.signatureBlob
                    }
                  },
                  query: this.$route.query ? this.$route.query : ''
                });
              }
              return;
            }

            // id3 check & general poi process
            await this.generalPoiProcessWithId3Pass();
          } catch (error) {
            this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
          }
        }
      });
    },
    openDialog(condition) {
      if (condition) {
        if (this.$route.query && this.$route.query.entry === 'withdraw') {
          this.$router.push({ path: '/withdrawFunds' });
          return;
        } else {
          this.resInfoType = 'pending';
          this.resDialogTitle = this.$t('register.identityVerification.generalInfo.title');
          this.resDialogContent = this.$t('register.identityVerification.generalInfo.content', {
            platform: this.$config.info.fullName
          });
        }
      }
      this.showResDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.half-row {
  width: calc(50% - 20px);
}
.upload-container {
  margin-top: 16px;
}
.upload-head {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}

.upload-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &-title {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 18px;
    color: $pug-black;
    word-break: break-word;
  }
  &-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $pug-black;
    word-break: break-word;
  }
}

.upload-guide {
  width: fit-content;
  &-text {
    font-weight: 800;
    font-size: 14px;
    line-height: 28px;
    white-space: nowrap;
  }
}

.upload-area {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  &-item {
    height: 260px !important;
  }

  /deep/ .el-form-item {
    width: 100%;
    max-width: 472px;
  }
}

.action-area {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.action-button {
  width: 160px;
  height: 56px;
}

// guide dialog
.guide-dialog {
  /deep/ .el-dialog__wrapper .el-dialog {
    max-width: 1200px;
  }
  /deep/ .dialog .el-dialog__body {
    padding: 8px 16px 0;
  }
}
.guide-title {
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: $pug-black;
  margin-bottom: 16px;
  word-break: break-word;
}
.demo-area {
  margin-bottom: 30px;
  display: flex;
}
.demo-correct {
  border-right: 2px solid $pug-gray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  .demo-item {
    padding-right: 20px;
  }
}
.demo-fail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.demo-item {
  position: relative;
  &-main {
    height: 160px;
    width: auto;
  }
  &-icon {
    width: 36px;
    height: 36px;
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.guide-list {
  margin-bottom: 30px;
  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }

  &-type {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    word-break: break-word;
  }
}

.guide-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    word-break: break-word;
  }
}

.proceed-upload-btn {
  width: 212px;
  height: 60px;
  margin-left: 16px;
  white-space: nowrap;
}

@media (max-width: 1280px) {
  .guide-dialog {
    /deep/ .el-dialog__wrapper .el-dialog {
      max-width: 680px;

      .dialog-body {
        padding: 0 35px 36px 30px;
      }
    }
  }
  .demo-area {
    flex-direction: column;
    align-items: flex-start;
  }
  .demo-correct {
    border-right: none;
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .demo-fail {
    gap: 16px;
  }
  .demo-item {
    &-main {
      height: 96px;
    }
    &-icon {
      width: 20px;
      height: 20px;
      bottom: -10px;
    }
  }

  .guide-bottom {
    flex-direction: column;
    justify-content: center;
  }
  .proceed-upload-btn {
    width: 100%;
    margin-left: 0;
    margin-top: 24px;
  }
}

@media (max-width: 1200px) {
  .upload-head {
    flex-direction: column;
    margin-bottom: 16px;
  }
  .upload-main {
    margin-bottom: 16px;
  }
}

@media (max-width: 900px) {
  .upload-main {
    margin-bottom: 0;
  }
  .upload-area {
    flex-direction: column;
    /deep/ .el-form-item {
      width: 100%;
      max-width: unset;
      &:first-of-type {
        margin-bottom: 0;
      }
    }
  }
  .confirm-btn {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .half-row {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .guide-dialog {
    /deep/ .el-dialog__wrapper .el-dialog {
      .dialog-body {
        padding: 0 16px 24px 16px;
      }
    }
  }
  .demo-fail {
    gap: 6px;
  }
  .demo-item {
    &-main {
      height: 64px;
    }
  }
}

@media (max-width: 770px) {
  .action-area {
    flex-direction: column-reverse;
    gap: 16px;
  }
  .action-button {
    width: 100%;
  }
}
</style>
