<template>
  <div :class="['info-popover-wrapper', ...calCustomClasses]">
    <el-popover
      v-bind="$attrs"
      v-on="$listeners"
      :popper-class="`info-popover-content-wrapper ${calColorType}`"
      :placement="calPlacement"
      :trigger="calTrigger"
      :width="calPopoverWidth"
    >
      <el-button slot="reference" :data-testid="calDataTestId">
        <img src="@/assets/images/label_icon_blue.png" class="label-icon" v-if="calColorType === 'default'" />
        <img src="@/assets/images/label_icon.png" class="label-icon" v-if="calColorType === 'gray'" />
      </el-button>
      <div class="popover-content">
        <slot name="popoverContent"></slot>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'InfoPopover',
  props: {
    dataTestId: {
      type: String | Number,
      default: 'button'
    },
    placement: {
      type: String,
      default: 'right-start' // top, top-start, top-end, bottom, bottom-start, bottom-end, left, left-start, left-end, right, right-start, right-end
    },
    trigger: {
      type: String,
      default: 'hover' // hover, click
    },
    colorType: {
      type: String,
      default: 'default' // default, gray
    },
    popoverWidth: {
      type: String | Number,
      default: '180'
    },
    customClasses: {
      type: Array | String,
      default: ''
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    calDataTestId() {
      return this.dataTestId;
    },
    calPlacement() {
      return this.lang === 'ar' ? this.swapRtlStyles(this.placement) : this.placement;
      return this.placement;
    },
    calTrigger() {
      return this.trigger;
    },
    calColorType() {
      return this.colorType;
    },
    calPopoverWidth() {
      return this.popoverWidth;
    },
    calCustomClasses() {
      return typeof this.customClasses === 'string' ? [this.customClasses] : [...this.customClasses];
    }
  },
  methods: {
    swapRtlStyles(inputPlacement) {
      if (this.placement.includes('left')) return this.placement.replace('left', 'right')
      if (this.placement.includes('right')) return this.placement.replace('right', 'left')
    }
  },
};
</script>

<style lang="scss" scoped>
.popover-content {
  @include rtl-sass-value(text-align, left, right);
}

.info-popover-wrapper {
  display: inline-block;
  @include rtl-sass-prop(padding-left, padding-right, 4px);

  .el-button {
    padding: 0;
    width: 18px;
    height: 18px;
    background: transparent;
  }
}
</style>
