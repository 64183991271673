<template>
  <div
    class="main-wrapper"
    v-resizeObserver="{
      openResize: true,
      handler: observerCallBack
    }"
  >
    <div>
      <div class="main-title">
        <h1 class="main-text">{{ $t('propTrade.achievementTitle') }}</h1>
      </div>
      <div class="content">
        <div class="searchbar-container">
          <div class="main-head" :class="{ mobile: mobile }">
            <h1 class="main-text">{{ $t('propTrade.certificates') }}</h1>
            <div class="filter-container">
              <Selection
                v-model="filterStatus"
                :options="filterStatusOptions"
                customClasses="filter-select"
                selectType="underline"
                filterable
                size="small"
                dataTestId="filterStatus"
              />
            </div>
          </div>
        </div>
        <div class="account-wrapper" :class="{ mobile: mobile }">
          <div v-show="!sortedData.length">{{ $t('propTrade.achievement.noData') }}</div>
          <div
            class="account-container"
            v-for="propTradingPdf in sortedData"
            :key="propTradingPdf.sessionNumber"
            v-show="sortedData.length"
          >
            <div class="content-wrapper">
              <div class="top">
                <div class="title">
                  {{ $t(`propTrade.accountStageContext.${accountStageMap[propTradingPdf.accountStage]}`) }}
                </div>
                <div class="certificate-desc">{{ $t('propTrade.achievement.certificate') }}</div>
                <div class="name">
                  <span class="first-name">{{ propTradingPdf.firstName }}</span>
                  <span class="last-name">{{ propTradingPdf.lastName }}</span>
                </div>
                <div class="description">
                  {{
                    $t('propTrade.achievement.description', {
                      stage: $t(`propTrade.accountStageContext.${accountStageMap[propTradingPdf.accountStage]}`)
                    })
                  }}
                </div>
              </div>
              <div class="bottom">
                <div class="flex-wrapper">
                  <div class="date">{{ formatDate(propTradingPdf.displayDate) }}</div>
                  <div class="session-number">{{ propTradingPdf.sessionNumber }}</div>
                </div>
                <Button @click.prevent="downloadPdf(propTradingPdf)">{{ $t('propTrade.button.downloadPdf') }}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import Selection from '@/components/common/Selection.vue';
import TabContent from '@/components/propTrading/components/dashboard/TabContent';
import AccountsTabContent from '@/components/propTrading/components/dashboard/AccountsTabContent';
import PendingOrFailedTabContent from '@/components/propTrading/components/dashboard/PendingOrFailedTabContent';
import HowWorks from '../components/HowWorks.vue';
import AccountIcon from '@/components/propTrading/components/dashboard/AccountIcon.vue';
import InfoPopover from '@/components/common/InfoPopover.vue';
import splitThousands from '@/util/splitThousands';
import { apiGetCertificationPDFList, apiDownloadCertificationPDF } from '@/resource';
import { isSuccess } from '@/util/restApi';
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';

const NEWEST_FIRST = -1;
const OLDEST_FIRST = 1;

export default {
  name: 'PropTradingDashBoard',
  components: {
    Button,
    Selection,
    TabContent,
    HowWorks,
    AccountIcon,
    InfoPopover,
    AccountsTabContent,
    PendingOrFailedTabContent
  },
  data() {
    return {
      data: [],
      filterStatus: NEWEST_FIRST,
      mainWrapperWidth: 0,
      activeTab: 'accounts',
      selecedAccount: '',
      mobileShowDetail: false
    };
  },
  computed: {
    ...mapGetters('propTrading', ['accountStatusMap', 'accountStageMap', 'stageMap']),
    mobile() {
      return this.mainWrapperWidth <= 700;
    },
    filterStatusOptions() {
      return [
        {
          label: this.$t(`propTrade.selection.newest`),
          value: -1
        },
        {
          label: this.$t(`propTrade.selection.oldest`),
          value: 1
        }
      ];
    },
    sortedData() {
      const data = [...this.data];
      if (data.length) {
        data.sort((a, b) => {
          if (this.filterStatus === NEWEST_FIRST) {
            return moment(b.displayDate, 'DD-MM-YYYY').diff(moment(a.displayDate, 'DD-MM-YYYY'));
          } else {
            return moment(a.displayDate, 'DD-MM-YYYY').diff(moment(b.displayDate, 'DD-MM-YYYY'));
          }
        });
      }

      return data;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async downloadPdf({ sessionNumber, accountStage }) {
      const response = await apiDownloadCertificationPDF(sessionNumber, accountStage);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${sessionNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
    },
    formatDate(dateString) {
      const date = moment(dateString, 'DD-MM-YYYY');
      return date.format('DD MMMM YYYY');
    },
    async init() {
      const result = await apiGetCertificationPDFList();

      if (isSuccess(result.data)) {
        this.data = result.data.data;
      }
    },
    observerCallBack(width) {
      this.mainWrapperWidth = width;
    },
    formateAmount(amount) {
      return splitThousands(amount, 2);
    }
  }
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding: 48px 64px;
  max-width: 1440px;
  margin: auto;
}

.main-title {
  margin-bottom: 24px;
}
.main-text {
  font-weight: 900;
  font-size: 22px;
  line-height: 34px;
}
.main-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  margin-top: 10px;
}

.card-panel {
  padding: 30px 24px;
  margin-bottom: 16px;
  background-color: $pug-white;
  border-radius: 10px;
  &.mobile {
    padding: 30px 16px;
  }

  &-main {
    font-weight: 900;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  &-desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
  }
}

.top {
  margin-bottom: 16px;
}

/deep/ .dashboard-tabs {
  .el-tabs__item {
    font-weight: 900;
    font-size: 18px;
    color: $pug-gray;
    &.is-active {
      color: $pug-blue-hover;
    }
  }
  .el-tabs__active-bar {
    height: 4px;
    border-radius: 2px;
    background-color: $pug-blue-hover;
  }

  &.single-tab {
    .el-tabs__item {
      color: $pug-black;
      &.is-active {
        color: $pug-black;
      }
    }
    .el-tabs__active-bar {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .main-wrapper {
    padding: 48px 36px;
  }
}

@media (max-width: 764px) {
  .main-wrapper {
    padding: 40px 16px 30px 16px;
  }
}

.content {
  background-color: $pug-white;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.searchbar-container {
  margin-bottom: 16px;
}

.main-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $pug-white;
  padding: 20px 18px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .filter-select {
    width: 140px;
  }
}

.main-head.mobile {
  flex-direction: column;
  gap: 30px;
  padding: 16px;
  padding-top: 7px;

  .filter-container {
    width: 100%;
  }

  .filter-select {
    width: 100%;
  }
}

.account-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  .account-container {
    flex: 0 0 47%;
    min-height: 280px;
    .content-wrapper {
      border-radius: 10px;
      overflow: hidden;
      border: solid 1px #eaecf0;
    }
    .top {
      padding: 22px 24px;

      color: $pug-white;
      background-color: $pug-black;
      .title {
        font-size: 24px;
        color: $pug-white;
      }
      .description {
        font-size: 14px;
        font-weight: 400;
        color: $pug-white;
      }
      .certificate-desc {
        margin-top: 2.4rem;
        color: #76afff;
        font-size: 12px;
        font-weight: 400;
      }
      .name {
        font-family: 'sans-serif';
        display: flex;
        flex-direction: column;
        color: #fff;
        font-size: 20px;
        font-weight: 900;
        margin-top: 0.4rem;
        margin-bottom: 1.2rem;
      }
    }
    .bottom {
      padding: 22px 24px;
      padding-top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /deep/.button-wrapper {
        width: fit-content;
      }
      /deep/.el-button {
        margin-left: auto;
        flex: 0 0 fit-content;
        width: fit-content;
      }
      .date,
      .session-number {
        font-family: 'sans-serif';
        color: #667085;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
}

.account-wrapper.mobile {
  flex-direction: column;

  .account-container {
    flex: 0 0 100%;
    .top {
      padding: 16px;
    }
    .bottom {
      padding: 16px;

      .date,
      .session-number {
        font-size: 14px;
      }

      /deep/.el-button {
        padding: 7px 6px;
        font-size: 16px;
      }
    }
  }
}
</style>
