<template>
  <div class="card">
    <h2>{{ $t('menu.payDetails') }}</h2>
    <div class="desc">
      {{ $t('deposit.crypto.remark') }}
    </div>
    <CryptoQrCode
      :name="name"
      :cryptoAddress="cryptoAddress"
      :actualAmount="actualAmount"
      :isPropTrading="true"
    ></CryptoQrCode>
  </div>
</template>

<script>
import CryptoQrCode from '@/components/deposit/QrCode/CryptoQrCode.vue';

export default {
  name: 'SubscriptionCryptoQrCode',
  components: {
    CryptoQrCode
  },
  props: {
    additionalData: { type: Object, default: () => {} }
  },
  data() {
    return {
      name: '',
      cryptoAddress: '',
      actualAmount: '0'
    };
  },
  mounted() {
    this.name = this.additionalData.actualCurrencyCode;
    this.cryptoAddress = this.additionalData.cryptoAddress;
    this.actualAmount = this.additionalData.actualAmount;
  }
};
</script>
