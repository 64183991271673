<template>
  <div
    :class="['step-banner-wrapper']"
    v-resizeObserver="{
      openResize: true,
      handler: observerCallBack
    }"
  >
    <div class="pc-container" v-if="!(currentWidth < calMobileWidth)">
      <div class="pc-container-item" v-for="(item, index) in calData" :key="index">
        <slot :item="Object.assign(item, { index: index + 1 })"></slot>
      </div>
    </div>
    <div class="mobile-container" v-else>
      <el-carousel :interval="5500" trigger="click">
        <el-carousel-item v-for="(item, index) in calData" :key="index">
          <slot :item="Object.assign(item, { index: index + 1 })"></slot>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepBanner',
  props: {
    data: {
      type: Array,
      default: []
    },
    mobileWidth: {
      type: String | Number,
      default: 768
    }
  },
  data() {
    return {
      currentWidth: 0
    };
  },
  computed: {
    calData() {
      return this.data;
    },
    calMobileWidth() {
      return Number(this.mobileWidth);
    }
  },
  methods: {
    observerCallBack(width) {
      this.currentWidth = width;
      this.$emit('changeStepBannerWidth', this.currentWidth, this.currentWidth < this.calMobileWidth);
    }
  }
};
</script>

<style lang="scss" scoped>
.step-banner-wrapper {
  .pc-container {
    display: flex;
    justify-content: space-between;
    &-item {
      flex: 1;
    }
  }
}

/deep/.el-carousel__arrow--left {
  left: -14px;
}
/deep/.el-carousel__arrow--right {
  right: -14px;
}


[dir="rtl"] * /deep/.el-icon-arrow-left {
  transform: rotate(360deg);
}
[dir="rtl"] * /deep/.el-icon-arrow-right {
  transform: rotate(360deg);
}
</style>
