<template>
  <div
    class="main-wrapper"
    v-resizeObserver="{
      openResize: true,
      handler: observerCallBack
    }"
  >
    <div class="back-icon" @click="backToDashboard">
      <i class="el-icon-back"></i>
      <div>{{ $t('propTrade.button.back') }}</div>
    </div>
    <div>
      <div class="main-title">
        <h1 class="main-text">{{ $t('propTrade.metricsPage.mainTitle') }}</h1>
        <h2 class="main-desc">{{ $t('propTrade.metricsPage.mainDesc') }}</h2>
      </div>

      <div class="card-panel" :class="{ mobile: topBannerMobileMode }">
        <div class="top-banner-head" :class="{ mobile: topBannerMobileMode }">
          <AccountIcon
            :accountStage="data.accountStage"
            :accountStatus="data.status"
            customClasses="top-banner-head-icon"
            :height="24"
          />
          <div v-if="topBannerMobileMode" class="account-number">{{ $t('propTrade.metricsPage.accountNo') }}:</div>
          <Selection
            v-model="selectedAccount"
            :options="selectedAccountOptions"
            filterable
            :dataTestId="`selectedAccount`"
            size="small"
            customClasses="top-banner-head-selection"
          />
        </div>
        <div class="top-banner-body" :class="{ mobile: topBannerMobileMode }">
          <div class="top-banner-body-left">
            <div class="equity-content">
              <div class="content-text">{{ $t('propTrade.metricsPage.equity') }}</div>
              <InfoPopover dataTestId="equityInfo" :trigger="'hover'">
                <template #popoverContent>
                  <div>{{ $t('propTrade.metricsPage.infoContext') }}</div>
                </template>
              </InfoPopover>
              <div class="profit" :class="{ minus: data.profitRatio < 0 }">{{ data.profitRatio }}%</div>
            </div>
            <div class="account-text-main">
              <span class="amount">{{ data.currency | currencyToSymbol }}{{ formateAmount(data.equity) }}</span>
              <span class="currency">{{ data.currency }}</span>
            </div>
            <div class="init-balance">
              <span>{{ $t('propTrade.metricsPage.initBalance') }}</span>
              <span>${{ formateAmount(data.initialBalance, 0) }}</span>
            </div>
            <div class="content-text" v-if="isEndDateVisible">
              <div v-if="isLiveAccount && data.payout">
                <span>{{ $t('propTrade.button.payout') }} : </span>
                <span>{{ data.statusDate }}</span>
              </div>
              <div v-else>
                <span>{{ $t('propTrade.metricsPage.endDate') }} : </span>
                <span>{{ data.statusDate }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="topBannerMobileMode"
            class="view-button"
            :class="{ show: mobileShowDetail, hide: !mobileShowDetail }"
            @click="mobileShowDetail = !mobileShowDetail"
          >
            <span>{{ $t('propTrade.button.view') }}</span>
            <i class="el-icon-arrow-right view-right-icon"></i>
          </div>
          <div class="top-banner-body-right" v-show="isShowDetail">
            <div class="info-item content-text emphasis">
              <div class="info-item-column">{{ $t(`propTrade.metricsPage.topInfoField.leverage`) }} :</div>
              <div class="info-item-context">{{ data.leverage }}:1</div>
            </div>
            <div class="info-item content-text emphasis">
              <div class="info-item-column">{{ $t(`propTrade.metricsPage.topInfoField.status`) }} :</div>
              <div class="info-item-context">{{ $t(ACCOUNT_STATUS_REV[data.status]) }}</div>
            </div>
            <div v-if="data?.server" class="info-item content-text emphasis">
              <div class="info-item-column">{{ $t(`propTrade.metricsPage.topInfoField.server`) }} :</div>
              <div class="info-item-context">{{ data.server }}</div>
            </div>
            <div class="info-item content-text emphasis">
              <div class="info-item-column">{{ $t(`propTrade.metricsPage.topInfoField.accountType`) }} :</div>
              <div class="info-item-context">
                {{ $t(`propTrade.accountType.${ACCOUNT_TYPE_REV[data.type]}`) }}
              </div>
            </div>
            <div class="info-item content-text emphasis">
              <div class="info-item-column">{{ $t(`propTrade.metricsPage.topInfoField.platform`) }} :</div>
              <div class="info-item-context">
                {{ data?.platform }}
                <!-- <span class="download-button" @click="backToDownloads">{{ $t('propTrade.metricsPage.download') }}</span> -->
              </div>
            </div>
            <div class="info-item content-text emphasis">
              <div class="info-item-column">{{ $t(`propTrade.metricsPage.topInfoField.startDate`) }} :</div>
              <div class="info-item-context">{{ data.startDate }}</div>
            </div>
          </div>
        </div>

        <MentionMsg
          :isMobile="topBannerMobileMode"
          :alertType="data.metricsStatus"
          :accountNumber="data.accountNumber"
        ></MentionMsg>
      </div>

      <div class="card-panel" :class="{ mobile: topBannerMobileMode }">
        <div class="card-panel-main">{{ $t('propTrade.metricsPage.chart.title') }}</div>
        <!-- <div class="card-panel-desc">{{ $t('propTrade.metricsPage.tradingGoalsDesc') }}</div> -->
        <Chart :data="chartData" />
      </div>

      <div class="card-panel" :class="{ mobile: topBannerMobileMode }">
        <div class="card-panel-main">{{ $t('propTrade.metricsPage.tradingGoals') }}</div>
        <div class="card-panel-desc">{{ $t('propTrade.metricsPage.tradingGoalsDesc') }}</div>

        <div class="trading-goal">
          <el-table :data="tableData" style="width: 100%" class="test">
            <el-table-column prop="goals" :label="$t('propTrade.metricsPage.goals')">
              <template slot-scope="scope">
                <div class="goal">{{ scope.row.goals }}</div>
                <div class="amount" :class="{ minus: scope.row.minusStatus }">{{ scope.row.amount }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              :label="$t('propTrade.metricsPage.topInfoField.status')"
              :width="tableStatusFieldWidth"
            >
              <template slot-scope="scope">
                <img
                  v-if="scope.row.status"
                  :src="require(`@/assets/images/propTrading/check.png`)"
                  class="status-icon"
                  alt="completed"
                />
                <!-- <img
                  v-else
                  :src="require(`@/assets/images/propTrading/uncheck.png`)"
                  class="status-icon"
                  alt="not-completed"
                /> -->
                <img
                  v-else
                  :src="require(`@/assets/images/propTrading/fail.png`)"
                  class="status-icon"
                  alt="not-completed"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/common/Button.vue';
import Selection from '@/components/common/Selection.vue';
import TabContent from '@/components/propTrading/components/dashboard/TabContent';
import HowWorks from '../components/HowWorks.vue';
import AccountIcon from '@/components/propTrading/components/dashboard/AccountIcon.vue';
import InfoPopover from '@/components/common/InfoPopover.vue';
import MentionMsg from '@/components/propTrading/components/metrics/MentionMsg.vue';
import splitThousands from '@/util/splitThousands';
import { apiGetAccountMetrics, apiUpdateAccountIsNew, apiGetAccountGraph } from '@/resource';
import setIntervalFn from '@/util/setIntervalFn';
import {
  DEFAULT_TAB_CONTENT_REFRESH_INTERVAL,
  ACCOUNT_STATUS_REV,
  ACCOUNT_TYPE_REV,
  ACCOUNT_STAGE,
  ACCOUNT_STATUS
} from '@/constants/propTrading';
import formatPercentage from '@/util/formatPercentage';
import Chart from '@/components/propTrading/components/metrics/Chart.vue';

export default {
  name: 'PropTradingMetrics',
  components: { Button, Selection, TabContent, HowWorks, AccountIcon, InfoPopover, MentionMsg, Chart },
  data() {
    return {
      ACCOUNT_TYPE_REV,
      ACCOUNT_STATUS_REV,
      ACCOUNT_STATUS,
      mainWrapperWidth: 0,
      activeTab: 'accounts',
      selectedAccount: '',
      mobileShowDetail: false,
      data: {},
      autoRefetchAPI: null,
      restartAutoRefetchAPI: null,
      refetchStopFn: null,
      chartData: {},
      optionSeparator: ' - '
    };
  },
  computed: {
    ...mapGetters('propTrading', ['accountStatusMap', 'accountStageMap', 'stageMap']),
    routerAccountNumber() {
      return this.$route.params.accountNumber;
    },
    selectedAccountOptions() {
      const firstAccount = {
        label: `${this.combineAccountOption({ ...this.data })}`,
        value: `${this.combineAccountOption({ ...this.data }, 'value')}`,
        accountNumber: `${this.data?.accountNumber}`
      };
      if (this.data?.accountList && Array.isArray(this.data?.accountList)) {
        const result = this.data.accountList.map(item => ({
          label: `${this.combineAccountOption({ ...item })}`,
          value: `${this.combineAccountOption({ ...item }, 'value')}`,
          accountNumber: `${item.accountNumber}`
        }));

        result.unshift(firstAccount);

        return result;
      }
      return [firstAccount];
    },
    tableData() {
      let result = [
        this.isLiveAccount
          ? null
          : {
              goals: this.$t('propTrade.metricsPage.tradingGoalsFiels.minTradingDay', {
                minTradingDay: this.data.minTradingDay
              }),
              amount: '',
              status: !!this.data.tradingDayStatus
            },
        {
          goals: this.$t('propTrade.metricsPage.tradingGoalsFiels.maxDailyLoss', {
            maxDailyLoss: `${this.data.maxDailyLoss}%`
          }),
          amount: `$${this.data.maxDailyLossN} (${this.data.maxDailyLossRatio}%)`,
          minusStatus: this.data.maxDailyLossN < 0,
          status: !!this.data.dailyLossStatus
        },
        {
          goals: this.$t('propTrade.metricsPage.tradingGoalsFiels.maxLoss', {
            maxTotalLoss: this.data.maxTotalLoss + '%'
          }),
          amount: `$${this.data.profit} (${this.data.profitRatio}%)`,
          minusStatus: this.data.profit < 0,
          status: !!this.data.totalLossStatus
        },
        this.isLiveAccount
          ? null
          : {
              goals: this.$t('propTrade.metricsPage.tradingGoalsFiels.profitTarget', {
                profitTarget: this.data.profitTarget + '%'
              }),
              amount: `$${this.data.profit} (${this.data.profitRatio}%)`,
              minusStatus: this.data.profit < 0,
              status: !!this.data.profitTargetStatus
            }
      ];

      return result.filter(Boolean);
    },
    topBannerMobileMode() {
      return this.mainWrapperWidth <= 730;
    },
    isShowDetail() {
      if (!this.topBannerMobileMode) return true;
      return this.mobileShowDetail;
    },
    tableStatusFieldWidth() {
      if (this.topBannerMobileMode) return 80;
      return this.mainWrapperWidth >= 900 ? 160 : 120;
    },
    isLiveAccount() {
      return this.data?.accountStage > ACCOUNT_STAGE.EVALUATION_PLUS;
    },
    isEndDateVisible() {
      if (this.isLiveAccount) {
        return this.data.payout || this.data.status === ACCOUNT_STATUS.FAILED;
      }

      const isEligibleAccountStatus = [
        ACCOUNT_STATUS.ACTIVE,
        ACCOUNT_STATUS.FAILED,
        ACCOUNT_STATUS.PASSED,
        ACCOUNT_STATUS.VERIFYING
      ].includes(this.data.status);

      return isEligibleAccountStatus ? this.data.payout || this.data.statusDate : false;
    }
  },
  watch: {
    selectedAccount: {
      async handler(newValue, oldValue) {
        if (oldValue !== newValue && !!oldValue) {
          this.selectedAccountChangeHandler(this.routerAccountNumber);
        }

        const id = this.selectedAccountOptions.filter(item => item.value === newValue)[0]?.accountNumber;
        await this.$router
          .push({
            name: 'propTradingMetrics',
            params: { accountNumber: encodeURIComponent(id) }
          })
          .catch(() => {});
      }
    },
    selectedAccountOptions: {
      handler(newValue) {
        if (newValue?.length && newValue[0]?.value !== this.selectedAccount) {
          this.selectedAccount = newValue[0].value;
        }
      }
    }
  },
  mounted() {
    this.init();

    const { intervalFn, stop, restart } = setIntervalFn(async () => {
      await this.selectedAccountChangeHandler();
    }, DEFAULT_TAB_CONTENT_REFRESH_INTERVAL);

    this.autoRefetchAPI = intervalFn;
    this.refetchStopFn = stop;
    this.restartAutoRefetchAPI = restart;

    this.autoRefetchAPI();
  },
  beforeDestroy() {
    this.refetchStopFn?.();
  },
  methods: {
    async init() {
      if (!this.routerAccountNumber) {
        this.backToDashboard();
      }

      await this.selectedAccountChangeHandler(this.routerAccountNumber);
    },
    observerCallBack(width) {
      this.mainWrapperWidth = width;
    },
    formateAmount(amount) {
      return splitThousands(amount, 2);
    },
    async getAccountMetrics(accountNumber) {
      const result = await apiGetAccountMetrics(accountNumber);

      if (result.data.code === 0) {
        return result.data.data;
      }

      return null;
    },
    async backToDashboard() {
      await this.$router.push({ name: 'propTradingDashboard' }).catch(() => {});
    },
    async backToDownloads() {
      await this.$router.push({ name: 'downloads' }).catch(() => {});
    },
    async selectedAccountChangeHandler(accountNumber) {
      if (!accountNumber) {
        const selectedAccountNumber = this.data?.accountList.find(
          item => `${this.combineAccountOption({ ...item }, 'value')}` === this.selectedAccount
        )?.accountNumber;

        accountNumber = encodeURIComponent(selectedAccountNumber);
      }

      const result = await this.getAccountMetrics(accountNumber);

      if (!result) {
        this.backToDashboard();
      }

      this.data = result;

      if (!this.data?.isNew) apiUpdateAccountIsNew({ accountNumber });

      // get chart data
      const chartResp = await apiGetAccountGraph(accountNumber);
      if (chartResp?.data?.code === 0 && chartResp?.data?.data) {
        this.chartData = chartResp?.data?.data;
      }
    },
    combineAccountOption({ sessionNumber, accountStage, currency }, type = 'label') {
      const stageText =
        typeof accountStage === 'number' && ~accountStage
          ? this.$t(`propTrade.accountStageContext.${this.accountStageMap[accountStage]}`)
          : '';
      let strs = type === 'label' ? [sessionNumber, stageText, currency] : [sessionNumber, stageText];

      return strs.filter(Boolean).join(this.optionSeparator);
    }
  }
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  // padding: 76px 64px;
  padding: 24px 64px 36px 64px;
  max-width: 1440px;
  margin: auto;
}

.back-icon {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 16px;
  gap: 12px;
  cursor: pointer;
}

.main-title {
  margin-bottom: 24px;
}
.main-text {
  font-weight: 900;
  font-size: 22px;
  line-height: 34px;
}
.main-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  margin-top: 10px;
}

.card-panel {
  padding: 30px 24px;
  margin-bottom: 16px;
  background-color: $pug-white;
  border-radius: 10px;
  &.mobile {
    padding: 30px 16px;
  }

  &-main {
    font-weight: 900;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  &-desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
  }
}

.top-banner-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  &-icon {
    margin-right: 0;
  }
  &-selection {
    min-width: 320px;
  }
  .account-number {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 20px;
    margin-bottom: 4px;
    text-align: left;
  }

  &.mobile {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .top-banner-head-selection {
      width: 100%;
    }
  }
}

.top-banner-body {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content-text {
    font-size: 14px;
    font-weight: 400;
    &.emphasis {
      font-weight: 500;
    }
  }

  .view-button {
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 24px auto;
    color: $pug-blue;
    cursor: pointer;
    .view-right-icon {
      margin-left: 8px;
    }

    &.show {
      .view-right-icon {
        transform: rotate(90deg);
        transition: transform linear 0.15s;
      }
    }
    &.hide {
      .view-right-icon {
        transform: rotate(0deg);
        transition: transform linear 0.15s;
      }
    }
  }

  &-left {
    flex: 1 1 36%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &-right {
    flex: 1 1 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 24px 14px;
    // background-color: $pug-gray-opacity-40;
    background-color: $pug-blue-upload-bg;
    border-radius: 20px;
    gap: 12px;

    .info-item {
      flex: 0 0 48%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      &-column {
        color: $pug-gray-dot;
        font-size: 16px;
      }
      &-context {
        color: $pug-black;
        font-weight: 600;
        font-size: 14px;
      }
    }

    .download-button {
      color: $pug-blue;
      font-weight: 400;
      font-size: 12px;
      text-decoration: underline;
      margin-left: 2px;
      cursor: pointer;
    }
  }

  &.mobile {
    flex-direction: column;
    align-items: flex-start;
    .top-banner-body-left {
      flex: 1 1 100%;
      margin-bottom: 16px;
    }
    .top-banner-body-right {
      flex: 1 1 100%;
      .info-item {
        flex: 0 0 100%;
        padding: 0px;
      }
    }
  }

  .equity-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-bottom: 18px;
  }

  .profit {
    color: $pug-profit-green;
    height: 18px;
    &.minus {
      color: $pug-red;
    }
  }

  .account-text-main {
    margin-bottom: 4px;
    display: flex;
    align-items: flex-end;
  }
  .amount {
    margin-right: 12px;
    font-weight: 900;
    font-size: 22px;
    line-height: 1.2;
    color: $pug-black;
  }
  .currency {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: $pug-black;
  }
  .init-balance {
    font-weight: 400;
    font-size: 14px;
    color: $pug-gray;
    margin-bottom: 24px;
  }
}

/deep/ .trading-goal {
  padding: 14px;
  margin-top: 8px;
  .el-table {
    .el-table__header-wrapper {
      th {
        background-color: $pug-white;
        height: 28px;
        color: $pug-black;
        .cell {
          padding: 0 36px;
          text-align: start;
          font-weight: 800;
          font-size: 16px;
        }
        &:nth-child(2) {
          .cell {
            text-align: center;
            padding: 0px;
          }
        }
      }
    }
    .el-table__body-wrapper {
      .el-table__row {
        &:nth-child(2n + 1) {
          background-color: $pug-blue-upload-bg !important;
        }
        &:nth-last-child(1) {
          td {
            border-bottom: 1px solid rgba(99, 110, 114, 0.1);
          }
        }

        td {
          border-bottom: none;
          min-height: 48px;
          height: auto;
          .cell {
            font-weight: 500;
            font-size: 16px;
            line-height: 1.5;
            text-align: left;
            padding: 12px 36px;
            color: $pug-black;
            .amount {
              font-weight: 400;
              font-size: 14px;
              color: $pug-profit-green;
              &.minus {
                color: $pug-red;
              }
            }
          }
          &:nth-child(2) {
            .cell {
              text-align: center;
              padding: 0px;
            }
          }
        }
      }
    }
  }
  .status-icon {
    width: 22px;
    height: 22px;
  }
}

@media (max-width: 1024px) {
  .main-wrapper {
    padding: 48px 36px;
  }
}

@media (max-width: 764px) {
  .main-wrapper {
    padding: 40px 16px 30px 16px;
  }

  /deep/ .trading-goal {
    padding: 0;
    .el-table {
      .el-table__header-wrapper {
        th {
          .cell {
            padding: 0 8px;
          }
          // &:nth-child(2) {
          //   .cell {
          //     text-align: center;
          //   }
          // }
        }
      }
      .el-table__body-wrapper {
        .el-table__row {
          td {
            .cell {
              padding: 12px 8px;
            }

            // &:nth-child(2) {
            //   .cell {
            //     text-align: center;
            //   }
            // }
          }
        }
      }
    }
  }
}
</style>
