<template>
  <el-form :model="form" :rules="formRules" ref="form" class="body-box">
    <div class="form-box">
      <el-row :gutter="40">
        <el-col :sm="12" :xs="24">
          <el-form-item prop="nationalityId">
            <Selection
              :title="$t('register.poaVerification.nationality')"
              v-model="form.nationalityId"
              :placeholder="$t('common.formValidation.answerReq')"
              :options="nationalities"
              filterable
              disabled
              selectType="underline"
              dataTestId="nationalityId"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item prop="countryId">
            <Selection
              :title="$t('register.poaVerification.country')"
              v-model="form.countryId"
              :placeholder="$t('common.formValidation.answerReq')"
              :options="countries"
              filterable
              disabled
              selectType="underline"
              dataTestId="countryId"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item prop="address">
            <TextInput
              type="text"
              id="address"
              :title="$t('register.poaVerification.address')"
              v-model="form.address"
              :placeholder="$t('common.formValidation.sameAsPOA')"
              dataTestId="address"
              :disabled="isResubmit"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item prop="postcode">
            <TextInput
              type="text"
              id="postcode"
              :title="$t('register.poaVerification.postalCode')"
              v-model="form.postcode"
              :placeholder="$t('common.formValidation.sameAsPOA')"
              dataTestId="postcode"
              :disabled="isResubmit"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item prop="suburb">
            <TextInput
              type="text"
              id="suburb"
              :title="$t('register.poaVerification.citySuburb')"
              v-model="form.suburb"
              :placeholder="$t('common.formValidation.sameAsPOA')"
              dataTestId="suburb"
              :disabled="isResubmit"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item prop="state">
            <TextInput
              type="text"
              id="state"
              :title="$t('register.poaVerification.provinceState')"
              v-model="form.state"
              :placeholder="$t('common.formValidation.sameAsPOA')"
              dataTestId="state"
              :disabled="isResubmit"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <div class="upload-container">
      <div class="upload-head">
        <div class="upload-main">
          <h4 class="upload-main-title">
            {{
              isIBT
                ? $t('register.financialVerification.addressProof.upload.title')
                : $t('register.poaVerification.upload.title')
            }}
          </h4>
          <ul>
            <li>{{ $t('register.poaVerification.upload.inst1') }}</li>
            <li>{{ $t('register.poaVerification.upload.inst2') }}</li>
          </ul>
          <i18n path="register.poaVerification.upload.desc" tag="p">
            <template v-slot:months>
              <b>{{ isIBT ? 3 : 6 }}</b>
            </template>
          </i18n>
        </div>

        <Button type="text" customClasses="upload-guide" @click="showGuide = true">
          <div class="upload-guide-text">
            {{ $t('register.poaVerification.upload.guideline') }}
          </div>
        </Button>
      </div>
      <div class="upload-area">
        <el-form-item prop="uploadFilesPhoto">
          <PugUpload
            ref="POAUpload"
            customClasseses="upload-area-item"
            @updateFileInfo="updateUploadFilesPhotoInfo"
            @onErrorOccur="onUploadFilesPhotoErrorOccur"
            dataTestId="uploadFilesPhoto"
            :files="existingFilesPhoto"
          >
            <template #uploadBottomContent>
              {{ $t('register.poaVerification.upload.uploadFilesPhoto') }}
            </template>
          </PugUpload>
        </el-form-item>
      </div>
    </div>
    <div class="action-area">
      <Button
        v-if="propPreStep"
        buttonType="default"
        customClasses="action-button"
        outline
        dataTestId="back"
        @click="goBack"
      >
        <div>{{ $t('register.btn.back') }}</div>
      </Button>
      <Button buttonType="default" customClasses="action-button" dataTestId="confirm" @click="submit('form')">
        <div>{{ $t('common.button.confirm') }}</div>
      </Button>
    </div>

    <GeneralDialog :visible.sync="showGuide" class="guide-dialog" visibleFooter needFullscreen hideChat>
      <div class="guide-title">{{ $t('verificationStatus.uploadTemplate.uploadGuideTitle') }}</div>
      <div class="demo-area">
        <div class="demo-correct">
          <div class="demo-item">
            <img class="demo-item-main" src="@/assets/images/register/guide/fileGood.png" alt="" />
            <img class="demo-item-icon" src="@/assets/images/register/guide/correct.png" alt="" />
          </div>
        </div>
        <div class="demo-fail">
          <div class="demo-item">
            <img class="demo-item-main" src="@/assets/images/register/guide/fileBlurry.png" alt="" />
            <img class="demo-item-icon" src="@/assets/images/register/guide/fail.png" alt="" />
          </div>
          <div class="demo-item">
            <img class="demo-item-main" src="@/assets/images/register/guide/fileEdgeMissing.png" alt="" />
            <img class="demo-item-icon" src="@/assets/images/register/guide/fail.png" alt="" />
          </div>
          <div class="demo-item">
            <img class="demo-item-main" src="@/assets/images/register/guide/fileReflective.png" alt="" />
            <img class="demo-item-icon" src="@/assets/images/register/guide/fail.png" alt="" />
          </div>
        </div>
      </div>

      <div class="guide-list">
        <div v-for="(item, index) in guideList" :key="index" class="guide-list-item">
          <img class="guide-list-type" :src="require(`@/assets/images/register/guide/${item.type}.png`)" alt="" />
          <p class="guide-list-desc">{{ item.content }}</p>
        </div>
      </div>

      <div class="guide-bottom">
        <div class="guide-bottom-text">{{ $t('verificationStatus.uploadTemplate.bottomDesc') }}</div>
        <Button
          buttonType="default"
          :customClasses="['proceed-upload-btn']"
          dataTestId="proceedToUpload"
          @click="showGuide = false"
        >
          {{ $t('register.btn.proceedToUpload') }}
        </Button>
      </div>
    </GeneralDialog>

    <InfoDialog
      :visible.sync="showResDialog"
      :title="resDialogTitle"
      :infoType="resInfoType"
      @closCbDialog="closeRegisterDialog"
    >
      <div>{{ resDialogContent }}</div>

      <template #actions>
        <Button buttonType="default" v-if="propNextStep" @click="goToNextStep" dataTestId="depositViaOther">
          {{ $t('register.btn.depositViaOther') }}
        </Button>
        <Button buttonType="default" v-else @click="goToDestination('register')" dataTestId="ok">
          {{ $t('common.button.ok') }}
        </Button>
      </template>
    </InfoDialog>
  </el-form>
</template>

<script>
import InfoDialog from '@/components/dialog/InfoDialog.vue';
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import Button from '@/components/common/Button.vue';
import TextInput from '@/components/common/TextInput.vue';
import Selection from '@/components/common/Selection.vue';
import PugUpload from '@/components/common/Upload.vue';
import { statusConfig } from '@/constants/status';
import { getUserStep, getNextRegisterStep, getStatus } from '@/util/register';
import { apiGetData, apiSubmitIbtQuestionnaire } from '@/resource/register';
import { apiUpdate_user_id_address, apiVerification2IdentityProofs, apiGet_last_identifiy_proof } from '@/resource';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { REGISTERROUTER } from '@/constants/route';
import { urlToFile } from '@/util/urlToFile';

export default {
  name: 'poaVerification',
  components: { InfoDialog, GeneralDialog, Button, TextInput, Selection, PugUpload },
  props: {
    nextStep: {
      type: String,
      default: null
    },
    preStep: {
      type: String,
      default: null
    },
    isIBT: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFromWithdraw: false,
      fileType: 11, // IDProof: 12, AddressProof: 11
      documentType: null,
      existingFilesPhoto: [],
      form: {
        nationalityId: '',
        countryId: '',
        address: '',
        postcode: '',
        suburb: '',
        state: '',
        uploadFilesPhoto: []
      },
      formRules: {
        nationalityId: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error(this.getFieldErrorMsg('nationalityId')));
              } else {
                return callback();
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        countryId: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error(this.getFieldErrorMsg('countryId')));
              } else {
                return callback();
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        address: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error(this.getFieldErrorMsg('address')));
              } else {
                return callback();
              }
            },
            trigger: 'blur'
          }
        ],
        postcode: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error(this.getFieldErrorMsg('postcode')));
              } else {
                return callback();
              }
            },
            trigger: 'blur'
          }
        ],
        suburb: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error(this.getFieldErrorMsg('suburb')));
              } else {
                return callback();
              }
            },
            trigger: 'blur'
          }
        ],
        state: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error(this.getFieldErrorMsg('state')));
              } else {
                return callback();
              }
            },
            trigger: 'blur'
          }
        ],
        uploadFilesPhoto: [
          {
            validator: this.validateUploadFilesPhoto
          }
        ]
      },
      uploadFilesPhotoCallbackError: null,
      showGuide: false,
      showResDialog: false,
      resInfoType: 'success',
      resDialogTitle: this.$t('register.poaVerification.successInfo.title'),
      resDialogContent: this.$t('register.poaVerification.successInfo.content'),
      registerUrl: REGISTERROUTER.REGISTER,
      poaBlob: null
    };
  },
  computed: {
    ...mapState('register', ['ibtPoiAuditStatus', 'ibtPoaAuditStatus', 'poaAuditStatus']),
    ...mapGetters('register', ['nationalities', 'countries']),
    ...mapState('common', ['id3Pass']),
    auditTypeMap() {
      // general poi process: 1, IBT process: 2
      return this.isIBT ? 2 : 1;
    },
    isResubmit() {
      return this.isIBT
        ? this.ibtPoaAuditStatus === statusConfig.pending || this.ibtPoaAuditStatus === statusConfig.rejected
        : this.poaAuditStatus === statusConfig.pending || this.poaAuditStatus === statusConfig.rejected;
    },
    resubmitType() {
      if (this.isResubmit) {
        return this.isIBT ? getStatus(this.ibtPoaAuditStatus) : getStatus(this.poaAuditStatus);
      }
      return null;
    },
    guideList() {
      return [
        {
          type: 'correct',
          content: this.$t('verificationStatus.uploadTemplate.desc1')
        },
        {
          type: 'correct',
          content: this.$t('verificationStatus.uploadTemplate.desc2')
        },
        {
          type: 'correct',
          content: this.$t('verificationStatus.uploadTemplate.desc3')
        },
        {
          type: 'correct',
          content: this.$t('verificationStatus.uploadTemplate.desc4')
        },
        {
          type: 'fail',
          content: this.$t('verificationStatus.uploadTemplate.desc5')
        },
        {
          type: 'fail',
          content: this.$t('verificationStatus.uploadTemplate.desc6')
        }
      ];
    },
    lang() {
      return this.$store.state.common.lang;
    },
    userStep() {
      return getUserStep(this.$route.name);
    },
    nextRegisterStep() {
      return getNextRegisterStep(this.$route.name);
    },
    propNextStep() {
      return this.nextStep;
    },
    propPreStep() {
      return this.preStep;
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    }
  },
  mounted() {
    this.$route.query.entry && this.$route.query.entry === 'withdraw'
      ? (this.isFromWithdraw = true)
      : (this.isFromWithdraw = false);
    this.init();
  },
  methods: {
    // ...mapMutations('common', ['setId3Pass']),
    ...mapActions('common', ['actionUpdateId3Pass']),
    ...mapActions('register', [
      'actionNationalities',
      'actionStep',
      'actionCountries',
      'actionPoaAuditStatus',
      'actionUpdateIBTStatus',
      'actionChangePoaAuditStatus',
      'actionChangeIbtPoaAuditStatus',
      'actionPendingThenReSubmit',
      'actionRejectThenReSubmit'
    ]),
    init() {
      this.getData();
      this.actionNationalities(this.lang);
      this.actionCountries(this.lang);
    },
    async getData() {
      try {
        let step = this.userStep > 5 ? 5 : this.userStep;
        const res = await apiGetData(step);
        if (res.data.code === 0 && res.data?.data) {
          const list =
            this.isIBT && res.data.data.poaAdvancedDocFilePathList?.length
              ? res.data.data.poaAdvancedDocFilePathList
              : res.data.data.poaDocFilePathList;

          const preStepsData = this.$route.params;

          let userChoseFileList = null;

          if (preStepsData.ibtPoa && Object.keys(preStepsData.ibtPoa).length) {
            const { poaBlob } = preStepsData.ibtPoa;
            userChoseFileList = poaBlob ? [poaBlob] : [];
          }

          if (userChoseFileList) {
            if (userChoseFileList.length > 0) {
              this.$refs.POAUpload.$refs.uploadRef.handleStart(userChoseFileList[0].raw);
              this.$refs.POAUpload.$refs.uploadRef.submit();
            }
          } else if (list.length > 0) {
            if (this.resubmitType == statusConfig.pending) {
              const blob = await urlToFile(list[0]);
              const objUrl = URL.createObjectURL(blob);
              this.existingFilesPhoto = [
                {
                  url: objUrl,
                  name: blob.name,
                  type: blob.type,
                  raw: blob,
                  size: blob.size,
                  response: {
                    code: 0,
                    data: objUrl
                  },
                  existingFilePath: list[0]
                }
              ];
              this.form.uploadFilesPhoto = [list[0]];
            } else {
              this.$refs.POAUpload.$refs.uploadRef.handleStart(await urlToFile(list[0]));
              this.$refs.POAUpload.$refs.uploadRef.submit();
            }
          }

          this.form = {
            ...this.form,
            ...preStepsData?.ibtPoa,
            ...res.data.data
          };
        }

        // if status is resubmit
        if (this.isResubmit) {
          const response = await apiGet_last_identifiy_proof(this.fileType, this.auditTypeMap);
          if (response.data?.code === 0 && response.data?.data) {
            const { status, id, value } = response.data.data;
            if (id) this.documentType = id;
            if (!this.isIBT) {
              this.actionChangePoaAuditStatus(getStatus(status));
            } else {
              this.actionChangeIbtPoaAuditStatus(getStatus(status));
            }

            if (value?.address) {
              this.form.address = value.address;
            }
            if (value?.postcode) {
              this.form.postcode = value.postcode;
            }
            if (value?.suburb) {
              this.form.suburb = value.suburb;
            }
            if (value?.state) {
              this.form.state = value.state;
            }
          }
        }
      } catch (error) {
        this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
      }
    },
    validateUploadFilesPhoto(rule, value, callback) {
      if (this.uploadFilesPhotoCallbackError) {
        const { type, response } = this.uploadFilesPhotoCallbackError;
        switch (type) {
          case 'oversize':
            callback(
              new Error(
                this.$t('fileUploader.maxFileSize', {
                  maxSize: response.limitSize
                })
              )
            );
            return;
          case 'error':
            callback(new Error(response));
            return;
          case 'fileTypeNotAllow':
            callback(new Error(this.$t('verificationStatus.uploadTemplate.bottomDesc')));
            return;
          default:
            break;
        }
      }

      if (!value?.length) {
        callback(
          new Error(
            this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.poaVerification.upload.uploadFilesPhoto')
            })
          )
        );
        return;
      }

      return callback();
    },
    updateUploadFilesPhotoInfo(file) {
      this.form.uploadFilesPhoto = file?.fileList?.length ? [...file.fileList] : [];
      this.poaBlob = file.blob ? file.blob : null;
      this.uploadFilesPhotoCallbackError = null;
      this.$refs.form.validateField('uploadFilesPhoto');
    },
    onUploadFilesPhotoErrorOccur(result) {
      this.uploadFilesPhotoCallbackError = result;
      this.$refs.form.validateField('uploadFilesPhoto');
    },
    async updatePoaStatusAndShowDailog() {
      // push progress step
      if (!this.isResubmit) this.actionStep(getNextRegisterStep(this.$route.name));

      let apiIBTpoaStatus = null;
      // update status
      if (this.isIBT) {
        const { ibtPoaAuditStatus } = await this.actionUpdateIBTStatus();
        apiIBTpoaStatus = ibtPoaAuditStatus;
      } else {
        apiIBTpoaStatus = await this.actionPoaAuditStatus();
      }

      if (getStatus(apiIBTpoaStatus) !== statusConfig.completed) {
        this.resInfoType = 'pending';
        this.resDialogTitle = this.$t('register.poaVerification.generalInfo.title');
        this.resDialogContent = this.propNextStep
          ? this.$t('register.financialVerification.generalInfo.content')
          : this.$t('register.poaVerification.generalInfo.content', {
              platform: this.$config.info.fullName
            });
      }
      // update idPass status
      await this.actionUpdateId3Pass();
      this.isFromWithdraw ? this.$router.push({ path: '/withdrawFunds' }) : (this.showResDialog = true);
    },
    async submitIBTData(isFirstTimeSubmit = false) {
      try {
        // resubmit process
        if (this.isResubmit) {
          let res = false;
          if (this.resubmitType === statusConfig.pending) {
            res = await this.actionPendingThenReSubmit({
              files: [...this.form.uploadFilesPhoto],
              documentId: this.documentType,
              fileType: this.fileType
            });
          } else if (this.resubmitType === statusConfig.rejected) {
            res = await this.actionRejectThenReSubmit({
              filePathList: [...this.form.uploadFilesPhoto],
              fileType: this.fileType,
              auditType: this.auditTypeMap
            });
          }
          if (res) await this.updatePoaStatusAndShowDailog();
          return;
        }

        const preStepsData = this.$route.params;
        // first time submit
        if (isFirstTimeSubmit && preStepsData?.financialInfo && preStepsData?.ibtPoi) {
          // submit finanacial info
          await apiSubmitIbtQuestionnaire(preStepsData?.financialInfo);

          // submit IBT poi
          const userIdAddressRes = await apiUpdate_user_id_address({
            idType: preStepsData?.ibtPoi?.idType,
            idNumber: preStepsData?.ibtPoi?.idNumber
          });
          if (userIdAddressRes.data.code == 0) {
            await apiVerification2IdentityProofs({
              filePathList: preStepsData?.ibtPoi?.filePathList,
              fileType: preStepsData?.ibtPoi?.fileType,
              auditType: preStepsData?.ibtPoi?.auditType
            });
          }
        }

        // submit IBT poa
        const useIdAddressRes = await apiUpdate_user_id_address({ ...this.form });
        if (useIdAddressRes.data.code == 0) {
          const idProofRes = await apiVerification2IdentityProofs({
            filePathList: [...this.form.uploadFilesPhoto],
            fileType: this.fileType,
            auditType: this.auditTypeMap
          });
          if (idProofRes.data.code == 0) {
            await this.updatePoaStatusAndShowDailog();
          }
        }
      } catch (error) {
        this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
      }
    },
    submit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const isFirstTimeSubmit =
            this.ibtPoiAuditStatus === statusConfig.init && this.ibtPoaAuditStatus === statusConfig.init;
          await this.submitIBTData(isFirstTimeSubmit);
        }
      });
    },
    async goToDestination(destination) {
      if (destination) await this.$router.push({ name: destination }).catch(() => {});
      this.showResDialog = false;
    },
    goToNextStep() {
      this.$router.push(this.propNextStep);
    },
    goBack() {
      const preStepsData = this.$route.params;
      this.$router.push({
        name: 'iDProof',
        params: {
          ...preStepsData,
          ibtPoa: {
            nationalityId: this.form.nationalityId,
            countryId: this.form.countryId,
            address: this.form.address,
            postcode: this.form.postcode,
            suburb: this.form.suburb,
            state: this.form.state,
            poaBlob: this.poaBlob
          }
        }
      });
    },
    async closeRegisterDialog() {
      if (
        this.isIBT &&
        this.ibtPoiAuditStatus === statusConfig.completed &&
        this.ibtPoaAuditStatus === statusConfig.completed
      ) {
        this.$rotuer.go(-1);
      } else {
        await this.goToDestination('register');
      }
    },
    getFieldErrorMsg(fields) {
      let error = null;
      switch (fields) {
        case 'nationalityId':
          error = 'nationality';
          break;
        case 'countryId':
          error = 'country';
          break;
        case 'address':
          error = 'address';
          break;
        case 'postcode':
          error = 'postalCode';
          break;
        case 'suburb':
          error = 'city';
          break;
        case 'state':
          error = 'provinceState';
          break;
        default:
          break;
      }
      return error
        ? this.$t('common.formValidation.dynamicReq', {
            dynamic: this.$t(`register.poaVerification.${error}`)
          })
        : '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/registerNew.scss';
.upload-container {
  margin-top: 16px;
}
.upload-head {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  ul {
    li {
      list-style: disc;
      margin: 0 0 10px 24px;
      font-size: 16px;
    }
  }
  p {
    color: $pug-blue;
    font-size: 16px;
  }
}
.upload-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &-title {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 18px;
    color: $pug-black;
    word-break: break-word;
  }
  &-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $pug-black;
    word-break: break-word;
  }
}

/deep/.upload-guide {
  width: fit-content;
  height: fit-content;
  &-text {
    font-weight: 800;
    font-size: 14px;
    line-height: 28px;
    white-space: nowrap;
  }
}
.upload-area {
  &-item {
    height: 260px !important;
  }

  /deep/ .el-form-item {
    width: 100%;
  }
}

.action-area {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.action-button {
  width: 200px;
}

// guide dialog
.guide-dialog {
  /deep/ .el-dialog__wrapper .el-dialog {
    max-width: 1200px;
  }
  /deep/ .dialog .el-dialog__body {
    padding: 8px 16px 0;
  }
}
.guide-title {
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: $pug-black;
  margin-bottom: 16px;
  word-break: break-word;
}
.demo-area {
  margin-bottom: 30px;
  display: flex;
}
.demo-correct {
  border-right: 2px solid $pug-gray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  .demo-item {
    padding-right: 20px;
  }
}
.demo-fail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.demo-item {
  position: relative;
  &-main {
    height: 160px;
    width: auto;
  }
  &-icon {
    width: 36px;
    height: 36px;
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.guide-list {
  margin-bottom: 30px;
  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }

  &-type {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  &-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    word-break: break-word;
  }
}

.guide-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    word-break: break-word;
  }
}

.proceed-upload-btn {
  width: 212px;
  height: 60px;
  margin-left: 16px;
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .upload-head {
    flex-direction: column;
    margin-bottom: 16px;
  }
  .upload-main {
    margin-bottom: 16px;
  }
  .guide-dialog {
    /deep/ .el-dialog__wrapper .el-dialog {
      max-width: 680px;

      .dialog-body {
        padding: 0 35px 36px 30px;
      }
    }
  }
  .demo-area {
    flex-direction: column;
    align-items: flex-start;
  }
  .demo-correct {
    border-right: none;
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .demo-fail {
    gap: 16px;
  }
  .demo-item {
    &-main {
      height: 96px;
    }
    &-icon {
      width: 20px;
      height: 20px;
      bottom: -10px;
    }
  }

  .guide-bottom {
    flex-direction: column;
    justify-content: center;
  }
  .proceed-upload-btn {
    width: 100%;
    margin-left: 0;
    margin-top: 24px;
  }
}

@media (max-width: 900px) {
  .upload-area {
    flex-direction: column;
    /deep/ .el-form-item {
      width: 100%;
      max-width: unset;
    }
  }
}

@media (max-width: 770px) {
  .action-area {
    flex-direction: column-reverse;
    gap: 16px;
  }
  .action-button {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .guide-dialog {
    /deep/ .el-dialog__wrapper .el-dialog {
      .dialog-body {
        padding: 0 16px 24px 16px;
      }
    }
  }
  .demo-fail {
    gap: 6px;
  }
  .demo-item {
    &-main {
      height: 64px;
    }
  }
}
</style>
