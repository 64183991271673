<template>
  <div>
    <HowWorks :immediateShowContent="true" />
    <div class="card">
      <div class="title-box">
        <h4 class="sub-title">{{ $t('propTrade.createAccount.chooseAccount.subTitle') }}</h4>
        <p class="narrative" v-html="$t('propTrade.createAccount.chooseAccount.desc')"></p>
      </div>
      <el-form class="form-box" :model="form" :rules="formRules" ref="form">
        <div class="input-box">
          <el-row :gutter="40">
            <el-col :sm="12" :xs="24">
              <el-form-item prop="tradingPlatform">
                <Selection
                  v-model="form.tradingPlatform"
                  :placeholder="
                    isEmptySettings ? $t('common.formValidation.na') : $t('common.formValidation.answerReq')
                  "
                  :options="tradingPlatformOptions"
                  :disabled="true"
                  filterable
                  selectType="underline"
                  dataTestId="platform"
                >
                  <template #title>
                    {{ $t('propTrade.createAccount.chooseAccount.tradingPlatform') }}
                  </template>
                </Selection>
              </el-form-item>
              <el-form-item prop="currency">
                <Selection
                  v-model="form.currency"
                  :placeholder="
                    isEmptySettings ? $t('common.formValidation.na') : $t('common.formValidation.answerReq')
                  "
                  :options="currencyOptions"
                  filterable
                  :disabled="isEmptySettings"
                  selectType="underline"
                  dataTestId="currency"
                >
                  <template #title>
                    {{ $t('propTrade.createAccount.chooseAccount.accountCurrency') }}
                  </template>
                </Selection>
                <div class="el-form-item__error" v-if="isEmptySettings"></div>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :xs="24">
              <el-form-item prop="accountType">
                <Selection
                  v-model="form.accountType"
                  :placeholder="
                    isEmptySettings ? $t('common.formValidation.na') : $t('common.formValidation.answerReq')
                  "
                  :options="calAccountTypeList"
                  :disabled="isEmptySettings"
                  filterable
                  selectType="underline"
                  dataTestId="type"
                >
                  <template #title>
                    {{ $t('propTrade.createAccount.chooseAccount.accountType') }}
                    <InfoPopover popoverWidth="190">
                      <template v-slot:popoverContent>
                        <div>{{ $t('propTrade.createAccount.chooseAccount.accountTypeInfo') }}</div>
                      </template>
                    </InfoPopover>
                  </template>
                </Selection>
              </el-form-item>
              <div class="trading-goals">
                <div class="trading-goals-title">
                  {{ $t('propTrade.createAccount.chooseAccount.targetProfit') }}
                </div>
                <ul class="trading-goals-list" v-if="currentSetting">
                  <li>
                    <div class="lable">{{ $t('propTrade.createAccount.chooseAccount.maxDailyLoss') }} :</div>
                    <div class="value">
                      <span class="num">{{ currentSetting.maxDailyLoss | formatPercentage }}</span>
                    </div>
                  </li>
                  <li>
                    <div class="lable">{{ $t('propTrade.createAccount.chooseAccount.maxLoss') }} :</div>
                    <div class="value">
                      <span class="num">{{ currentSetting.maxTotalLoss | formatPercentage }}</span>
                    </div>
                  </li>
                  <li>
                    <div class="lable">{{ $t('propTrade.createAccount.chooseAccount.targetProfit') }} :</div>
                    <div class="value">
                      <span class="num">{{ currentSetting.profitTarget | formatPercentage }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :sm="12" :xs="24">
              <el-form-item prop="accountBalance">
                <Selection
                  v-model="form.accountBalance"
                  :placeholder="
                    isEmptySettings ? $t('common.formValidation.na') : $t('common.formValidation.answerReq')
                  "
                  :options="accountBalanceOptions"
                  filterable
                  :disabled="isEmptySettings"
                  selectType="underline"
                  dataTestId="accountBalance"
                >
                  <template #title>
                    {{ $t('propTrade.createAccount.chooseAccount.accountBalance') }}
                  </template>
                </Selection>
                <div class="el-form-item__error" v-if="isEmptySettings">
                  {{ $t('propTrade.createAccount.noBalance') }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :xs="24">
              <el-form-item prop="leverage">
                <Selection
                  v-model="form.leverage"
                  :placeholder="
                    isEmptySettings ? $t('common.formValidation.na') : $t('common.formValidation.answerReq')
                  "
                  :options="leverageOptions"
                  :disabled="true"
                  filterable
                  selectType="underline"
                  dataTestId="leverage"
                >
                  <template #title>
                    {{ $t('propTrade.createAccount.chooseAccount.leverage') }}
                    <InfoPopover :popoverWidth="leverageInfoWidth">
                      <template v-slot:popoverContent>
                        <div v-html="$t('propTrade.createAccount.chooseAccount.leverageInfo')"></div>
                      </template>
                    </InfoPopover>
                  </template>
                </Selection>
                <div class="el-form-item__error" v-if="isEmptySettings"></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="apiData.canOpenInstantAccount">
            <el-col>
              <el-form-item>
                <div class="checkBox pug-checkbox">
                  <div class="d-flex">
                    <el-checkbox v-model="form.isOpenInstantAccount">
                      {{ $t('propTrade.createAccount.chooseAccount.openInstantAccount') }}
                    </el-checkbox>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="payment-box">
          <h4 class="sub-title">{{ $t('propTrade.createAccount.payment.payment') }}</h4>
          <ul class="fee">
            <li>
              <div class="lable">
                <span>{{ $t('propTrade.createAccount.payment.totalRegistrationFee') }}</span>
                <InfoPopover popoverWidth="180">
                  <template v-slot:popoverContent>
                    <div>{{ $t('propTrade.createAccount.payment.totalRegistrationFeeInfo') }}</div>
                  </template>
                </InfoPopover>
              </div>
              <div class="value">
                <span class="currency">{{ form.currency }}</span>
                <span>{{ fee | fixedDecimal(2) }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="check-box">
          <el-form-item prop="checkStatus">
            <div class="checkBox pug-checkbox">
              <div class="d-flex">
                <el-checkbox id="checkbox" name="checkStatus" v-model="form.checkStatus" data-testid="checkStatus">
                  <p
                    class="check-lable"
                    v-html="
                      $t('propTrade.createAccount.chooseAccount.agreeTermsAndConditions', {
                        link: 'https://www.puxtrader.com/pdf/PU_Xtrader_GTC.pdf?v=1716280290'
                      })
                    "
                  ></p>
                </el-checkbox>
              </div>
            </div>
          </el-form-item>
        </div>
        <div class="small-narrative">
          {{ $t('propTrade.createAccount.chooseAccount.agreeTermsAndConditionsDesc') }}
        </div>
        <div class="btn-box">
          <Button buttonType="default" customClasses="action-button" dataTestId="confirm" @click="submit">
            <div>{{ $t('propTrade.button.submitProceedPayment') }}</div>
          </Button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import HowWorks from '@/components/propTrading/components/HowWorks';
import Button from '@/components/common/Button.vue';
import Selection from '@/components/common/Selection.vue';
import InfoPopover from '@/components/common/InfoPopover';
import { checkAgreedTNC, checkAgreedCancellation } from '@/util/validation';
import splitThousands from '@/util/splitThousands';
import { apiPropTradingSettings } from '@/resource';
import { ACCOUNT_TYPE, DEFAULT_ACCOUNT_TYPE } from '@/constants/propTrading';
import propTradeCommonFilters from '@/mixins/page/propTrade/commonFilters';
import formatPercentage from '@/util/formatPercentage';

export default {
  name: 'ChooseAccount',
  components: { HowWorks, Button, Selection, InfoPopover },
  mixins: [propTradeCommonFilters],
  data() {
    return {
      form: {
        tradingPlatform: null,
        currency: null,
        accountType: DEFAULT_ACCOUNT_TYPE,
        accountBalance: 10000,
        leverage: null,
        checkStatus: false,
        isOpenInstantAccount: false
      },
      apiData: { settings: [], promoFees: [], canOpenInstantAccount: false },
      formRules: {
        currency: [{ required: true, trigger: 'change' }],
        accountBalance: [{ required: true, trigger: 'change' }],
        leverage: [{ required: true, trigger: 'change' }],
        checkStatus: [{ validator: checkAgreedTNC, trigger: 'change' }]
      },
      accountTypeList: [
        {
          value: ACCOUNT_TYPE.BASIC,
          label: 'Basic'
        },
        {
          value: ACCOUNT_TYPE.PLUS,
          label: 'Plus'
        }
      ],
      tradingGoals: {
        maxDailyLoss: '-5',
        maxLoss: '-10',
        targetProfit: '-10'
      }
    };
  },
  filters: {
    formatPercentage(value = 0) {
      return formatPercentage(value);
    }
  },
  computed: {
    currentSetting() {
      const { currency, accountType, accountBalance, leverage } = this.form;

      const items = this.filterHelper({ currency, accountType, accountBalance, leverage });

      if (items.length > 1) return null;

      return items?.[0];
    },
    settings() {
      return this.apiData.settings;
    },
    isEmptySettings() {
      return !this.settings?.length;
    },
    promoFees() {
      return this.apiData.promoFees;
    },
    fee() {
      const { currency, accountType, accountBalance, leverage } = this.form;

      const items = this.filterHelper({ currency, accountType, accountBalance, leverage });

      if (items.length > 1) throw new Error('duplicate data');

      return items?.[0]?.fee ?? 0;
    },
    calAccountTypeList() {
      if (this.isEmptySettings) {
        this.form.accountType = null;
      } else if (this.form.accountType === null) {
        this.form.accountType = DEFAULT_ACCOUNT_TYPE;
      }

      return this.isEmptySettings ? [] : this.accountTypeList;
    },
    tradingPlatformOptions() {
      const tradingPlatformData = [...new Set(this.filterHelper().map(item => item.tradingPlatform))];

      if (!tradingPlatformData.length) {
        return [];
      }

      this.form.tradingPlatform = tradingPlatformData[0].toUpperCase();
      // change label context to mt4, mt5
      return tradingPlatformData.map(item => ({
        label: item.toUpperCase(),
        value: item.toLowerCase()
      }));
    },
    currencyOptions() {
      const { accountType } = this.form;

      // Sorted currency options :
      // Currency not belonging to any of currencySortedOrder's items will be placed in the end, according to the api order.
      const currencySortedOrder = ['USD', 'EUR', 'GBP', 'CAD'];
      const currentAccountsFilterByAccountType = [
        ...new Set(this.filterHelper({ accountType }).map(item => item.currency))
      ].map(item => {
        return {
          value: item,
          label: item
        };
      });

      const result = currentAccountsFilterByAccountType.sort((a, b) => {
        let aIndex = currencySortedOrder.indexOf(a.value) < 0 ? 9999 : currencySortedOrder.indexOf(a.value);
        let bIndex = currencySortedOrder.indexOf(b.value) < 0 ? -9999 : currencySortedOrder.indexOf(b.value);

        return aIndex - bIndex;
      });

      this.form.currency = result?.[0]?.value;

      return result;
    },
    accountBalanceOptions() {
      let result = [];

      const { currency, accountType } = this.form;

      result = [...new Set(this.filterHelper({ currency, accountType }).map(item => item.initBalance))];

      result = result
        .sort((a, b) => a - b)
        .map(item => {
          return {
            value: item,
            label: splitThousands(item)
          };
        });

      this.form.accountBalance = result?.[0]?.value;

      return result;
    },
    leverageOptions() {
      const { currency, accountType, accountBalance } = this.form;

      const filtered = this.filterHelper({ currency, accountType, accountBalance });

      if (filtered.length > 1) throw new Error('duplicate data');

      const result = filtered
        .map(item => {
          return {
            value: item.leverage,
            label: `${item.leverage}:1`
          };
        })
        .flat();

      this.form.leverage = result?.[0]?.value;

      return result;
    },
    lang() {
      return this.$store.state.common.lang;
    },
    leverageInfoWidth() {
      switch (this.lang) {
        case 'fr_FR':
        case 'pt':
        case 'ja':
        case 'vi':
        case 'id':
        case 'ar':
          return 220;
        case 'es':
          return 260;
        default:
          return 190;
      }
    }
  },
  methods: {
    filterHelper({ currency, accountType, accountBalance, leverage } = {}) {
      let data = [...this.settings];

      if (currency) data = data.filter(d => d.currency === currency);
      if (accountType || typeof accountType === 'number') data = data.filter(d => d.type === accountType);
      if (accountBalance || typeof accountBalance === 'number')
        data = data.filter(d => d.initBalance === accountBalance);
      if (leverage) data = data.filter(d => d.leverage === leverage);
      // if (leverage) data = data.filter(d => d.leverages.includes(leverage));

      return data;
    },
    goToDestination() {
      this.$router.push({ name: 'home' });
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('redirectCurrentPage', 'billingDetail');
          this.$emit('changeChooseAccountData', {
            form: this.form,
            currentSetting: this.currentSetting,
            settings: this.settings,
            promoFees: this.promoFees
          });
        }
      });
    },
    getApiPropTradingSettings() {
      apiPropTradingSettings()
        .then(res => {
          if (res.data.code === 0) {
            this.apiData = res.data.data;
          } else {
            console.log(res.data.errmsg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.getApiPropTradingSettings();
  }
};
</script>
<style lang="scss" scoped>
.trading-goals {
  border-radius: 10px;
  background: $pug-gray-disabled-bg;
  padding: 8px 16px;
  &-title {
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    color: $pug-black;
    margin-bottom: 4px;
    font-style: italic;
  }
  &-list {
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
      .lable {
        color: $pug-gray-title;
        font-size: 14px;
      }
      .value {
        text-align: right;
        color: $pug-blue;
        font-size: 14px;
        .num {
          display: inline-block;
          font-weight: bold;
          color: $pug-blue;
          padding-right: 4px;
        }
      }
    }
  }
}
.payment-box {
  border-bottom: 1px solid $pug-gray-disabled-bg;
  margin-bottom: 24px;
  .fee {
    margin-bottom: 24px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .lable {
      font-size: 16px;
      color: $pug-black;
    }
    .value {
      font-size: 16px;
      font-weight: bold;
      color: $pug-black;
    }
    .currency {
      margin-right: 4px;
    }
  }
}
/deep/.check-lable {
  a {
    color: $pug-blue;
  }
}
.small-narrative {
  font-size: 12px;
  color: $pug-gray-pending;
  margin-bottom: 24px;
  margin-top: -12px;
  word-break: break-word;
  line-height: 16px;
}
.input-box {
  border-bottom: 1px solid $pug-gray-disabled-bg;
  margin-bottom: 24px;
}
.action-button {
  font-size: 16px;
  width: 100%;
  /deep/ .el-button {
    background-color: $sky-blue;
    &:hover {
      background-color: #0154c8;
    }
  }
}

/deep/ {
  .el-form-item__error ~ .el-form-item__error {
    display: none;
  }
}

.check-box {
  /deep/ .el-form-item {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 32px;
    }
  }
  /deep/ .el-form-item__error {
    top: 75%;
  }
}

@media (min-width: 768px) {
  .trading-goals {
    border-radius: 20px;
  }
  .small-narrative {
    margin-bottom: 64px;
  }
  .action-button {
    width: 330px;
    height: 60px;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .check-box {
    /deep/ .el-form-item {
      margin-bottom: 26px;
    }
  }
}
</style>
