<template>
  <div
    :class="[bgImgSize, lang, ...calCustomClasses]"
    class="dashboard-top-banner"
    :style="{ ...calStyles }"
    v-resizeObserver="{
      openResize: true,
      handler: observerCallBack
    }"
  >
    <div class="title-box">{{ title }}</div>
    <div class="btn-box">
      <Button
        buttonType="default"
        customClasses="dashboard-top-banner-button"
        :dataTestId="calDataTestId"
        @click="clickButtonEvent"
      >
        <div>{{ buttonText }}</div>
      </Button>
    </div>
    <div v-if="bgImgUrl" class="bg-img">
      <img :src="bgImgUrl" alt />
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';

export default {
  name: 'DashboardTopBanner',
  components: { Button },
  props: {
    customClasses: {
      type: Array | String,
      default: ''
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    bgImg: {
      type: Object,
      default: () => ({})
    },
    dataTestId: {
      type: String | Number,
      default: 'button'
    }
  },
  data() {
    return {
      bannerWidth: 0,
      bgImgSize: 'normal'
    };
  },
  computed: {
    calDataTestId() {
      return this.dataTestId;
    },
    calCustomClasses() {
      return typeof this.customClasses === 'string' ? [this.customClasses] : [...this.customClasses];
    },
    calStyles() {
      return { ...this.styles };
    },
    lang() {
      return this.$store.state.common.lang;
    },
    bgImgUrl() {
      if (!Object.keys(this.bgImg).length) return '';

      const { img, imgSmall, breakpoint } = this.bgImg;

      if (!breakpoint) return img;

      if (this.bannerWidth > Number(breakpoint)) {
        this.bgImgSize = 'normal';
        return img;
      } else {
        this.bgImgSize = 'small';
        return imgSmall;
      }
    }
  },
  methods: {
    observerCallBack(width) {
      this.bannerWidth = width;
    },
    clickButtonEvent() {
      this.$emit('clickButtonEvent');
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard-top-banner {
  width: 100%;
  height: 96px;
  margin-bottom: 10px;
  border-radius: 8px;
  display: block;
  padding: 14px 16px;
  position: relative;
  overflow: hidden;

  .title-box {
    font-size: 14px;
    font-weight: bold;
    @include rtl-sass-value(text-align, left, right);
    color: $pug-white;
    margin-bottom: 6px;
    width: 60%;
    line-height: 1.2;
    position: relative;
    z-index: 1;
  }

  &-button {
    width: fit-content;
    height: 36px;
    position: relative;
    z-index: 1;
    /deep/.el-button {
      font-size: 12px;
      font-weight: bold;
      padding: 0 20px;
    }
  }

  .bg-img {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
  }
  &.normal {
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    .title-box {
      font-size: 18px;
      margin-left: 42px;
      margin-bottom: 0;
      width: 100%;
    }
    &-button {
      height: 36px;
      /deep/.el-button {
        padding: 0 28px;
      }
    }
  }
}

@media (min-width: 576px) {
  .dashboard-top-banner {
    .title-box {
      width: 200px;
    }
  }
}

@media (min-width: 768px) {
  .dashboard-top-banner {
    height: 152px;
    padding: 24px 32px;
    .title-box {
      font-size: 24px;
      margin-bottom: 12px;
      width: 300px;
    }
  }
}

@media (min-width: 1024px) {
  .dashboard-top-banner {
    margin-bottom: 20px;
  }
}

@media (min-width: 1440px) {
  .dashboard-top-banner {
    &.normal {
      .title-box {
        font-size: 26px;
      }
    }
  }
}
</style>
