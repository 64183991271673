<template>
  <el-form class="form-box" :model="form" :rules="formRules" ref="form">
    <el-row :gutter="40">
      <el-col :sm="12" :xs="24">
        <el-form-item prop="firstName">
          <TextInput
            type="text"
            :placeholder="$t('common.formValidation.sameAsID')"
            v-model="form.firstName"
            :inputLimitation="formatInputName"
            :disabled="disabled"
            dataTestId="firstName"
          >
            <template #title>
              {{ $t('register.accOpeningVerification.personalInformation.firstName') }}
            </template>
          </TextInput>
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item prop="middleName">
          <TextInput
            type="text"
            :placeholder="$t('common.formValidation.notBlank')"
            v-model="form.middleName"
            :inputLimitation="formatInputName"
            :disabled="disabled"
            dataTestId="middleName"
          >
            <template #title>
              {{ $t('register.accOpeningVerification.personalInformation.middleName') }}
            </template>
          </TextInput>
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item prop="lastName">
          <TextInput
            type="text"
            :placeholder="$t('common.formValidation.sameAsID')"
            v-model="form.lastName"
            :inputLimitation="formatInputName"
            :disabled="disabled"
            dataTestId="lastName"
          >
            <template #title>
              {{ $t('register.accOpeningVerification.personalInformation.lastName') }}
            </template>
          </TextInput>
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item prop="gender">
          <Label>{{ $t('register.accOpeningVerification.personalInformation.gender') }}</Label>
          <el-radio-group v-model="form.gender" data-testid="gender" :disabled="disabled">
            <el-radio v-for="itme in genderList" :key="itme.key" :label="itme.key" :data-testid="itme.key">{{
              itme.name
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item prop="dob">
          <Label>{{ $t('register.accOpeningVerification.personalInformation.dob') }}</Label>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="date" :show-message="false">
                <Selection
                  v-model="dateOfBirth.date"
                  :placeholder="$t('register.accOpeningVerification.personalInformation.day')"
                  :options="dates"
                  :disabled="disabled"
                  filterable
                  selectType="underline"
                  dataTestId="date"
                >
                </Selection>
                <span class="line"></span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="month" :show-message="false">
                <Selection
                  :customClasses="['select-indent']"
                  v-model="dateOfBirth.month"
                  :placeholder="$t('register.accOpeningVerification.personalInformation.month')"
                  :options="months"
                  :disabled="disabled"
                  filterable
                  selectType="underline"
                  dataTestId="month"
                >
                </Selection>
                <span class="line"></span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="year" :show-message="false">
                <Selection
                  :customClasses="['select-indent']"
                  v-model="dateOfBirth.year"
                  :placeholder="$t('register.accOpeningVerification.personalInformation.year')"
                  :options="years"
                  :disabled="disabled"
                  filterable
                  selectType="underline"
                  dataTestId="year"
                >
                </Selection>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item>
          <Label>{{ $t('register.accOpeningVerification.personalInformation.mobile') }}</Label>
          <el-row :gutter="14">
            <el-col :sm="6" :xs="8">
              <el-form-item prop="phoneCode">
                <Selection
                  v-model="form.phoneCode"
                  :options="phoneCodes"
                  :disabled="disabled"
                  placeholder="00"
                  filterable
                  selectType="underline"
                  dataTestId="phoneCode"
                >
                </Selection>
              </el-form-item>
            </el-col>
            <el-col :sm="18" :xs="16">
              <el-form-item prop="mobile">
                <TextInput
                  type="text"
                  v-model="form.mobile"
                  :disabled="disabled"
                  placeholder="0000 0000"
                  dataTestId="mobile"
                ></TextInput>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item prop="countryCode">
          <Selection
            v-model="form.countryCode"
            :placeholder="$t('common.formValidation.answerReq')"
            :options="countries"
            filterable
            disabled
            selectType="underline"
            dataTestId="country"
          >
            <template #title>
              {{ $t('register.accOpeningVerification.personalInformation.countryName') }}
            </template>
          </Selection>
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item prop="nationalityId">
          <Selection
            v-model="form.nationalityId"
            :placeholder="$t('common.formValidation.answerReq')"
            :options="nationalities"
            :disabled="disabled"
            filterable
            selectType="underline"
            dataTestId="nationalityId"
          >
            <template #title>
              {{ $t('register.accOpeningVerification.personalInformation.nationality') }}
            </template>
          </Selection>
        </el-form-item>
      </el-col>
      <template v-if="emailshow">
        <el-col :sm="12" :xs="24">
          <el-form-item prop="email">
            <TextInput
              type="text"
              :placeholder="$t('common.formValidation.emailReq')"
              v-model="form.email"
              :disabled="disabled"
              dataTestId="email"
            >
              <template #title>
                {{ $t('register.accOpeningVerification.personalInformation.email') }}
              </template>
            </TextInput>
          </el-form-item>
        </el-col>
      </template>
    </el-row>
    <div class="button-box">
      <Button
        buttonType="default"
        :customClasses="['m-auto', 'action-button']"
        dataTestId="next"
        @click="submit('form')"
      >
        <div>{{ $t('register.btn.next') }}</div>
      </Button>
    </div>
  </el-form>
</template>

<script>
import Button from '@/components/common/Button.vue';
import TextInput from '@/components/common/TextInput.vue';
import Selection from '@/components/common/Selection.vue';
import { validateNumber } from '@/util/validation';
import { registerFirstStep, getUserStep, getNextRegisterStep } from '@/util/register';
import { REGISTERROUTER } from '@/constants/route';
import { apiGetData, apiProcess, apiGetUsername } from '@/resource/register';
import { mapState, mapActions, mapGetters } from 'vuex';
import { inhibitInputSpecialCharacters, inhibitInputNumber } from '../../../../util/helper';

export default {
  name: 'PersonalInformationDefault',
  components: { Button, TextInput, Selection },
  data() {
    return {
      dateOfBirth: {
        year: null,
        month: null,
        date: null
      },
      form: {
        firstName: '',
        middleName: '',
        lastName: '',
        gender: null,
        mobile: '',
        dob: null,
        countryCode: null,
        email: ''
      },
      formRules: {
        firstName: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.personalInformation.firstName')
            }),
            trigger: 'blur'
          }
        ],
        // middleName: [
        //   {
        //     required: true,
        //     message: this.$t('common.formValidation.dynamicReq', {
        //       dynamic: this.$t('register.accOpeningVerification.personalInformation.middleName')
        //     }),
        //     trigger: 'blur'
        //   }
        // ],
        lastName: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.personalInformation.lastName')
            }),
            trigger: 'blur'
          }
        ],
        gender: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.personalInformation.gender')
            }),
            trigger: 'change'
          }
        ],
        dob: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.personalInformation.dob')
            }),
            trigger: 'change'
          }
        ],
        phoneCode: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.personalInformation.mobile')
            }),
            trigger: ['blur', 'change']
          }
        ],
        mobile: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.mobReq')
          },
          {
            min: 4,
            message: this.$t('common.formValidation.noteLessThan', { limit: 4 })
          },
          {
            max: 20,
            message: this.$t('common.formValidation.noteMoreThan', { limit: 20 })
          },
          {
            validator: validateNumber,
            trigger: 'blur'
          }
        ],
        nationalityId: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.personalInformation.nationality')
            }),
            trigger: ['blur', 'change']
          }
        ],
        email: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.personalInformation.email')
            }),
            trigger: 'blur'
          }
        ]
      },
      genderList: [
        { name: this.$t('register.accOpeningVerification.personalInformation.genderList.male'), key: 'Male' },
        { name: this.$t('register.accOpeningVerification.personalInformation.genderList.female'), key: 'Female' }
        // { name: this.$t('register.accOpeningVerification.personalInformation.genderList.other'), key: 'Other' }
      ],
      ndt: new Date(),
      emailshow: false
    };
  },
  computed: {
    ...mapState('common', ['countryCode']),
    ...mapState('register', ['userData', 'currStep']),
    ...mapGetters('register', ['nationalities', 'phoneCodes', 'countries']),
    disabled() {
      return this.currStep > registerFirstStep.personalInformation;
    },
    years() {
      const year = this.ndt.getFullYear() - 18;
      return Array.from({ length: 100 - 18 }, (value, index) => {
        let num = year - index;
        return { value: num, label: num };
      });
    },
    months() {
      if (this.dateOfBirth.year == this.years[0].value) {
        const month = this.ndt.getMonth() + 1;
        return Array.from({ length: month }, (value, index) => {
          let num = this.$options.filters.appendzero(index + 1);
          return { value: num, label: num };
        });
      }
      return Array.from({ length: 12 }, (value, index) => {
        let num = this.$options.filters.appendzero(index + 1);
        return { value: num, label: num };
      });
    },
    dates() {
      const d =
        this.dateOfBirth.year == this.years[0].value && this.dateOfBirth.month == this.ndt.getMonth() + 1
          ? this.ndt.getDate()
          : new Date(this.dateOfBirth.year, this.dateOfBirth.month, 0).getDate();
      return Array.from({ length: d }, (value, index) => {
        let num = this.$options.filters.appendzero(index + 1);
        return { value: num, label: num };
      });
    },
    lang() {
      return this.$store.state.common.lang;
    },
    userStep() {
      return getUserStep(this.$route.name);
    },
    nextRegisterStep() {
      return getNextRegisterStep(this.$route.name);
    }
  },
  watch: {
    dateOfBirth: {
      deep: true,
      immediate: true,
      handler(dob) {
        const { date, month, year } = dob;
        if (date || month || year) {
          const presMonth = this.months.find(f => f.value == dob.month);
          const presDate = this.dates.find(f => f.value == dob.date);
          if (!presMonth) dob.month = '';
          if (!presDate) dob.date = '';
          this.form.dob = dob.year && dob.month && dob.date ? `${dob.year}-${dob.month}-${dob.date}` : '';

          this.$nextTick(() => {
            this.$refs.form.validateField('dob');
          });
        }
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('register', ['actionNationalities', 'actionPhoneCodes', 'actionCountries', 'actionStep']),
    async init() {
      await this.actionNationalities(this.lang);
      await this.actionPhoneCodes();
      await this.actionCountries(this.lang);
      await this.getData();
    },
    getData() {
      apiGetData(this.userStep).then(resp => {
        if (resp.data.code === 0 && resp.data?.data) {
          const userData = resp.data.data;
          this.form = {
            ...userData,
            countryCode: this.countries.find(item => item.value === parseInt(this.countryCode)).value
          };
          this.emailshow = !userData.email;
          if (userData.dob) {
            const splitDob = userData.dob.split('-');
            this.dateOfBirth = {
              year: parseInt(splitDob[0]),
              month: this.$options.filters.appendzero(parseInt(splitDob[1])),
              date: this.$options.filters.appendzero(parseInt(splitDob[2]))
            };
          }
        }
      });
    },
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          apiProcess({ step: this.userStep, ...this.form }).then(resp => {
            if (resp.data.code == 0) {
              apiGetUsername().then(res => {
                let username = res.data.data;
                this.actionStep(this.nextRegisterStep);
                this.$store.commit('common/setUserName', username);
                this.$store.commit('common/setIndividualName', username.split('&')[0].trim());
                this.$router.push(REGISTERROUTER.CREATE_LIVE_ACCOUNT);
              });
            }
          });
        }
      });
    },
    formatInputName(value) {
      if (value) {
        value = inhibitInputSpecialCharacters(value);
        value = inhibitInputNumber(value);
      }
      return value;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ {
  .el-radio__inner {
    width: 16px;
    height: 16px;
  }
  .el-radio__input.is-checked .el-radio__inner {
    content: url(~@/assets/images/openAccount/radio02.png);
  }
}
.line {
  display: inline-block;
  width: 1px;
  height: 22px;
  background: $pug-pray-border;
  position: absolute;
  top: 8px;
  right: 0;
}
.selection.select-indent {
  /deep/.el-select .el-input__inner {
    padding-left: 20px;
  }
}
.action-button {
  width: 160px;
  height: 56px;
}
@media (max-width: 770px) {
  .action-button {
    width: 100%;
  }
}
</style>
