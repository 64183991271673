<template>
  <div>
    <div class="searchbar-container">
      <SearchBar
        :containerWidth="mainWrapperWidth"
        :excludeStatusOption="excludeStatusOption"
        :excludeStagesOption="excludeStagesOption"
        :showFilterStatus="showFilterStatus"
        :showFilterStages="showFilterStages"
        :showFilterSession="showFilterSession"
        @statusChange="handleStatusChange"
        @stagesChange="handleStagesChange"
        @sessionChange="handleSessionChange"
      ></SearchBar>
    </div>

    <div class="account-wrapper">
      <component
        :is="currentComponent"
        :accountData="account"
        :newAccount="account.isNew"
        :containerWidth="mainWrapperWidth"
        v-for="account in pagination.data"
        :key="account.id"
      />
      <!-- <Account :accountData="{}" :newAccount="true" :containerWidth="mainWrapperWidth" />
      <Account :accountData="{}" :newAccount="false" :containerWidth="mainWrapperWidth" /> -->
    </div>

    <Button
      v-show="pagination.canNext"
      customClasses="more-button"
      buttonType="default"
      type="text"
      dataTestId="moreButton"
      @click.prevent="paginationLoadMore"
    >
      <div class="more-button-context">
        {{ $t('propTrade.button.more') }}
        <div class="more-button-context-icon">
          <i class="el-icon-arrow-down"></i>
        </div>
      </div>
    </Button>
  </div>
</template>

<script>
import SearchBar from '@/components/propTrading/components/dashboard/SearchBar.vue';
import Account from '@/components/propTrading/components/dashboard/Account.vue';
import Session from '@/components/propTrading/components/dashboard/Session.vue';
import Button from '@/components/common/Button.vue';
import paginationMixin from '@/mixins/page/propTrade/pagination';
import {
  ACCOUNT_STAGE,
  ACCOUNT_STATUS,
  SESSION_STATUS,
  DEFAULT_TAB_CONTENT_SIZE,
  COMPONENT_NAME
} from '@/constants/propTrading';

export default {
  name: 'DashboardTabContent',
  props: {
    mainWrapperWidth: {
      type: String | Number,
      default: 0
    },
    excludeStatusOption: {
      type: Array,
      default: () => []
    },
    excludeStagesOption: {
      type: Array,
      default: () => []
    },
    showFilterStatus: {
      type: Boolean,
      default: true
    },
    showFilterStages: {
      type: Boolean,
      default: true
    },
    showFilterSession: {
      type: Boolean,
      default: false
    },
    afterStatusChangeHook: {
      type: Function,
      default: () => {}
    },
    afterStagesChangeHook: {
      type: Function,
      default: () => {}
    },
    afterSessionChangeHook: {
      type: Function,
      default: () => {}
    },
    data: {
      type: Array,
      default: () => []
    },
    sessionOrAccount: {
      type: String,
      default: () => COMPONENT_NAME.ACCOUNT
    }
  },
  components: { SearchBar, Account, Session, Button },
  mixins: [paginationMixin],
  data() {
    return {
      status: ACCOUNT_STATUS.ALL,
      stages: ACCOUNT_STAGE.ALL,
      session: SESSION_STATUS.ALL
    };
  },
  computed: {
    currentComponent() {
      return this.sessionOrAccount;
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    data: {
      handler(value) {
        this.initPagination(this.data, DEFAULT_TAB_CONTENT_SIZE);
      }
    }
  },
  methods: {
    async init() {},
    handleStatusChange(value) {
      this.status = value;
      this.afterStatusChangeHook(value);
    },
    handleStagesChange(value) {
      this.stages = value;
      this.afterStagesChangeHook(value);
    },
    handleSessionChange(value) {
      this.session = value;
      this.afterSessionChangeHook(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.searchbar-container {
  margin-bottom: 16px;
}

.account-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
}

.more-button {
  margin-top: 20px;
}
.more-button-context {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  &-icon {
    margin-left: 16px;
  }
}
</style>
