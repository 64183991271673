<template>
  <el-radio-button :label="payoutName" :data-testid="`pt_payout_${convertDataTestId(payoutName)}`">
    <div class="payout-radio-item-box" @click="cpsClick(payout)">
      <div class="payout-img" v-if="calShowImg">
        <img :src="ptpayoutLogo(payoutName)" :alt="payoutName" />
      </div>
      <div class="payout-name" v-if="calShowName">
        {{ langTranslate('deposit.default.channels.', payoutName) }}
      </div>
    </div>
  </el-radio-button>
</template>

<script>
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';
import { CRYPTO_USDT_TRC20_FAKE_NAME_KEY } from '@/constants/propTrading';

export default {
  name: 'payoutRadio',
  mixins: [cpsMixin],
  props: {
    payout: {
      type: Object,
      default: () => {}
    },
    showImg: {
      type: Boolean,
      default: true
    },
    showName: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      calShowImg: this.showImg,
      calShowName: this.showName
    };
  },
  computed: {
    payoutName() {
      return this.payout.label;
    }
  },
  methods: {
    ptpayoutLogo(value) {
      let imgFile = '';

      switch (value) {
        case 'cryptocurrencybitcoin':
          imgFile = 'bitcoin';
          break;
        case 'cryptocurrencyusdt':
          imgFile = 'usdterc20';
          break;
        case CRYPTO_USDT_TRC20_FAKE_NAME_KEY:
          imgFile = 'usdttrc20';
          break;
        case 'cryptocurrencyusdccps':
          imgFile = 'usdcerc20';
          break;
        case 'cryptocurrencyethcps':
          imgFile = 'eth';
          break;
        case 'Bitwallet':
          imgFile = 'bitwallet';
          break;
        case 'Fasapay':
          imgFile = 'fasapay';
          break;
        case 'SticPay':
        case 'sticpay':
        case 'Sticpay':
          imgFile = 'sticpay';
          break;
        case 'Perfect Money':
          imgFile = 'perfect_money';
          break;
        case 'Skrill':
          imgFile = 'skrill';
          break;
        case 'Neteller':
          imgFile = 'neteller';
          break;
        case 'EBuy':
          imgFile = 'ebuy';
          break;
        case 'Alipay':
          imgFile = 'alipay';
          break;
        case 'AirTM':
          imgFile = 'airtm';
          break;
      }

      // if not mapping img file, hide img and show name
      if (!imgFile) {
        this.calShowImg = false;
        this.calShowName = true;
        return;
      }

      return require(`@/assets/images/propTrading/${imgFile}.png`);
    },
    cpsClick(payout) {
      this.$emit('changePayoutDetail', payout);
    }
  }
};
</script>
<style lang="scss" scoped></style>
