<template>
  <div class="how-works" :class="[imgLang]">
    <div class="how-works-header">
      <div :style="{ flex: '1 1 90%' }">
        <div class="how-works-title">{{ $t('propTrade.howItWorks.title') }}</div>
        <div class="how-works-narrative">
          {{ $t('propTrade.howItWorks.desc') }}
          <a href="https://helpcenter.puxtrader.com/hc/en-gb" target="_blank" class="how-works-faq">FAQ</a>
        </div>
      </div>
      <div>
        <el-button
          type="text"
          class="how-works-more-btn"
          :class="{ 'is-show': showHowWorksContent, 'is-hide': !showHowWorksContent }"
          @click="toggleHowWorksContent"
        >
          <div class="how-works-more-btn-text">
            {{ $t('propTrade.button.more') }}<i class="el-icon-arrow-right view-right-icon"></i>
          </div>
        </el-button>
      </div>
    </div>
    <div class="how-works-content" v-if="showHowWorksContent">
      <StepBanner
        :data="howWorksData"
        :mobileWidth="stepBannerMobileWith"
        @changeStepBannerWidth="changeStepBannerWidth"
      >
        <template v-slot="{ item }">
          <div class="how-works-item">
            <div class="head">
              <div class="title-box">
                <div class="title" :ref="`howWorksItemTitle${item.index}`">
                  {{ item.title }}
                </div>
                <div class="title-img">
                  <!-- <img src="@/assets/images/propTrading/how_ works_title_img.png" /> -->
                </div>
              </div>
              <div class="bg-step-img">
                <img :src="require(`@/assets/images/propTrading/bg_${item.index}.png`)" />
              </div>
            </div>
            <ul class="content" :class="{ mobile: isMobile }">
              <li v-for="(desc, index) in item.desc" :key="index">{{ desc }}</li>
            </ul>
          </div>
        </template>
      </StepBanner>
    </div>
  </div>
</template>

<script>
import StepBanner from '@/components/common/StepBanner';

export default {
  name: 'HowWorks',
  components: { StepBanner },
  props: {
    immediateShowContent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showHowWorksContent: false,
      howWorksData: [
        {
          title: this.$t('propTrade.howItWorks.register'),
          desc: [
            this.$t('propTrade.howItWorks.registerDesc.1'),
            this.$t('propTrade.howItWorks.registerDesc.2'),
            this.$t('propTrade.howItWorks.registerDesc.3')
          ]
        },
        {
          title: this.$t('propTrade.howItWorks.trade'),
          desc: [
            this.$t('propTrade.howItWorks.tradeDesc.1'),
            this.$t('propTrade.howItWorks.tradeDesc.2'),
            this.$t('propTrade.howItWorks.tradeDesc.3'),
            this.$t('propTrade.howItWorks.tradeDesc.4')
          ]
        },
        {
          title: this.$t('propTrade.howItWorks.getFunded'),
          desc: [
            this.$t('propTrade.howItWorks.getFundedDesc.1'),
            this.$t('propTrade.howItWorks.getFundedDesc.2'),
            this.$t('propTrade.howItWorks.getFundedDesc.3')
          ]
        }
      ],
      textWrapList: {},
      isMobile: false
    };
  },
  computed: {
    imgLang() {
      return this.$store.state.common.lang;
    },
    stepBannerMobileWith() {
      let targetValue;
      switch (this.imgLang) {
        case 'fr_FR':
          targetValue = 994;
          break;
        case 'es':
          targetValue = 1010;
          break;
        case 'pt':
          targetValue = 1110;
          break;
        case 'ko':
          targetValue = 920;
          break;
        case 'ja':
          targetValue = 1090;
          break;
        case 'th':
          targetValue = 1220;
          break;
        case 'de':
          targetValue = 1160;
          break;
        case 'id':
          targetValue = 1096;
          break;
        case 'ar':
          targetValue = 960;
          break;
        case 'it':
          targetValue = 1016;
          break;
        default:
          targetValue = 830;
          break;
      }
      return targetValue;
    }
  },
  mounted() {
    this.showHowWorksContent = this.immediateShowContent;
  },
  methods: {
    toggleHowWorksContent() {
      this.showHowWorksContent = !this.showHowWorksContent;
    },
    changeStepBannerWidth(width, isMobile) {
      this.isMobile = isMobile;
      this.setItemTitleWrap(width, isMobile);
    },
    setItemTitleWrap(stepBannerWidth, isMobile) {
      const getTitleWidth = (textContent, fontSize) => {
        let colFontSize = typeof fontSize === 'number' ? `${fontSize}px` : fontSize;
        const newCanvas = document.createElement('canvas');
        const ctx = newCanvas.getContext('2d');
        ctx.font = `bold ${colFontSize} Avenir`;
        let text = ctx.measureText(textContent);
        return text.width;
      };

      let howWorksDataLength = this.howWorksData.length;
      let headWidth = isMobile ? stepBannerWidth : Math.round(stepBannerWidth / howWorksDataLength);
      this.$nextTick(() => {
        for (let i = 0; i < howWorksDataLength; i++) {
          let fontSize = isMobile ? 40 : 48;
          let titleWidth = getTitleWidth(this.howWorksData[i].title, fontSize);
          this.$refs[`howWorksItemTitle${i + 1}`].style.whiteSpace = titleWidth < headWidth ? 'nowrap' : 'unset';
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.how-works {
  width: 100%;
  border-radius: 10px;
  background: $black;
  padding: 16px 16px 24px 16px;
  margin-bottom: 24px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &-title {
    font-size: 18px;
    font-weight: bold;
    color: $pug-blue-secondary2;
    line-height: 1.5;
    @include rtl-sass-value(text-align, left, right);
  }
  &-faq {
    font-size: 14px;
    color: $pug-blue-secondary2;
    line-height: 1.5;
    @include rtl-sass-prop(padding-left, padding-right, 2px);
    &:hover {
      text-decoration: underline !important;
    }
  }
  &-narrative {
    font-size: 14px;
    color: $pug-white;
    line-height: 1.5;
  }
  &-more-btn {
    text-transform: capitalize;
    color: $pug-white;
    font-size: 14px;
    padding: 0;
    &-text {
      font-weight: bold;
      letter-spacing: 1px;
    }
    &.is-show {
      .view-right-icon {
        transform: rotate(-90deg);
        transition: transform linear 0.15s;
      }
    }
    &.is-hide {
      .view-right-icon {
        transform: rotate(0deg);
        transition: transform linear 0.15s;
      }
    }
  }
  &-content {
    padding: 24px 0 0 0;
  }
  &-item {
    color: $pug-white;
    position: relative;
    width: 100%;
    padding: 0 16px 12px 16px;
    .head {
      position: relative;
      padding-top: 48px;
    }
    .bg-step-img {
      width: 100px;
      position: absolute;
      left: 50%;
      top: 4px;
      transform: translateX(-50%);
    }
    .title-box {
      position: relative;
      margin: 0 auto;
    }
    .title {
      font-style: italic;
      font-size: 40px;
      font-weight: bold;
      position: relative;
      z-index: 2;
      text-shadow: -2px -2px 0px $black, -1px -2px 0px $black, 0px -2px 0px $black, 1px -2px 0px $black,
        2px -2px 0px $black, 2px -1px 0px $black, 2px 0px 0px $black, 2px 1px 0px $black, 2px 2px 0px $black,
        1px 2px 0px $black, 0px 2px 0px $black, -1px 2px 0px $black, -2px 2px 0px $black, -2px 1px 0px $black,
        -2px 0px 0px $black, -2px -1px 0px $black;
      margin-bottom: 20px;
      text-align: center;
    }
    .text-no-wrap {
      white-space: nowrap;
    }
    .title-img {
      width: 58px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
    .content {
      // width: 200px;
      min-width: 200px;
      width: 80%;

      text-align: center;
      word-break: break-word;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      margin: 0 auto;
    }

    ul.content {
      margin-top: 16px;
      text-align: left;
      list-style: disc;
      /deep/li {
        list-style: disc;
        @include rtl-sass-value(text-align, left, right);
      }

      &.mobile {
        max-width: 560px;
      }
    }
  }
  /deep/.el-carousel__container {
    height: 250px;
  }

  &.fr_FR,
  &.es,
  &.pt,
  &.th,
  &.de,
  &.id,
  &.ar,
  &.it {
    .how-works-item {
      .title {
        font-size: 30px;
      }
    }
  }
  &.ko,
  &.ja,
  &.th,
  &.ar {
    .how-works-item {
      ul.content {
        &.mobile {
          max-width: 360px;
        }
      }
    }
  }
  &.vi,
  &.it {
    .how-works-item {
      ul.content {
        &.mobile {
          max-width: 460px;
        }
      }
    }
  }
}
@media (max-width: 890px) {
  .how-works {
    position: relative;
    padding: 30px 20px;
    &-more-btn {
      position: absolute;
      right: 30px;
      top: 36px;
    }
    &-content {
      padding: 16px 0;
    }
    &-item {
      .title {
        font-size: 48px;
        margin-bottom: 8px;
      }

      .content {
        width: 90%;
      }
    }

    /deep/.el-carousel__container {
      height: 292px;
    }
  }
}
</style>
