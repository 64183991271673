<template>
  <div
    v-if="typeof calAlertType === 'number' && ~calAlertType"
    class="mention-container"
    :class="[{ mobile: isMobile }, calAlertTypeClass]"
  >
    <div class="mention-content">
      <div class="status-icon" :class="calAlertTypeClass"></div>
      <div class="text">
        {{ $t(`propTrade.history.status.${calAlertType}`) }}
      </div>
    </div>
    <div class="mention-action" v-show="calAlertType === METRICS_STATUS.FAILED">
      <Button
        customClasses="action-button"
        buttonType="default"
        :styles="{ padding: '4px 8px', 'font-size': '14px', 'font-weight': 900 }"
        dataTestId="join"
        @click.prevent="join"
      >
        <div>
          {{ $t('propTrade.button.join') }}
        </div>
      </Button>
    </div>
    <div class="mention-action" v-show="calAlertType === METRICS_STATUS.PROFIT_SPLITTED">
      <Button
        customClasses="action-button"
        buttonType="default"
        :styles="{ padding: '4px 8px', 'font-size': '14px', 'font-weight': 900 }"
        dataTestId="payout"
        @click.prevent="checkPoaAndShowPayoutDialog"
      >
        <div>
          {{ $t('propTrade.button.payout') }}
        </div>
      </Button>
    </div>

    <InteractiveDialog class="payout-dialog" :show.sync="showPayoutDialog">
      <template v-slot:body>
        <div class="reset-pwd-body">
          <div class="title">{{ $t('propTrade.payoutPage.howToProcessYourProfitSplit') }}</div>
          <div class="desc">{{ $t('propTrade.payoutPage.howToProcessYourProfitSplitDesc') }}</div>
          <div class="desc">{{ $t('propTrade.payoutPage.howToProcessYourProfitSplitDesc2') }}</div>
          <div class="desc">{{ $t('propTrade.payoutPage.howToProcessYourProfitSplitDesc3') }}</div>
          <div class="desc">{{ $t('propTrade.payoutPage.howToProcessYourProfitSplitDesc4') }}</div>
          <div class="desc">{{ $t('propTrade.payoutPage.howToProcessYourProfitSplitDesc5') }}</div>
        </div>
      </template>
      <template v-slot:btn>
        <div class="action-area">
          <Button
            customClasses="action-button"
            buttonType="default"
            :styles="{ padding: '8px', 'font-size': '16px', 'font-weight': 900 }"
            outline
            dataTestId="cancelPayoutDialog"
            @click="showPayoutDialog = false"
          >
            <div>
              {{ $t('propTrade.button.cancel') }}
            </div>
          </Button>

          <Button
            customClasses="action-button"
            buttonType="default"
            :styles="{ padding: '8px', 'font-size': '16px', 'font-weight': 900 }"
            dataTestId="gotoPayout"
            @click="gotoPayout"
          >
            <div>
              {{ $t('propTrade.payoutPage.submit') }}
            </div>
          </Button>
        </div>
      </template>
    </InteractiveDialog>

    <InfoDialog
      class="mention-dialog"
      :visible.sync="showPoaDialog"
      :title="$t('propTrade.payoutPage.poaDialog.title')"
      infoType="pending"
      @closCbDialog="showPoaDialog = false"
    >
      <div class="info-container">
        <div>{{ $t('propTrade.payoutPage.poaDialog.message') }}</div>
      </div>
      <template #actions>
        <Button buttonType="default" @click="verifyNow">
          {{ $t('common.button.verifyNow') }}
        </Button>
      </template>
    </InfoDialog>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import InteractiveDialog from '@/components/dialog/interactive/Dialog.vue';
import InfoDialog from '@/components/dialog/InfoDialog.vue';
import { METRICS_STATUS } from '@/constants/propTrading';
import { PROP_TRADING } from '@/constants/route';
import { statusConfig } from '@/constants/status';
import { mapState } from 'vuex';

export default {
  name: 'MentionMsg',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    alertType: {
      type: Number | String,
      default: METRICS_STATUS.DEFAULT
    },
    accountNumber: {
      type: Number | String,
      default: ''
    }
  },
  data() {
    return {
      METRICS_STATUS,
      showPayoutDialog: false,
      showPoaDialog: false
    };
  },
  components: { Button, InteractiveDialog, InfoDialog },
  computed: {
    ...mapState('register', ['poaAuditStatus']),
    calAlertType() {
      return this.alertType;
    },
    calAlertTypeClass() {
      if (this.calAlertType == METRICS_STATUS.PASSED) {
        return 'check';
      } else if (this.calAlertType == METRICS_STATUS.FAILED) {
        return 'info';
      } else if (this.calAlertType == METRICS_STATUS.VERIFYING) {
        return 'check';
      } else if (this.calAlertType == METRICS_STATUS.PROFIT_PENDING) {
        return 'processing';
      } else if (this.calAlertType == METRICS_STATUS.PROFIT_SPLITTED) {
        return 'processing';
      }

      return '';
    }
  },
  methods: {
    join() {
      this.$router.push({ path: PROP_TRADING });
    },
    async gotoPayout() {
      if (this.accountNumber) {
        await this.$router
          .push({ name: 'propTradingPayout', params: { accountNumber: encodeURIComponent(this.accountNumber) } })
          .catch(() => {});
      }
    },
    checkPoaAndShowPayoutDialog() {
      if (this.poaAuditStatus !== statusConfig.completed) {
        this.showPoaDialog = true;
      } else {
        this.showPayoutDialog = true;
      }
    },
    async verifyNow() {
      await this.$router.push({ name: 'register' }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.mention-container {
  margin-top: 16px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: $pug-green-success-bg;
  &.info {
    background-color: $pug-pt-red-bg;
  }
  &.processing {
    background-color: $pug-blue-secondary;
  }

  .mention-content {
    display: flex;
    align-items: center;
    .status-icon {
      flex: 0 0 24px;
      margin-right: 12px;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      &.check {
        background-image: url('~@/assets/images/propTrading/check.png');
      }
      &.info {
        background-image: url('~@/assets/images/propTrading/info.png');
      }
      &.processing {
        background-image: url('~@/assets/images/propTrading/processing.png');
      }
    }
    .text {
      line-height: 24px;
    }
  }

  .mention-action {
    .action-button {
      min-width: 96px;
    }
  }

  &.mobile {
    padding: 18px;
    flex-direction: column;
    justify-content: center;
    .mention-content {
      width: 100%;
    }
    .mention-action {
      width: 100%;
      margin-top: 12px;
    }
  }
}

/deep/.payout-dialog {
  position: absolute;
  .el-dialog__wrapper .el-dialog {
    max-width: 480px;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    // padding: 45px 55px 35px !important;
    text-align: left;
    .dialog-body {
      padding: 0;
    }

    .title {
      font-weight: 900;
      font-size: 20px;
      margin-bottom: 12px;
      color: $pug-black;
    }
    .desc {
      overflow-y: scroll;
      margin-top: 20px;
    }
    .account-number {
      font-weight: 400;
      font-size: 14px;
      color: $pug-blue-hover;
      margin-bottom: 24px;
    }
    .reset-pwd-body {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      .reset-form-item {
        margin-bottom: 20px;
      }
    }
    .forgot-pwd {
      display: flex;
      justify-content: flex-end;
      color: $pug-blue-hover;
      text-decoration: underline;
      cursor: pointer;
    }
    .action-area {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      margin-bottom: 16px;
      .action-button {
        height: 45px;
      }
    }
  }
}

/deep/.mention-dialog {
  position: absolute;
}
</style>
