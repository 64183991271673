<template>
  <div class="pt-cps-withdraw-container">
    <CpsWithdraw ref="TransferFormWrapper" v-bind="$attrs" v-on="$listeners" />
    <PtFeeTip
      :withdrawalType="$attrs.withdrawalType"
      :currency="$attrs.currency"
      :withdrawalAmount="$attrs.amount"
      :styleType="2"
    />
  </div>
</template>

<script>
import CpsWithdraw from '@/components/cps/CpsWithdraw.vue';
import PtFeeTip from '@/components/propTrading/components/payout/PtFeeTip.vue';

export default {
  name: `PtCpsWithdraw`,
  components: { CpsWithdraw, PtFeeTip }
};
</script>

<style lang="scss" scoped>
.pt-cps-withdraw-container /deep/ {
  .alert-info {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    & > .alert_info_icon {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
