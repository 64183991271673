// For PropTrade use only
export default class {
  constructor(data, limit) {
    this.data = data
    this.limit = limit
    this.total = data.length
    this.pageIndex = 0
    this.canNext = true
  }

  more() {
    if (!this.canNext) return []

    const start = this.pageIndex * this.limit
    const end = start + this.limit

    this.pageIndex++

    this.canNext = end < this.total

    return this.data.slice(start, end)
  }
}
