<template>
  <TabContent
    :mainWrapperWidth="mainWrapperWidth"
    :afterStatusChangeHook="handleStatusChange"
    :afterStagesChangeHook="handleStagesChange"
    :data="sortedData"
  />
</template>

<script>
import { apiGetDashBoardAccountList } from '@/resource';
import TabContent from '@/components/propTrading/components/dashboard/TabContent';
import fieldsExist from '@/util/fieldsExist';
import {
  ACCOUNT_STATUS_ORDER,
  ACCOUNT_STAGE,
  ACCOUNT_STATUS,
  SESSION_STATUS,
  DEFAULT_TAB_CONTENT_REFRESH_INTERVAL
} from '@/constants/propTrading';
import setIntervalFn from '@/util/setIntervalFn';

export default {
  name: 'DashboardAccountsTabContent',
  props: {
    mainWrapperWidth: {
      type: String | Number,
      default: 0
    }
  },
  components: { TabContent },
  data() {
    return {
      cache: {},
      data: [],
      status: ACCOUNT_STATUS.ALL,
      stages: ACCOUNT_STAGE.ALL,
      autoRefetchAPI: null,
      restartAutoRefetchAPI: null,
      refetchStopFn: null,
      sessionForShowProfitSplitRemider: ''
    };
  },
  computed: {
    sortedData() {
      return this.data.sort((a, b) => {
        const aStatus = a.status;
        const bStatus = b.status;
        const aStage = a.accountStage;
        const bStage = b.accountStage;

        return bStage - aStage || ACCOUNT_STATUS_ORDER[aStatus] - ACCOUNT_STATUS_ORDER[bStatus];
      });
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.refetchStopFn?.();
  },
  methods: {
    async init() {
      this.sessionForShowProfitSplitRemider = sessionStorage.getItem('pt-remider-setting') || '';
      this.data = await this.getDashBoardAccountList();

      const { intervalFn, stop, restart } = setIntervalFn(async () => {
        this.data = await this.getDashBoardAccountList();
      }, DEFAULT_TAB_CONTENT_REFRESH_INTERVAL);

      this.autoRefetchAPI = intervalFn;
      this.refetchStopFn = stop;
      this.restartAutoRefetchAPI = restart;

      this.checkHasActiveAccounts(this.data);

      this.autoRefetchAPI();
    },
    async getDashBoardAccountList() {
      const id = `${this.status}${this.stages}`;

      if (this.cache[id]) {
        return [];
      }

      this.cache[id] = apiGetDashBoardAccountList(this.status, this.stages);

      const result = await this.cache[id];

      this.cache = {};

      if (result.data.code === 0) {
        const data = result.data.data.accountList;

        // profit split reminder
        if (
          result.data.data?.showProfitSpiltReminder &&
          result.data.data?.profitSplitDate &&
          this.sessionForShowProfitSplitRemider !== 'true'
        ) {
          setTimeout(() => {
            this.showProfitSplitNotification(result.data.data?.profitSplitDate);
          }, 600);
        }

        // refactor or removed
        if (!Array.isArray(data)) {
          throw new Error('data is not array');
        }

        const isAllFieldExist = data.every(item =>
          fieldsExist(item, [
            'accountStage',
            'platform',
            // 'server',
            'statusDate',
            'equity',
            'profitRatio',
            'sessionNumber',
            'currency',
            'status',
            'isNew'
          ])
        );

        if (!isAllFieldExist) {
          throw new Error('data is not all field exist');
        }

        return data;
      }

      return [];
    },
    async handleStatusChange(value) {
      this.status = value;
      this.data = await this.getDashBoardAccountList();
      this.restartAutoRefetchAPI?.();
      this.checkHasActiveAccounts(this.data);
    },
    async handleStagesChange(value) {
      this.stages = value;
      this.data = await this.getDashBoardAccountList();
      this.restartAutoRefetchAPI?.();
    },
    showProfitSplitNotification(date) {
      const h = this.$createElement;
      this.$notify({
        title: this.$t('propTrade.notification.profitSplit.title'),
        message: h('div', null, [
          h(
            'div',
            {
              style: 'position: absolute;top:14px;right:12px;cursor:pointer;',
              class: 'el-notification__closeBtn el-icon-close',
              on: {
                click: () => {
                  sessionStorage.setItem('pt-remider-setting', 'true');
                  this.$notify.closeAll();
                }
              }
            },
            null
          ),
          h('div', null, this.$t('propTrade.notification.profitSplit.context', { date }))
        ]),
        showClose: false,
        duration: 10 * 1000,
        customClass: 'pt-remider'
      });
    },
    checkHasActiveAccounts(value) {
      const count = value.some(item => item.status === 0);
      if (count) {
        this.$store.commit('propTrading/SET_HAS_ACTIVE_ACCOUNTS', true);
      } else {
        this.$store.commit('propTrading/SET_HAS_ACTIVE_ACCOUNTS', false);
      }
    }
  }
};
</script>
