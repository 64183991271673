<template>
  <div class="account-container" :class="{ 'new-account': calNewAccount, 'mobile-style': containerWidth <= 950 }">
    <div class="account-header">
      <div class="account-left">
        <AccountIcon :accountStage="accountData.accountStage" :accountStatus="accountData.status" />
        <div class="account-content">
          <span>{{ $t(PAYMENT_STATUS_REV[accountData.status]) }}</span>
        </div>
      </div>
    </div>
    <div class="account-text">
      <div class="account-text-main">
        <span class="amount">{{ accountData.currency | currencyToSymbol }}{{ formateAmount(accountData.equity) }}</span>
        <span class="currency">{{ accountData.currency }}</span>
      </div>
      <div class="session-number">{{ accountData.sessionNumber }}</div>
    </div>
    <div class="account-footer">
      <div class="account-footer-detail">
        <div class="account-footer-item single">{{ statusDesc }} {{ accountData.statusDate }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import TextInput from '@/components/common/TextInput.vue';
import InfoDialog from '@/components/dialog/InfoDialog.vue';
import AccountIcon from '@/components/propTrading/components/dashboard/AccountIcon.vue';
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import InteractiveDialog from '@/components/dialog/interactive/Dialog.vue';
import splitThousands from '@/util/splitThousands';
import { SESSION_STATUS_REV, PAYMENT_STATUS, PAYMENT_STATUS_REV } from '@/constants/propTrading';
import { apiResetPassword, apiGetSessionHistory } from '@/resource';
import { isSuccess } from '@/util/restApi';
import { rsa } from '@/util/encrypt';

export default {
  name: 'DashboardAccount',
  props: {
    // dropdownMenu
    newAccount: {
      type: Boolean,
      default: false
    },
    accountData: {
      type: Object,
      default: () => ({})
    },
    containerWidth: {
      type: Number | String,
      default: 0
    }
  },
  components: { Button, AccountIcon, InfoDialog, TextInput, GeneralDialog, InteractiveDialog },
  data() {
    return {
      SESSION_STATUS_REV,
      PAYMENT_STATUS,
      PAYMENT_STATUS_REV,
      showHistory: false,
      historyData: [],
      resetPwdForm: {
        currentPwd: '',
        newPwd: '',
        confirmNewPwd: ''
      }
    };
  },
  computed: {
    statusDesc() {
      if (
        [PAYMENT_STATUS.SUBMITTED, PAYMENT_STATUS.EXPIRED, PAYMENT_STATUS.MISMATCHED].includes(this.accountData.status)
      ) {
        return `${this.$t('propTrade.dashboard.applicationTime')} : `;
      }

      if ([PAYMENT_STATUS.SUCCESS].includes(this.accountData.status)) {
        return `${this.$t('propTrade.dashboard.paymentTime')} : `;
      }

      if ([PAYMENT_STATUS.FAILED].includes(this.accountData.status)) {
        return `${this.$t('propTrade.dashboard.failedTime')} : `;
      }

      return '';
    },

    calNewAccount() {
      return this.newAccount;
    },
    calAccountData() {
      return { ...this.accountData };
    },
    dropdownList() {
      const defaultMenu = [
        {
          title: this.$t('propTrade.button.resetPassword'),
          value: 'resetPassword'
        },
        {
          title: this.$t('propTrade.button.payout'),
          value: 'payout'
        },
        {
          title: this.$t('propTrade.button.history'),
          value: 'history'
        }
      ];

      return defaultMenu;
    },
    drawerWidth() {
      if (this.containerWidth <= 768) {
        return '100%';
      } else {
        return '50%';
      }
    },
    drawerColumnWidth() {
      if (this.containerWidth <= 768) {
        return '120';
      } else {
        return '240';
      }
    },
    resetFormRules() {
      return {
        currentPwd: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('propTrade.resetPassword.currentPwd')
            }),
            trigger: 'blur'
          }
        ],
        newPwd: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('propTrade.resetPassword.newPwd')
            }),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (this.resetPwdForm?.currentPwd && value === this.resetPwdForm.currentPwd) {
                callback(new Error(this.$t('propTrade.resetPassword.newPwdErrorMsg')));
              }
              callback();
            },
            trigger: 'blur'
          }
        ],
        confirmNewPwd: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('propTrade.resetPassword.confirmNewPwd')
            }),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (this.resetPwdForm?.newPwd && value !== this.resetPwdForm.newPwd) {
                callback(new Error(this.$t('propTrade.resetPassword.confirmNewPwdErrorMsg')));
              }
              callback();
            },
            trigger: 'blur'
          }
        ]
      };
    }
  },
  methods: {
    formateAmount(amount) {
      return splitThousands(amount, 2);
    }
  }
};
</script>

<style lang="scss" scoped>
.account-container {
  flex: 0 0 49%;
  height: 160px;
  padding: 16px;
  background-color: $pug-white;
  border-radius: 10px;

  &.new-account {
    border: 0.5px solid $pug-blue-opacity-40;
    box-shadow: 2px 2px 4px 0px #2163bc80;
  }
  &.mobile-style {
    flex: 0 0 100%;
  }
}

.account-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-tag {
  width: 100%;
  border-radius: 14px;
  height: 20px;
  // max-width: 112px;
  min-width: 80px;
  background-color: $pug-blue;
  color: $pug-white;
  text-align: center;
  line-height: 20px;
  margin-right: 16px;
}

.account-left {
  width: 100%;
  display: flex;
  align-items: center;
  .account-content {
    width: 100%;
    display: flex;
    align-items: center;
    &-devider {
      margin: 0 8px;
    }
  }
}
.account-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  .new-tag {
    color: $pug-white;
    border-radius: 2px;
    background-color: $pug-red;
    font-size: 12px;
    padding: 4px;
  }

  .account-more-icon {
    transform: rotate(90deg);
    cursor: pointer;
    font-size: 20px;
    color: $pug-gray-dot;
  }
}
/deep/ .el-badge__content {
  background-color: $pug-red !important;
}
.menu-item {
  display: flex;
  align-items: center;
  width: 120px;
  padding: 4px 0;
}
.menu-dot {
  border-radius: 50%;
  background-color: $pug-red;
  width: 8px;
  height: 8px;
  margin-left: 8px;
}

.account-text {
  margin: 16px 0 8px 0;
  &-main {
    margin-bottom: 12px;
    display: flex;
    align-items: flex-end;
  }
  .amount {
    margin-right: 12px;
    font-weight: bolder;
    font-size: 22px;
    line-height: 1.2;
    color: $pug-black;
  }
  .currency {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: $pug-black;
  }
  .profit {
    color: $pug-profit-green;
    height: 18px;
    &.minus {
      color: $pug-red;
    }
  }
  .session-number {
    @include rtl-sass-value(text-align, left, right);
  }
}

.account-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-detail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-item {
      display: flex;
      align-items: center;
      color: $pug-gray-title;
      font-size: 14px;
      font-weight: 400;
      padding-right: 16px;
      border-right: 1px solid;
      margin-left: 16px;

      &:nth-child(1) {
        margin-left: 0;
      }
      &:nth-last-child(1) {
        border-right: none;
      }
      &.single {
        padding-right: 0px;
        border-right: none;
        margin-left: 0px;
      }
    }
  }

  .view-metrics-button {
    min-width: 92px;
    width: fit-content;
    height: 30px;
  }
}

.drawer-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: $pug-black;
  &-main {
    font-weight: 900;
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 16px;
  }
  &-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
  }
}

/deep/ .reset-pwd-dialog {
  .el-dialog__wrapper .el-dialog {
    max-width: 480px;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    text-align: left;
    .dialog-body {
      padding: 0;
    }

    .title {
      font-weight: 900;
      font-size: 20px;
      margin-bottom: 12px;
      color: $pug-black;
    }
    .account-number {
      font-weight: 400;
      font-size: 14px;
      color: $pug-blue-hover;
      margin-bottom: 24px;
    }
    .reset-pwd-body {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      .reset-form-item {
        margin-bottom: 20px;
      }
    }
    .action-area {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      margin-bottom: 16px;
      .action-button {
        height: 45px;
      }
    }
  }
}
.cs-chat-container {
  margin-top: 36px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.cs-chat-image {
  width: 24px !important;
  height: 24px !important;
  margin-right: 8px;
}
.cs-chat-desc {
  color: $pug-blue-hover;
  font-size: 16px;
  font-weight: 400;
}
/deep/ .reset-info {
  .el-dialog__body {
    padding: 8px 60px 24px 60px !important;
  }
}
.info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/deep/ .history-container {
  .el-drawer__header {
    align-items: flex-start;
    .el-drawer__close-btn {
      color: $pug-black;
    }
  }
  .el-table {
    .el-table__header-wrapper {
      .el-table__header {
        border-bottom: 1px solid $pug-pray-border;
        th {
          color: $pug-black;
          background-color: $pug-white;
          height: 60px;
          text-align: left;

          .cell {
            padding: 22px 28px;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.2;
          }
        }
      }
    }

    .el-table__body-wrapper .el-table__body .el-table__row {
      td {
        border-bottom: 2px solid $pug-pray-border;
        .cell {
          padding: 0 0 0 24px;
          text-align: left;
          font-size: 14px;
          font-weight: 400;
          line-height: 2;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .account-container {
    min-width: 348px;
    width: 100%;
    height: 228px;
  }

  .status-tag {
    margin-right: 8px;
  }
  .account-right {
    gap: 0px;
  }

  .account-text {
    margin: 24px 0;
  }

  .account-footer {
    flex-direction: column;
    gap: 16px;
    &-detail {
      width: 100%;
      &-item {
        margin-left: 8px;
        padding-right: 8px;
      }
    }

    .view-metrics-button {
      width: 100%;
      height: 36px;
    }
  }

  /deep/ .reset-pwd-dialog {
    .el-dialog__body {
      .action-area {
        flex-direction: column;
        flex-direction: column-reverse;
        gap: 12px;
        .action-button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 475px) {
  /deep/ .reset-pwd-dialog {
    .el-dialog__wrapper .el-dialog {
      top: 50%;
      transform: translateY(-50%);
    }
    .el-dialog__body {
      padding: 30px 20px 12px 20px !important;
    }
  }
}

@media (max-width: 430px) {
  .account-left {
    .account-content {
      &-devider {
        margin: 0 2px;
      }
    }
  }
}
</style>
