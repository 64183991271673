<template>
  <div
    class="main-wrapper"
    v-resizeObserver="{
      openResize: true,
      handler: observerCallBack
    }"
  >
    <div>
      <div class="main-flex">
        <div class="main-title">
          <h1 class="main-text">{{ $t('propTrade.mainTitle') }}</h1>
        </div>
        <div class="main-puxtrader">
          <a
            v-if="this.$store.state.propTrading.hasActiveAccounts"
            :href="'https://trade.puxtrader.com/'"
            target="_blank"
            class="main-puxtrader-link main-puxtrader-is-active"
            >{{ $t('propTrade.puxtraderlink') }}</a
          >
          <span v-else class="main-puxtrader-link main-puxtrader-no-active">{{ $t('propTrade.puxtraderlink') }}</span>
        </div>
      </div>
      <div class="top">
        <HowWorks />
        <Reminder :containerWidth="mainWrapperWidth" v-if="isShowReminder" />
      </div>
      <div>
        <el-tabs v-model="activeTab" class="dashboard-tabs" :class="{ 'single-tab': false, 'ar':  lang === 'ar'}">
          <el-tab-pane :label="$t('propTrade.dashboard.accounts')" name="accounts">
            <AccountsTabContent :mainWrapperWidth="mainWrapperWidth" />
          </el-tab-pane>
          <el-tab-pane :label="$t('propTrade.dashboard.paymentStatus')" name="PaymentStatus">
            <PendingOrFailedTabContent :mainWrapperWidth="mainWrapperWidth" />
            <!-- <TabContent :mainWrapperWidth="mainWrapperWidth" :showFilterStages="false" /> -->
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import Selection from '@/components/common/Selection.vue';
import TabContent from '@/components/propTrading/components/dashboard/TabContent';
import AccountsTabContent from '@/components/propTrading/components/dashboard/AccountsTabContent';
import PendingOrFailedTabContent from '@/components/propTrading/components/dashboard/PendingOrFailedTabContent';
import HowWorks from '../components/HowWorks.vue';
import Reminder from '../components/Reminder.vue';
import AccountIcon from '@/components/propTrading/components/dashboard/AccountIcon.vue';
import InfoPopover from '@/components/common/InfoPopover.vue';
import splitThousands from '@/util/splitThousands';
import { statusConfig } from '@/constants/status';
import { mapState } from 'vuex';

export default {
  name: 'PropTradingDashBoard',
  components: {
    Button,
    Selection,
    TabContent,
    HowWorks,
    Reminder,
    AccountIcon,
    InfoPopover,
    AccountsTabContent,
    PendingOrFailedTabContent
  },
  data() {
    return {
      mainWrapperWidth: 0,
      activeTab: 'accounts',
      selecedAccount: '',
      mobileShowDetail: false
    };
  },
  computed: {
    routerAccountId() {
      return this.$route.params.accountId;
    },
    tableData() {
      return [
        {
          goals: this.$t('propTrade.metricsPage.tradingGoalsFiels.minTradingDay'),
          amount: '',
          status: true
        },
        {
          goals: this.$t('propTrade.metricsPage.tradingGoalsFiels.maxDailyLoss'),
          amount: '$00000.00 (0.00%)',
          status: true
        },
        {
          goals: this.$t('propTrade.metricsPage.tradingGoalsFiels.maxLoss'),
          amount: '$00000.00 (0.00%)',
          status: false
        },
        {
          goals: this.$t('propTrade.metricsPage.tradingGoalsFiels.profitTarget'),
          amount: '$00000.00 (0.00%)',
          status: true
        }
      ];
    },
    topBannerMobileMode() {
      return this.mainWrapperWidth <= 730;
    },
    isShowDetail() {
      if (!this.topBannerMobileMode) return true;
      return this.mobileShowDetail;
    },
    tableStatusFieldWidth() {
      if (this.topBannerMobileMode) return 80;
      return this.mainWrapperWidth >= 900 ? 160 : 120;
    },
    ...mapState('register', ['poiAuditStatus', 'poaAuditStatus']),
    isShowReminder() {
      return this.poiAuditStatus !== statusConfig.completed || this.poaAuditStatus !== statusConfig.completed;
    },
    lang() {
      return this.$store.state.common.lang;
    }
  },
  mounted() {
    this.init();
    let { account, session } = this.$store.state.propTrading;

    if (account) {
      this.activeTab = 'accounts';
    } else {
      this.activeTab = 'pendingFailed';
    }
  },
  methods: {
    async init() {},
    observerCallBack(width) {
      this.mainWrapperWidth = width;
    },
    formateAmount(amount) {
      return splitThousands(amount, 2);
    }
  }
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding: 48px 64px;
  max-width: 1440px;
  margin: auto;
}

.main-title {
  margin-bottom: 24px;
}
.main-text {
  font-weight: 900;
  font-size: 22px;
  line-height: 34px;
}

.main-puxtrader {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &-link {
    font-size: 14px;
    line-height: 1.5;
  }

  &-no-active {
    color: $pug-gray-pending;
  }

  &-is-active {
    color: $pug-blue-hover;

    &:hover {
      text-decoration: underline;
    }
  }
}

.main-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  margin-top: 10px;
}

.card-panel {
  padding: 30px 24px;
  margin-bottom: 16px;
  background-color: $pug-white;
  border-radius: 10px;
  &.mobile {
    padding: 30px 16px;
  }

  &-main {
    font-weight: 900;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  &-desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
  }
}

.top {
  margin-bottom: 16px;
}

/deep/ .dashboard-tabs {
  .el-tabs__item {
    font-weight: 900;
    font-size: 18px;
    color: $pug-gray;
    &.is-active {
      color: $pug-blue-hover;
    }
  }
  .el-tabs__active-bar {
    height: 4px;
    border-radius: 2px;
    background-color: $pug-blue-hover;
  }

  &.single-tab {
    .el-tabs__item {
      color: $pug-black;
      &.is-active {
        color: $pug-black;
      }
    }
    .el-tabs__active-bar {
      display: none;
    }
  }
  &.ar {
    .el-tabs__nav-scroll {
      direction: ltr;
    }
  }
}

@media (max-width: 1024px) {
  .main-wrapper {
    padding: 48px 36px;
  }
}

@media (max-width: 764px) {
  .main-wrapper {
    padding: 40px 16px 30px 16px;
  }
}

@media (min-width: 576px) {
  .main-flex {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .main-title {
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
  .main-puxtrader {
    margin-bottom: 10px;
  }
}
</style>
