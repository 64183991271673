<template>
  <div class="main-head" :class="{ mobile: mobile }">
    <div class="filter-container">
      <div class="row info-container">
        <InfoPopover v-if="calShowFilterStatus" :popoverWidth="385">
          <template v-slot:popoverContent>
            <div class="info-content">
              <ol type="1">
                <li v-for="(info, index) in infoContents" :key="index">{{ info }}</li>
              </ol>
            </div>
          </template>
        </InfoPopover>
        <Selection
          v-if="calShowFilterStatus"
          v-model="filterStatus"
          :options="filterStatusOptions"
          customClasses="filter-select"
          selectType="underline"
          filterable
          size="small"
          dataTestId="filterStatus"
        />
      </div>
      <Selection
        v-if="calShowFilterStages"
        v-model="filterStages"
        :options="filterStagesOptions"
        customClasses="filter-select"
        selectType="underline"
        filterable
        size="small"
        dataTestId="filterStages"
      />
      <div class="row info-container">
        <InfoPopover v-if="showFilterSession" :popoverWidth="385">
          <template v-slot:popoverContent>
            <div class="info-content">
              <ol type="1">
                <li v-for="(info, index) in paymentStatusInfoContents" :key="index">{{ info }}</li>
              </ol>
            </div>
          </template>
        </InfoPopover>

        <Selection
          v-if="showFilterSession"
          v-model="filterSession"
          :options="filterSessionOptions"
          customClasses="filter-select"
          selectType="underline"
          filterable
          size="small"
          dataTestId="filterSession"
        />
      </div>
    </div>
    <Button
      customClasses="create-account-button"
      buttonType="default"
      :styles="{ padding: '10px', 'font-size': '12px', 'font-weight': 500 }"
      @click="createNewAccount"
      dataTestId="createNewAccount"
    >
      <div class="create-account-button-text">
        <div class="create-account-button-icon">
          <i class="el-icon-plus"></i>
        </div>
        <div>{{ $t('propTrade.button.createNewAccount') }}</div>
      </div>
    </Button>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import Selection from '@/components/common/Selection.vue';
import InfoPopover from '@/components/common/InfoPopover';
import { ACCOUNT_STAGE, ACCOUNT_STATUS, SESSION_STATUS, PAYMENT_STATUS } from '@/constants/propTrading';

export default {
  name: 'DashboardSearchbar',
  components: { Button, Selection, InfoPopover },
  props: {
    excludeStatusOption: {
      type: Array,
      default: () => []
    },
    excludeStagesOption: {
      type: Array,
      default: () => []
    },
    showFilterStatus: {
      type: Boolean,
      default: true
    },
    showFilterStages: {
      type: Boolean,
      default: true
    },
    showFilterSession: {
      type: Boolean,
      default: false
    },
    containerWidth: {
      type: Number | String,
      default: 0
    }
  },
  data() {
    return {
      filterStatus: ACCOUNT_STATUS.ALL,
      filterStages: ACCOUNT_STAGE.ALL,
      filterSession: PAYMENT_STATUS.ALL
    };
  },
  computed: {
    infoContents() {
      let res = [];
      Array.from({ length: 5 }, (item, index) => {
        res.push(this.$t(`propTrade.dashboard.accountStatusInfo.${index + 1}`));
      });
      return res;
    },
    paymentStatusInfoContents() {
      let res = [];
      Array.from({ length: 3 }, (item, index) => {
        res.push(this.$t(`propTrade.dashboard.PaymentStatusInfo.${index + 1}`));
      });
      return res;
    },
    filterStatusOptions() {
      const defaultOptions = [
        {
          label: this.$t(`propTrade.statusContext.allStatus`),
          value: ACCOUNT_STATUS.ALL
        },
        {
          label: this.$t(`propTrade.statusContext.active`),
          value: ACCOUNT_STATUS.ACTIVE
        },
        {
          label: this.$t(`propTrade.statusContext.payoutPending`),
          value: ACCOUNT_STATUS.PAYOUT_PENDING
        },
        {
          label: this.$t(`propTrade.statusContext.verifying`),
          value: ACCOUNT_STATUS.VERIFYING
        },
        {
          label: this.$t(`propTrade.statusContext.passed`),
          value: ACCOUNT_STATUS.PASSED
        },
        {
          label: this.$t(`propTrade.statusContext.failed`),
          value: ACCOUNT_STATUS.FAILED
        },
        {
          label: this.$t(`propTrade.statusContext.processing`),
          value: ACCOUNT_STATUS.PROCESSING
        }
      ];

      return this.excludeStatusOption.length
        ? defaultOptions.filter(item => !this.excludeStatusOption.includes(item))
        : defaultOptions;
    },
    filterStagesOptions() {
      const defaultOptions = [
        {
          label: this.$t(`propTrade.accountStageContext.allStages`),
          value: ACCOUNT_STAGE.ALL
        },
        {
          label: this.$t(`propTrade.accountStageContext.liveTrading`),
          value: ACCOUNT_STAGE.LIVE_TRADING
        },
        {
          label: this.$t(`propTrade.accountStageContext.liveTradingPlus`),
          value: ACCOUNT_STAGE.LIVE_TRADING_PLUS
        },
        {
          label: this.$t(`propTrade.accountStageContext.evaluation`),
          value: ACCOUNT_STAGE.EVALUATION
        },
        {
          label: this.$t(`propTrade.accountStageContext.evaluationPlus`),
          value: ACCOUNT_STAGE.EVALUATION_PLUS
        },
        {
          label: this.$t(`propTrade.accountStageContext.challenge`),
          value: ACCOUNT_STAGE.CHALLENGE
        },
        {
          label: this.$t(`propTrade.accountStageContext.challengePlus`),
          value: ACCOUNT_STAGE.CHALLENGE_PLUS
        }
      ];

      return this.excludeStagesOption.length
        ? defaultOptions.filter(item => !this.excludeStagesOption.includes(item))
        : defaultOptions;
    },
    filterSessionOptions() {
      const defaultOptions = [
        {
          label: this.$t(`propTrade.sessionContext.all`),
          value: PAYMENT_STATUS.ALL
        },
        {
          label: this.$t(`propTrade.sessionContext.Processing`),
          value: PAYMENT_STATUS.PROCESSING
        },
        // {
        //   label: this.$t(`propTrade.sessionContext.submitted`),
        //   value: PAYMENT_STATUS.SUBMITTED
        // },
        // {
        //   label: this.$t(`propTrade.sessionContext.expired`),
        //   value: PAYMENT_STATUS.EXPIRED
        // },
        // {
        //   label: this.$t(`propTrade.sessionContext.mismatch`),
        //   value: PAYMENT_STATUS.MISMATCHED
        // },
        {
          label: this.$t(`propTrade.sessionContext.success`),
          value: PAYMENT_STATUS.SUCCESS
        },
        {
          label: this.$t(`propTrade.sessionContext.fail`),
          value: PAYMENT_STATUS.FAILED
        }
      ];

      return defaultOptions;
    },
    calShowFilterStatus() {
      return this.showFilterStatus;
    },
    calShowFilterStages() {
      return this.showFilterStages;
    },
    calShowFilterSession() {
      return this.showFilterSession;
    },
    mobile() {
      return this.containerWidth <= 700;
    }
  },
  watch: {
    filterStatus: {
      immediate: true,
      handler(newValue) {
        this.$emit('statusChange', newValue);
      }
    },
    filterStages: {
      immediate: true,
      handler(newValue) {
        this.$emit('stagesChange', newValue);
      }
    },
    filterSession: {
      immediate: true,
      handler(newValue) {
        this.$emit('sessionChange', newValue);
      }
    }
  },
  methods: {
    async createNewAccount() {
      await this.$router.push({ name: 'propTradingCreateAccount' }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.main-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $pug-white;
  padding: 20px 18px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.filter-container {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}
.info-container {
  @include rtl-sass-value(text-align, left, right);
}
.filter-select {
  width: 140px;
}
.create-account-button {
  width: fit-content;
  height: 40px;
  // max-width: 164px;

  &-icon {
    border-radius: 50%;
    // margin-right: 8px;
    @include rtl-sass-prop(margin-right, margin-left, 8px);
  }
  &-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// mobile style
.main-head.mobile {
  flex-direction: column;
  gap: 12px;

  .filter-container {
    width: 100%;
  }
  .filter-select {
    width: 100%;
  }

  .create-account-button {
    max-width: unset;
    width: 100%;
  }
}

/deep/.info-popover-wrapper {
  @include rtl-sass-prop(padding-left, padding-right, 0);
}

.info-content {
  @include rtl-sass-prop(padding-left, padding-right, 16px);
  ol {
    list-style: auto !important;
  }
  li {
    list-style: auto !important;
    @include rtl-sass-prop(padding-left, padding-right, 8px);
    @include rtl-sass-value(text-align, left, right);
    margin-bottom: 6px;
    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }
}
</style>
