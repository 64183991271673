<template>
  <div class="form">
    <component :is="currentComponent" ref="child"></component>
  </div>
</template>

<script>
import { apiProcess_old } from '@/resource/register';

export default {
  props: { showPageNumber: Number },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    registerStep() {
      return this.$store.state.common.registerStep;
    },
    currentComponent() {
      let path = '';
      if (this.showPageNumber == 1) path = 'register/UserDetail';
      else if (this.showPageNumber == 2) path = 'register/UserAddress';

      return () => this.$config.componentLoader(this.regulator, path).component;
    }
  },
  mounted() {
    this.$store.dispatch('register/actionCountries', this.lang);
    this.$store.dispatch('register/actionProvinces', this.lang);
    this.$store.dispatch('register/actionNationalities', this.lang);
    this.$store.dispatch('register/actionBirthPlace', this.lang);
    this.$store.dispatch('register/actionPhoneCodes');
  },
  methods: {
    submit() {
      return this.$refs.child.$refs.regulator.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.callApi();
        } else {
          console.log('error submit!!');
          return Promise.resolve(false);
        }
      });
    },
    callApi() {
      if (this.registerStep < 5) {
        const component = this.$refs.child.$refs.regulator;
        apiProcess_old(component.form).then(resp => {
          if (resp.data.code == 0) {
            if (component.form.countryId)
              this.$store.commit('common/setCountryCode', component.form.countryId.toString());
            this.$emit('addPage');
          }
        });
      } else {
        this.$emit('addPage');
      }
    }
  }
};
</script>
