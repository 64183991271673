<template>
  <div
    class="stage-tag"
    :class="[valAccountStatus, calAccountStage.cssClass, calCustomClasses]"
    :style="{ height: calHeight, 'line-height': calHeight, 'max-width': '144px' }"
  >
    {{ calAccountStage.context }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AccountIcon',
  props: {
    accountStage: {
      type: Number,
      default: 1
    },
    accountStatus: {
      type: Number,
      default: 1
    },
    height: {
      type: String | Number,
      default: 20
    },
    customClasses: {
      type: Array | String,
      default: ''
    }
  },
  computed: {
    calHeight() {
      return typeof this.height === 'string' ? this.height : `${this.height}px`;
    },
    calCustomClasses() {
      return typeof this.customClasses === 'string' ? [this.customClasses] : [...this.customClasses];
    },
    ...mapGetters('propTrading', ['accountStatusMap', 'accountStageMap', 'stageMap']),
    calAccountStage() {
      return this.accountStage || typeof this.accountStage === 'number'
        ? this.stageText.find(item => item.value === this.accountStage)
        : this.stageText[0];
    },
    valAccountStatus() {
      return this.accountStatusMap[this.accountStatus];
    },
    stageText() {
      return Object.keys(this.accountStageMap).map(item => ({
        context: this.$t(`propTrade.accountStageContext.${this.accountStageMap[item]}`),
        cssClass: this.accountStageMap[item].split('Plus')[0],
        value: Number(item)
      }));
    },
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
.stage-tag {
  width: 100%;
  border-radius: 14px;
  // height: 20px;
  // max-width: 112px;
  min-width: 80px;
  color: $pug-white;
  text-align: center;
  // line-height: 20px;
  @include rtl-sass-prop(margin-right, margin-left, 16px);

  &.liveTrading {
    background-color: $pug-yellow;
  }
  &.evaluation {
    background-color: $pug-green-success;
  }
  &.challenge {
    background-color: $pug-blue-hover;
  }
  &.failed {
    background-color: $pug-gray !important;
  }
}

@media (max-width: 768px) {
  .stage-tag {
    @include rtl-sass-prop(margin-right, margin-left, 8px);
  }
}
</style>
