export default (obj, fieldList) => {
  if (!obj) {
    throw new Error('obj is required')
  }

  if (!Array.isArray(fieldList)) {
    throw new Error('fieldList must be an array')
  }

  return fieldList.every(field => {
    return Object.prototype.hasOwnProperty.call(obj, field)
  })
}
