<template>
  <div class="pt-sticpay-withdraw-container">
		<SticpayWithdraw 
			ref="TransferFormWrapper"
			v-bind="$attrs"
      v-on="$listeners"
		/>
	</div>
</template>

<script>
import SticpayWithdraw from '@/components/withdraw/types/SticpayWithdraw.vue'

export default {
	name: `PtSticpayWithdraw`,
	components: {SticpayWithdraw}
}
</script>

<style lang="scss" scoped>
.pt-sticpay-withdraw-container /deep/ {
	.fr.img-row {
		display: none;
	}
	.fl.form_row {
		float: none;

		.form-list{
			width: 100%;
			display: flex;
			gap: 16px;

			> li {
				flex: 1 1 100%;
			}
		}
	}
}

@media (max-width: 1200px) {
	.pt-sticpay-withdraw-container /deep/ {
		.form-list{
			flex-direction: column;

			> li {
				flex: 1 1 100%;
			}
		}
	}
}
</style>
