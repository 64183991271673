<template>
  <TabContent
    :mainWrapperWidth="mainWrapperWidth"
    :afterSessionChangeHook="handleSessionChange"
    :showFilterStages="false"
    :showFilterStatus="false"
    :showFilterSession="true"
    :data="data"
    :sessionOrAccount="COMPONENT_NAME.SESSION"
  />
</template>

<script>
import { apiGetDashBoardSessionList } from '@/resource';
import TabContent from '@/components/propTrading/components/dashboard/TabContent';
import fieldsExist from '@/util/fieldsExist';
import { isSuccess } from '@/util/restApi';
import moment from 'moment';
import {
  ACCOUNT_STAGE,
  ACCOUNT_STATUS,
  SESSION_STATUS,
  DEFAULT_TAB_CONTENT_REFRESH_INTERVAL,
  COMPONENT_NAME
} from '@/constants/propTrading';
import setIntervalFn from '@/util/setIntervalFn';

export default {
  name: 'DashboardAccountsTabContent',
  props: {
    mainWrapperWidth: {
      type: String | Number,
      default: 0
    }
  },
  components: { TabContent },
  data() {
    return {
      COMPONENT_NAME,
      cache: {},
      data: [],
      session: SESSION_STATUS.ALL,
      autoRefetchAPI: null,
      restartAutoRefetchAPI: null,
      refetchStopFn: null
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.refetchStopFn?.();
  },
  methods: {
    async init() {
      this.data = await this.getDashBoardSessionList();

      const { intervalFn, stop, restart } = setIntervalFn(async () => {
        this.data = await this.getDashBoardSessionList();
      }, DEFAULT_TAB_CONTENT_REFRESH_INTERVAL);

      this.autoRefetchAPI = intervalFn;
      this.refetchStopFn = stop;
      this.restartAutoRefetchAPI = restart;

      this.autoRefetchAPI();
    },
    async getDashBoardSessionList() {
      const fieldShouldHave = [
        'accountStage',
        'platform',
        'server',
        'statusDate',
        'equity',
        'profitRatio',
        'accountNumber',
        'currency',
        'status',
        'isNew',
        'sessionNumber'
      ];
      const ids = Array.isArray(this.session) ? this.session : [this.session];

      const promises = ids.map(id => apiGetDashBoardSessionList(id));

      const results = await Promise.all(promises);

      const datas = [];

      for (const result of results) {
        if (isSuccess(result.data)) {
          const data = result.data.data;

          // refactor or removed
          if (!Array.isArray(data)) {
            throw new Error('data is not array');
          }

          const isAllFieldExist = data.every(item => fieldsExist(item, fieldShouldHave));

          if (!isAllFieldExist) {
            // throw new Error('data is not all field exist');
          }

          datas.push(...data);
        }
      }

      return datas;
    },
    async handleSessionChange(value) {
      this.session = value;

      const result = await this.getDashBoardSessionList();
      const apiDateFormat = 'DD-MM-YYYY HH:mm:ss';
      result.sort((a, b) => moment(b.statusDate, apiDateFormat) - moment(a.statusDate, apiDateFormat));
      this.data = result;
      this.restartAutoRefetchAPI?.();
    }
  }
};
</script>

<style lang="scss" scoped></style>
